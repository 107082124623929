// File: store_icons.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';

export const ANDROID_ICON_SRC='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVR4nO2WvUoDQRDHFwRBQQR9BC2srUSQOHMiRsQuLyD4ABai3qzcS2hhZ2MTQcyOiKKCjb6ChaBgKm2VoKBE1pyJHzk3H/cV3B8MHLtzt/O//e/dCGGxWCyaXD7XJRrEy+e6m8mPHGK4JMbblZOpflOudzQ9oHP1PSItSIUXkrEsGTZMucS4U8nFM5EWXIWjkuGVGN6IYZMYz0lhUTKWdOjryhjs+sWX1lRmRKQFr5AZ1rbwizNHAa7oEIZEGpAM81LBU8PF+0GMj7LgzCVaPLEzSQwv1cL0Waiehzrxc17B8zrjRCLFe2quVzLcfC1weX+8b3XPGQwSUG+eGK+X8mM9sQsghsVftlC4JRVsB9omcB4WEhCAp836/o84jl2AVPgQlgBiuI9dgCUuJMPsx59UYdFVmK2Nh+b/sg7Tei3jtwSfX4u7qAVQwHotE/ymotoBrDtuBUi7A2gt1BL2ELP9Cv1zC1Gn/4ldhVn9IB104MxELcANWC90ohIQGx0vgL55td2A9r3eLDWvtl88Ren1RjFZInHLmLACksbuQNKYWoDQW4SwMbUAsbUIFovFIuLkHXF9GoteFQKSAAAAAElFTkSuQmCC';


export const APPLE_ICON_SRC= 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXklEQVR4nO3ZT4hNURzA8c8YjI1QkgzZkIX8S0ZS/pQ/+VNSipLIwkJZWhEWwkIhCzYWlGQhLCTKylr5v7CQxEwTwjCaGeXq1pl6vd575r1333vnar71W713zznfe86555zfYZS6WYLLeIZDcshs3EdSEIfljG3oK5JIY68csR1DJSTSmCcnzMGPMhKv0CYnPCojkcYBOaGrgsRTjJMTLpWR+I5FcsSLEhLpfFkpZwwVSTwOkz8a2sLithQLManM/9K3341b2IwxRb9PxeJQzly0axIrcBWfSwyZdBidDI0apngypyJrcAHvSpTRhyuhnobQibsVvkDF8RbnsBvrsAvn8aGKMu6FejMjfYNfqmhAlvEVW7OQ2IDBFkkkIdL6t9QjMR8/WyyRFCyeE2qRGIvnEQgk4dzSUWtv7I9AIMFZddAevjqtlnhYYt2piuURSAxlsQM4FoHITRlQfKZuRezIQuRlBCKdWYh8a7HE73on+TCDEYj4H3okwZQsRHoiEFmVhciTCEROZyFyPQKR3lo3iYUciUAkySInvD4CiQT99aaMOiqkOJsd7+vNDd+OQCIJ8SkkwWtiTwQCSYltfZp6qnp49UbQ+KQg/mBBLb1yKoLGJwVxR43MwkBEvdGlDs5EIJHgmjqZGHK3rZTowwwZsK/FIgdlSDV53yzjQdZ3jNNasL3vwXQNYFkTty79IS3VMDY24Sg8GC6FGs7qfwyz9BLoIjaFJFt6bJ0Z7g2P4k2FZ7uzOh2OlPSq7XhIHQ2Exqf5sJ0YP4Ln1+IGPuIXXuMEJjeh7aOMYoT8BfcegYzf+KxLAAAAAElFTkSuQmCC'


export const ANDROID_ICON='<img  style="transform:scale(0.9);" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtklEQVR4nO2WvUoDQRDHFwRBQQR9BC2srUSQOHMiRsQuLyD4ABai3qzcS2hhZ2MTQcyOiKKCjb6ChaBgKm2VoKBE1pyJHzk3H/cV3B8MHLtzt/O//e/dCGGxWCyaXD7XJRrEy+e6m8mPHGK4JMbblZOpflOudzQ9oHP1PSItSIUXkrEsGTZMucS4U8nFM5EWXIWjkuGVGN6IYZMYz0lhUTKWdOjryhjs+sWX1lRmRKQFr5AZ1rbwizNHAa7oEIZEGpAM81LBU8PF+0GMj7LgzCVaPLEzSQwv1cL0Waiehzrxc17B8zrjRCLFe2quVzLcfC1weX+8b3XPGQwSUG+eGK+X8mM9sQsghsVftlC4JRVsB9omcB4WEhCAp836/o84jl2AVPgQlgBiuI9dgCUuJMPsx59UYdFVmK2Nh+b/sg7Tei3jtwSfX4u7qAVQwHotE/ymotoBrDtuBUi7A2gt1BL2ELP9Cv1zC1Gn/4ldhVn9IB104MxELcANWC90ohIQGx0vgL55td2A9r3eLDWvtl88Ren1RjFZInHLmLACksbuQNKYWoDQW4SwMbUAsbUIFovFIuLkHXF9GoteFQKSAAAAAElFTkSuQmCC">';

export const APPLE_ICON= '<img style="transform:scale(0.7);" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXklEQVR4nO3ZT4hNURzA8c8YjI1QkgzZkIX8S0ZS/pQ/+VNSipLIwkJZWhEWwkIhCzYWlGQhLCTKylr5v7CQxEwTwjCaGeXq1pl6vd575r1333vnar71W713zznfe86555zfYZS6WYLLeIZDcshs3EdSEIfljG3oK5JIY68csR1DJSTSmCcnzMGPMhKv0CYnPCojkcYBOaGrgsRTjJMTLpWR+I5FcsSLEhLpfFkpZwwVSTwOkz8a2sLithQLManM/9K3341b2IwxRb9PxeJQzly0axIrcBWfSwyZdBidDI0apngypyJrcAHvSpTRhyuhnobQibsVvkDF8RbnsBvrsAvn8aGKMu6FejMjfYNfqmhAlvEVW7OQ2IDBFkkkIdL6t9QjMR8/WyyRFCyeE2qRGIvnEQgk4dzSUWtv7I9AIMFZddAevjqtlnhYYt2piuURSAxlsQM4FoHITRlQfKZuRezIQuRlBCKdWYh8a7HE73on+TCDEYj4H3okwZQsRHoiEFmVhciTCEROZyFyPQKR3lo3iYUciUAkySInvD4CiQT99aaMOiqkOJsd7+vNDd+OQCIJ8SkkwWtiTwQCSYltfZp6qnp49UbQ+KQg/mBBLb1yKoLGJwVxR43MwkBEvdGlDs5EIJHgmjqZGHK3rZTowwwZsK/FIgdlSDV53yzjQdZ3jNNasL3vwXQNYFkTty79IS3VMDY24Sg8GC6FGs7qfwyz9BLoIjaFJFt6bJ0Z7g2P4k2FZ7uzOh2OlPSq7XhIHQ2Exqf5sJ0YP4Ln1+IGPuIXXuMEJjeh7aOMYoT8BfcegYzf+KxLAAAAAElFTkSuQmCC">';

//o      <Typography>HELLO</Typography>

export function AppleIOSLogo() : React.ReactElement {
    return( <>

      <Box component='img'
	  src={APPLE_ICON_SRC}  style={{transform: "scale(0.5)" }} alt=""/>
      </>)
}

export function AndroidLogo() : React.ReactElement {
    return( <>
      <Box component='img'      
       src={ANDROID_ICON_SRC} style={{transform: "scale(0.7)" }}  alt=""/>
      </>)
}


