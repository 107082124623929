// File: mobile_device_select_license.ts
//

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import {   TABLE_HEADER_FONT_COLOR,
    TABLE_HEADER_BG_COLOR } from './utils_config';
import { GetUserLicensesInfo } from '../../extlinks/portal_comm_types';

const TAG='mobile_device_select_license.tsx: ';

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;

interface Props {
    mobile_license_infos:  LicenseInfo[];
    onChange : (value:string|null) =>void;
}

function MobileActivatedDevicesSelectLicenseImpl(
    props : { mobile_license_infos:  LicenseInfo[],
	defaultLicenseKey : string,	
	onChange : (key:string)=>void }) : React.ReactElement
{

    React.useEffect(()=> {
	props.onChange(props.defaultLicenseKey);
    },[]);
    
    const onChange = (_evt:any,  key:string):void => {
	props.onChange(key);
    }
	    
    const license_infos = props.mobile_license_infos;
    
   return (
      
    <TableContainer component={Paper}
		    sx={{boxShadow:1, borderRadius:'5px'}}>

      <FormControl sx={{}}>	      		
	<RadioGroup  onChange={onChange}
		     defaultValue={props.defaultLicenseKey}
	>
	      
	  <Table sx={{width:'100%',borderTop: '1px solid #E0E0E0'}} >
	    <TableHead  style={{ 
		backgroundColor : TABLE_HEADER_BG_COLOR }}>

	      <TableRow>
		
		<TableCell size="small"  style={{color:TABLE_HEADER_FONT_COLOR}} align="center">
		</TableCell>
		
		<TableCell
		    style={{color:TABLE_HEADER_FONT_COLOR}}
			align="left">License Issued</TableCell>
		
		<TableCell
		    style={{color:TABLE_HEADER_FONT_COLOR}}
			align="left">Expiration</TableCell>

		
		<TableCell
		    style={{color:TABLE_HEADER_FONT_COLOR}}
			align="left">Activations Left</TableCell>		
		
	      </TableRow>
	    </TableHead>
	    
	    <TableBody>{
		license_infos.map( (license: LicenseInfo) => {

		    if( ! license.license_type.includes_mobile) {
			return null;
		    }
		    
		    const expiry_date = license.expiry_date == null? 'None' :
			(new Date(license.expiry_date)).toLocaleDateString().replace(/T/,' ');
		    const license_issue_date = license.issue_date == null? 'Unknown' :
			(new Date(license.issue_date)).toLocaleDateString().replace(/T/,' ');

		    //!!!!!!
		    console.log(TAG+' license.max_mobile_devices_activated_per_license=' +
			license.max_mobile_devices_activated_per_license);
		    console.log(TAG+' num_mobile_devices_activated='+
			license.num_mobile_devices_activated);
		    
		    const nact_left = Math.max(0, license.max_mobile_devices_activated_per_license -
			license.num_mobile_devices_activated);
		    
		    return (
			<React.Fragment key={'FR-'+license.license_key}>
			  
			  <TableRow
			      key={license.license_key}
			      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			      >
			    
			    <TableCell>
			       <FormControlLabel
				   label={''}
				   value={license.license_key}
				 control={<Radio/>}
				  />
			      
			    </TableCell>
			    
			    <TableCell  size="small"  align="left">{license_issue_date}</TableCell>
			    
			    <TableCell  size="small" align="left">{expiry_date}</TableCell>

			    <TableCell  size="small" align="left">{nact_left}</TableCell>

			    
			  </TableRow>
			</React.Fragment>
		)})}</TableBody>

	  </Table>
	</RadioGroup>
      </FormControl>
    </TableContainer>
	);

}

export function
MobileActivatedDevicesSelectLicense( props : Props ): React.ReactElement  {

    return(<>
	{ props.mobile_license_infos === null || props.mobile_license_infos.length === 0
	    ? (<div style={{ height:"3em"}}></div>)
	    : (
		<MobileActivatedDevicesSelectLicenseImpl
		    defaultLicenseKey={props.mobile_license_infos[0].license_key}
	            mobile_license_infos={props.mobile_license_infos}
		     onChange={props.onChange}/>
	    )
	}
	    </>
    );
}
