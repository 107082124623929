// File: not_found.tsx
//

import * as React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

export function TimedOut():React.ReactElement {
    return (

 <Container component="main">

        <Typography sx={{mt:10, pl:0}} variant={'h4'} align="center">
          Session timed out.
         </Typography>

  </Container>    
    )
}


