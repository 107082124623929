// File: use_location.ts
//

import * as router from 'react-router-dom';
import { NavigateFunction } from 'react-router-dom';

//export function useLocation<T>() : router.Location & { state: T } {
export function useLocationState<T>() :  T  {
    
//    type L = router.Location & { state: T };
    //type L = router.Location & T ;

    const {state} = router.useLocation() as { state: T};

    return state;
}

export function sendLocationState<T>(route:string, state:T, navigate: NavigateFunction) : void {
    navigate(route, {state});
}
