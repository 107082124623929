// File: set_free_mobile_license_auth_method.tsx
//

import * as React from 'react';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
//import QuestionMark from '@mui/icons-material/QuestionMark';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { AddFreeMobileLicenses } from '../../../extlinks/portal_comm_types';
import { HelpPopup2 } from '../help_popup';

const TAG='set_free_license_auth_method.tsx: ';
//const DEBUG=true;

type AuthMethod = AddFreeMobileLicenses.AuthMethod;
const AuthMethodEnum = AddFreeMobileLicenses.AuthMethodEnum;

const HELP_MSG0 = `Generate only a single activation code for a group of people
that can, for example, be announced to everyone in a class.
`;

const HELP_MSG1 = `Generate only a single activation code for a group of people, as
above, but also sends out an email to everyone with this code.
`;

const HELP_MSG2 = `Each person gets a unique activation code that
is emailed to him.
`;

export function
SetFreeMobileLicenseAuthMethod(props : {setAuthMethod:(auth_method:AuthMethod|null)=>void }) :React.ReactElement {
    
    const onChange = (ev:React.ChangeEvent<HTMLInputElement>):void => {
	console.log('ev.target.value='+ev.target.value);
	const authMethod = AuthMethodEnum[ ev.target.value as keyof typeof AuthMethodEnum];
	if(! authMethod) {
	    console.error(TAG+'onChange: authMethod===undefined');
	} else {
	    props.setAuthMethod(ev.target.value as AuthMethod);
	}
    }

    
    return (
<FormControl >
  <Typography variant='h6'>
Select Activation Method
  </Typography>
  
  <RadioGroup
    name="radio-buttons-group"
  >

    <Box style={{display:'flex', flexDirection:'row',alignItems:'flex-start'}}>
    <FormControlLabel style={{alignItems:'center', padding:'0'}}
		      sx={{mt:1,mr:1,pr:1}}
		      value={`${AuthMethodEnum.COMMON_ACT_CODE_NO_EMAIL}`}
		      control={<Radio sx={{mt:0.5,pt:0}} onChange={onChange}/>}
		      label="Common Activation Code - No Email" />

    <HelpPopup2 open={true} msg={HELP_MSG0}/>
    </Box>    

    <Box style={{display:'flex', flexDirection:'row', alignItems:'center'}}>    
    <FormControlLabel style={{alignItems:'center',padding:'0'}}
		      sx={{mt:1,mr:0,pr:1}}
		      value={`${AuthMethodEnum.COMMON_ACT_CODE_SEND_EMAIL}`}
		      control={<Radio
				   sx={{mt:0.5,pt:0,pl:1}} onChange={onChange}
			       />}
		      label="Common Activation Code - Send Email" />
    
    <HelpPopup2 open={true} msg={HELP_MSG1}/>
    
    </Box>        

    <Box style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
    <FormControlLabel style={{alignItems:'center'}}
		      sx={{mt:1,mr:0,pr:1}}		      
		      value={`${AuthMethodEnum.SEND_INDIVIDUAL_ACT_CODE}`}
		      control={<Radio sx={{mt:0.5,pt:0}} onChange={onChange}/>}
		      label="Individually Unique Activation Code - Send Email" />
    <HelpPopup2 open={true} msg={HELP_MSG2}/>    
    </Box>            
  </RadioGroup>
</FormControl>
    )
}



