// File: assert_unreachable.ts
//

// for statically detecting fall through of switch;                                                       
// pass in the switch argument to this function after the entire switch statement                         
export function assertUnreachable(x: never): never {
    console.error('assertUnreachable: reached');
    throw new Error("Didn't expect to get here");
}



