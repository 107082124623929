// File: side_bar.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import { HomeOnlySideBarMainItems, SideBarMainItems, SideBarMobUpgradeItems } from './sidebar_items';
import { AdminRole, UserPurchaseInfo } from '../../extlinks/portal_comm_types';
import { PubMainPagesContextState } from './pub_main_pages_provider';

//const TAG='main_layout.tsx: ';

export enum  SideBarType {
    FULL='FULL',
    HOME_ONLY='HOME_ONLY',
    MOBUPGRADE_CHILD_PAGE='MOBUPGRADE_CHILD_PAGE',
}

function FullSideBar(props : {user_admin_role: AdminRole,
    user_info: UserPurchaseInfo|null}) :React.ReactElement
{
    
    return (
	<Box>
	  
	  <List>
	    <SideBarMainItems user_admin_role={props.user_admin_role}
			      user_info={props.user_info}/> 
{/*	    
    <Divider sx={{ my: 1 }} />
    <SideBarSecondaryItems/>
  */}	    
	  </List>
	</Box>
    );
}


function MobUpgradeSideBar(props : { user_info: UserPurchaseInfo|null}) :React.ReactElement {
    return (
	<Box>
	  
	  <List>
	    <SideBarMobUpgradeItems 
		user_info={props.user_info}/> 
	  </List>
	</Box>
    );
}


function HomeOnlySideBar(props : {
    user_info: UserPurchaseInfo|null}) :React.ReactElement {
	
    return (
	<Box>
	  <List>
	    <HomeOnlySideBarMainItems user_info={props.user_info}/> 
	  </List>
	</Box>
    );
}

export function MainSideBar(props : { main_pages_state:PubMainPagesContextState, side_bar_type? : SideBarType}): React.ReactElement {

   return (
      
      <Box sx={{}}>
	
	    { (props.side_bar_type === undefined || props.side_bar_type===SideBarType.FULL) &&
		(<FullSideBar user_admin_role={props.main_pages_state.user_admin_role}
			      user_info={props.main_pages_state.purchase_info} />)
	    }
	
	    {  (props.side_bar_type !== undefined && props.side_bar_type===SideBarType.MOBUPGRADE_CHILD_PAGE) &&
		(<MobUpgradeSideBar user_info={props.main_pages_state.purchase_info}/>)
	    }
	
	    {  (props.side_bar_type !== undefined && props.side_bar_type===SideBarType.HOME_ONLY) &&
		(<HomeOnlySideBar user_info={props.main_pages_state.purchase_info}/>)
	    }
	
      </Box>
    );
}

