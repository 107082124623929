// File: send_email_verify_link.ts
//

import { getServerComm } from './server_comm';
import { get_on_local_host } from '../../config/firebase_config';
import { assertUnreachable } from '../../utils_common/assert_unreachable';

const TAG='send_email_verify_link.ts: ';
const DEBUG=true;

export function sendEmailVerifyLink(args : {cont_url_ending : string,
                                            email:string,
                                           },
                                    success : ()=>void,
                                    fail: (error:string)=>void) : void {

    const tag=TAG+'sendEmailVerifyLink: ';
    
    if(DEBUG) console.info(TAG+'entering  sendEmailVerifyLink');
    
    const server = getServerComm();
    
    const session_is_local = get_on_local_host();
    
    server.handle_send_email_verify_link(
        { 
          session_is_local,
          cont_url_ending:args.cont_url_ending,
          email:args.email,
        }
    ).then( (response)=> {

        if(DEBUG) console.log(TAG+'handle_send_email_verify_link: response=',response);

        if(response.error_status == null) {
            return success();
        }
        
        switch(response.error_status) {
            case 'ACCT-NOT-FOUND':
                console.error(tag+'error_msg=' + response.error_msg);
                fail('Account not found.');
                return;                
            case 'ERROR-EMAILING':
                console.error(tag+'error_msg=' + response.error_msg);                
                fail('Error emailing.');                
                return;                
            case 'COMM-ERROR':
                console.error(tag+'error_msg=' + response.error_msg);                
                fail('Commnications error.');                                
                return;                
            case 'INTERNAL-ERROR':
                console.error(tag+'error_msg=' + response.error_msg);                
                fail('Server rror.');                                                
                return;
        }

        assertUnreachable(response.error_status);

    }).catch((error)=> {
        console.error(TAG+'PT3: error=' + error);                
        fail('error sending email verification link (code=2)');
    });
}

