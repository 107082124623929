// File: signin.tsx
//

import * as React from 'react';
import {UserCredential} from 'firebase/auth'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {Link as RouterLink, Navigate, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { IPageProps } from '../interfaces/page'; 
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { PasswordField} from '../../utils_common/password_field';
import { AlertDialog, AlertDialog2 } from '../../utils_common/alert_dialog';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { useAuthStatus } from '../../auth_provider/use_auth';
import { useLocationState } from '../../utils_common/use_location_state';
import { useLocation } from 'react-router-dom';
import { getServerComm } from '../utils/server_comm';
import { MOBILE_UPGRADE_SIGNIN_ENDING_URL, MsgStatus } from '../../extlinks/portal_comm_types';
import { sendEmailVerifyLink } from '../utils/send_email_verify_link';
import { UseMobUpgradeRoutes } from '../utils/use_mobupgrade_routes';
/*
import { setPersistence} from 'firebase/auth';import * as firebase_auth from 'firebase/auth';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
*/

const DEBUG=true;
const TAG='signin.tsx: ';

const theme = createTheme();


export type SigninState = {
    email : string
}

function DialogAskToResubmit(props : { open:boolean,
    setOpen:(open:boolean)=>void, onOK: ()=>void }) : React.ReactElement {
	
    const alert_props = {
	open: ()=>props.open,
	handle_close : ()=>props.setOpen(false),
	handle_choice1 : ()=>{props.onOK(); props.setOpen(false)},
	handle_choice2 : ()=>props.setOpen(false),
	title: 'Email  was sent but not verified.\nResend email?',
	text: '',
	choice1: 'Yes',
	choice2: 'No',
    }
    return (<>
	{ props.open && 
	    (<AlertDialog2 {...alert_props} />)
	}
	</>
    );
}


export function SignIn( _props : IPageProps) : React.ReactElement {

    const location = useLocation();

    React.useEffect( ()=>{
	console.log(TAG+'location.pathname=',location.pathname);
	if(location.pathname === MOBILE_UPGRADE_SIGNIN_ENDING_URL) {
	    console.log(TAG+'calling UseMobUpgradeRoutes.set_on()');
	    UseMobUpgradeRoutes.set_on();
	}
    },[]);
    
    const auth = getAuth();

    const [ msg, setMsg ] = React.useState<string>('');

    const [ email, setEmail ] = React.useState<string>('');
    
    const [ password, setPassword ] = React.useState<string>('');

    const [ spinner, setSpinner ] = React.useState<boolean>(false);

    const [ openResubmit, setOpenResubmit ] = React.useState<boolean>(false);

    //const [ remember, setRemember ] = React.useState<boolean>(false);

    //const { pathname: location_path, state} = useLocation();

    //!!!!!! need to give message that email is not verified
    // what does user  do now ?  tell him check email ?
    // what if link is expired ?

    const parse_error = (error:any) : void => {

	if(DEBUG) console.info(TAG+'parse_error: error.code='+error.code);
	
	if(!error.code) {
	    setMsg('Error logging in. Try again later.');
	} else if(error.code === 'auth/invalid-login-credentials') {
	    setMsg('Invalid login credentials.');
	} else if(error.code === 'auth/invalid-credentials') {	    
	    setMsg('Error logging in. Try again later.');
	    
	} else if(error.code === 'auth/wrong-password') {
	    setMsg('Wrong password.');
	} else if(error.code === 'auth/invalid-email') {
	    setMsg('Email is not valid.');	    
	} else if(error.code === 'auth/user-disabled') {
	    setMsg('Account disabled.');	    	    
	} else if(error.code === 'auth/user-not-found') {
	    setMsg('User not found.');
	} else if(error.code === 'auth/internal-error') {
	    setMsg('Server Internal Error. Try again.');	    	    	    	    
	} else {
	    console.error(TAG+'error.code='+error.code);
	    setMsg('Could not log in.');
	}
    }

    const navigate = useNavigate();    
    
    const signin_success = async (result: UserCredential):Promise<void> =>{
	
        if(DEBUG) console.info(TAG+'signin_success: result=', result);

	navigate('/prehome', {state : { from_signin_page:true }});	
    }

    
    const resubmitEmailVerify = ():void=> {
	const cont_url_ending='/email-verified/0';
	const success = ():void=>{
	    setOpenResubmit(false);	    	    
	    setMsg('Check your email.');
	}
	
	const fail = (error:string):void=> {
	    setOpenResubmit(false);	    
	    console.error(TAG+'resubmitEmailVerify: error='+error);
	    setMsg('Server error while attempting to send email.');
	}
	
	sendEmailVerifyLink({cont_url_ending, email:email}, success, fail);
	    
    }
    const check_email_is_verified = async (emailx: string): Promise<boolean> => {
	
	const server = getServerComm();
	
	const result = await server.handle_get_email_is_verified({ email:emailx})
	
	if(result.status===MsgStatus.ERROR) {
	    console.error(TAG+'check_email_is_verified: result.status===MsgStatus.ERROR')
	    setMsg('Server error.');	    
	    return false;
	}

	switch(result.email_status) {
	    case 'VERIFIED':
		return true;
	    case 'ERROR':
		setMsg('Server error.');
		return false;
	    case 'NOT_VERIFIED':
		setOpenResubmit(true);
		return false;
	    case 'DOES_NOT_EXIST':
		setMsg('Email unknown.');		
		return false;				
	}
    }

    
    const req_sign_in =  async (success : (result:UserCredential)=>Promise<void>) :  Promise<void> =>{

        setMsg('');
	
	if(email==='') {
	    setMsg('Need to input email.');
	    return;
	}
	
	if(password==='') {
	    setMsg('Need to input password.');
	    return;
	}

	//if(DEBUG) console.info(TAG+'before signInWithEmailAndPassword:  remember='+remember);
	
	if(DEBUG) console.info(TAG+'before signInWithEmailAndPassword');
	//if(DEBUG) console.info(TAG+'before signInWithEmailAndPassword: password=' + password);
	setSpinner(true);

	/*
	setPersistence(auth, remember ?
	    firebase_auth.browserLocalPersistence :
	    firebase_auth.browserSessionPersistence).then( ()=> {
	*/

	// note: this is enforced on the backend also
	if( ! (await check_email_is_verified(email))) {
	    setSpinner(false);
	    return;
	}
		
	signInWithEmailAndPassword(auth, email, password)
	    .then( (result):void => {
		setSpinner(false);
		success(result).then(()=>{
		    const user = result.user;
		    console.log(TAG+'signInWithEmailAndPassword: success: user.emailVerified=' +
			user.emailVerified);
		    console.log(TAG+'signInWithEmailAndPassword: success: result=',result);
		    setPassword('');
		}).catch(
		    (errorx)=>{
			console.error(TAG+'success(): errorx='+errorx);
		});
	    }).catch( (errorx):void => {
		setSpinner(false);
		parse_error(errorx);
	    })

    }
    
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	
	event.preventDefault();
	
	if(DEBUG) {
	    //console.log(TAG+'email=' + email);
	    //console.log(TAG+'password=' + password);
	}
	
	req_sign_in(signin_success).then(()=>{}).catch((error)=>{
	    console.error(TAG+'req_sign_in: error=' + error);
	});
    };

    const dialog_is_open = () :boolean=> {
       return msg !== '';
    }

    const close_dialog = () : void => {
	setMsg('');
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,

	title : msg,
	text : '',
	choice1 : 'OK',
	choice2 : ''
    }

    const  state  = useLocationState<SigninState>();

    React.useEffect( ()=> {
	if(state && state.email) {
	    setEmail(state.email.trim().toLowerCase());
	}
    });
    
    const { user } = useAuthStatus();

    return (
      <>
	{ user != null && user.emailVerified
        ? (<Navigate to='/prehome'/>)
      	: (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center' sx={{mb:1}}>
         <TDBAppLogoLarge/>
	</Typography>
	 
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
	  
          <Box component="form"
	       onSubmit={(ev: React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       noValidate sx={{ mt: 1 }}>
	    
            <TextField
		margin="normal"
		required
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />

	      <PasswordField  id='newpw'
			      fullWidth={true}
	                      adornment="Password"
           		      label="Password"
				  password={password}
				  setPassword={setPassword}			 
				  onChange={()=> {
				      if(msg !== '') setMsg('');
				  }}/>


	    {/*
            <FormControlLabel
		control={<Checkbox value="remember"
					  checked={remember}
				   onChange={(e:React.ChangeEvent<HTMLInputElement>):void=> {
				       setRemember(e.target.checked);
				   }}/>}
		label="Remember me"
            />*/}

	    
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 0, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item sx={{ mt: 0, mb: 1 }}>
                <RouterLink to="/forgot-password">
                  Forgot password?
                </RouterLink>
              </Grid>

	      {/*
              <Grid item>
                <RouterLink to="/create-account">
                  Create an account if you have received an email invitation.
                </RouterLink>
              </Grid>
	      */}
            </Grid>
          </Box>
        </Box>

	<AlertDialog {...dialog_props} />

        <Copyright sx={{ mt: 5, mb: 4 }} />
      </Container>


	  <Backdrop invisible={true} open={spinner}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

          <DialogAskToResubmit open={openResubmit}
			       setOpen={setOpenResubmit}
	                       onOK={resubmitEmailVerify} />
	  
    </ThemeProvider>) }
      </>
  );
}
