// File: licenses_loc_states.ts
//

const TAG='licenses_loc_states.ts: ';
const DEBUG=true;
import { ListAllLicensesMsg } from '../../../extlinks/portal_comm_types';

type UserLicense = ListAllLicensesMsg.UserLicense;

export interface LicensesTableState {
    LicensesTableState_:undefined;    
    page_num:number|null,
    allValidUserLicenses : UserLicense[],
}

export interface BackLicensesTableState extends LicensesTableState {
    BackLicensesTableState_:undefined;
    rowFilterName:string|null,
    licenseSorterName : string|null,        
}

export interface EditLicenseState {
    license : UserLicense,
    back_state : BackLicensesTableState,
}

export interface SearchLicensesResultState {
    matching_licenses : UserLicense[],
    back_state : BackLicensesTableState,    
}

export function get_licenses_table_state(props : { page_num: number|null,
                                                    allUserLicenses : UserLicense[] }) : LicensesTableState {
    return {
        LicensesTableState_:undefined,
        page_num : props.page_num,
        allValidUserLicenses : [...props.allUserLicenses],
    };
}

function get_back_table_state(props : {   page_num: number|null,
                                          rowFilterName:string|null,
                                          licenseSorterName : string|null,        
                                          allUserLicenses : UserLicense[] }) : BackLicensesTableState {
    
    return { BackLicensesTableState_:undefined,
             rowFilterName: props.rowFilterName,
             licenseSorterName:props.licenseSorterName,
             ... get_licenses_table_state({...props}) };
}

export function get_edit_license_state(props : { license : UserLicense,
                                                 page_num: number|null,
                                                 rowFilterName:string|null,
                                                 licenseSorterName : string|null,                                                         
                                                 allUserLicenses : UserLicense[] }) : EditLicenseState {

    return {
        license : props.license,
        back_state : get_back_table_state({page_num : props.page_num,
                                           rowFilterName: props.rowFilterName,
                                           licenseSorterName:props.licenseSorterName,
                                           allUserLicenses : props.allUserLicenses})
    }
    
}


export function get_search_licenses_results_state(props : { matching_licenses : UserLicense[],
                                                            page_num: number|null,
                                                            rowFilterName:string|null,
                                                            licenseSorterName : string|null,
                                                            allUserLicenses : UserLicense[] }) : SearchLicensesResultState {

    return {
        matching_licenses: [...props.matching_licenses],
        back_state: get_back_table_state({page_num : props.page_num,
                                          rowFilterName: props.rowFilterName,
                                          licenseSorterName:props.licenseSorterName,
                                          allUserLicenses : [...props.allUserLicenses] }),
        
    }
    
}

