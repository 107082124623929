// File: license_info_landing.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { useParams, useNavigate} from 'react-router-dom';
import { EmailCommLandingPage } from './components/email_comm_landing_page';
import { getECommServerComm } from './utils/ecomm_server_comm';
import { EComm, ECOMM_NOT_FOUND_URL, MsgStatus } from '../extlinks/portal_comm_types';
import { Progress } from '../utils_common/progress';
import Typography from '@mui/material/Typography';
import { Copyright } from '../utils_common/copyright';
import { TDBAppLogoLarge } from '../utils_common/tdb_large_logo';
import { MsgAlertDialog } from '../utils_common/alert_dialog';
import { check_url_params } from './utils/check_url_params';
import { DesktopLicensesTable } from './license_info_landing/desktop_licenses_table';
import { MobileLicensesTable } from './license_info_landing/mobile_licenses_table';
import { MobileDevicesTable } from './license_info_landing/mobile_devices_table';
import { useECommAuthStatus } from '../auth_provider/ecomm_auth_provider';

const TAG='license_info_landing.tsx: ';
const DEBUG=true;

type LicensesInfo = EComm.ECommGetUserLicensesInfo.LicensesInfo;

export function ECommLicenseInfoLanding(props:{}) : React.ReactElement {

    const { url_params } = useParams();
    
    const navigate = useNavigate();
    const [ msg, setMsg ] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [ noLicenses, setNoLicenses ] = React.useState<boolean>(false);
    const licensesInfo = React.useRef<LicensesInfo|null>(null);
    
    console.log('url_params='+url_params);

    const server = getECommServerComm();

    const auth_status = useECommAuthStatus();
    
    React.useEffect( ()=> {
	console.log(TAG+'url_params',url_params);
	check_url_params({parent_tag:TAG, url_params,
	    session_token:auth_status.session_token,
	    navigate});
    },[url_params]);

    
    React.useEffect( ()=> {

	auth_status.refresh();	
	const session_token = auth_status.session_token;

	if(session_token==null) {
	    console.error(TAG+'session_token==null');
	    navigate(ECOMM_NOT_FOUND_URL);
	    return;
	}	
	
	if(url_params && licensesInfo.current == null) {
	    setIsLoading(true);
	    setNoLicenses(false);
	    server.handle_req_user_licenses_info({url_params, session_token}).then((result)=>{
		setIsLoading(false);
		console.log(TAG+'PT1')
		if(result.status === MsgStatus.ERROR) {
		    setMsg('Server error. Try again later.');
		} else {
		    licensesInfo.current = result.licenses_info;
		}
	    }).catch((error)=>{
		setIsLoading(false);	    
	    });
	}
    },[]);
    
    return (<>
      
<EmailCommLandingPage challengeOff={true}>

  <Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
    
  	    <Typography align='center' sx={{mt:4,mb:4}}>
              <TDBAppLogoLarge/>
 	    </Typography>

	    <>
	    { licensesInfo.current != null && 
		(
		  <Box>
		    <Box sx={{mb:1}}>
		      <DesktopLicensesTable desktop_licenses={licensesInfo.current.desktop_licenses}/>
		    </Box>		  

		    <Box  sx={{mb:1}}>		    
		      <MobileLicensesTable mobile_licenses={licensesInfo.current.mobile_licenses}/>
		    </Box>		    		  

		    <Box  sx={{mb:1}}>		    		    
		      <MobileDevicesTable mobile_devices={licensesInfo.current.mobile_devices}/>
		    </Box>		    		  		  
		  </Box>
		)
	    }
	    </>
	    
	  <Copyright sx={{ mt: 5, mb: 4 }} />
	  
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />
	  
	{ <Progress isLoading={isLoading}/> }
 </Box>
</EmailCommLandingPage>
	    
    </>);
}


