// File: EcommRequireAuth.tsx
//

import React from 'react';

import { Outlet, Navigate} from 'react-router-dom';
import { useECommAuthStatus } from '../auth_provider/ecomm_auth_provider';
import { useParams} from 'react-router-dom';
import { useNavigate} from 'react-router-dom';
import { ECOMM_EMAIL_LOGIN_URL, ECOMM_NOT_FOUND_URL } from '../extlinks/portal_comm_types';
import { ECOMM_TESTING } from '../config/config';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';


const TAG='ECommRequireAuth.tsx: ';

const DEBUG=true;

export function ECommRequireAuth() : React.ReactElement  {

    const { url_params } = useParams();
    
    const navigate = useNavigate();
    const auth_status = useECommAuthStatus();

    const [ isRefreshed, setIsRefreshed]= React.useState<boolean>(false);
    
    React.useEffect( ()=> {
	auth_status.refresh();
	setIsRefreshed(true);	
	/*
	if(auth_status.session_token==null) {
	    console.error(TAG+'PT1: auth_status.session_token==null');
	    if(!ECOMM_TESTING) {
		navigate(ECOMM_NOT_FOUND_URL);
	    }
	} else {
	    if(DEBUG) {
		console.info(TAG+'PT1: auth_status.session_token='+auth_status.session_token);
	    }
	}*/
    },[auth_status.session_token]);
    
    return (
	<>

        {  ! isRefreshed && 
	    (  <Container component="main" maxWidth="xs">
		<CssBaseline />
	 	</Container>
		)
        }

        {  isRefreshed && auth_status.session_token == null && url_params != null &&
	    (  <Container component="main" maxWidth="xs">
		<CssBaseline />
		<Navigate to={ECOMM_EMAIL_LOGIN_URL+'/' + url_params}/>
	 	</Container>
		)
        }
	  
        {  isRefreshed && auth_status.session_token == null && url_params == null &&
	    (  <Container component="main" maxWidth="xs">
		<CssBaseline />
		<Navigate to={ECOMM_NOT_FOUND_URL}/>
	 	</Container>
		)
        }
	  
	{ auth_status.session_token != null &&
		(<Outlet />)	  
	}
	  
	</>
    );
    
}


