// File: check_url_params.ts
//

import { NavigateFunction } from 'react-router-dom';
import { getECommServerComm } from '../utils/ecomm_server_comm';
import { ECOMM_NOT_FOUND_URL, MsgStatus } from '../../extlinks/portal_comm_types';

const TAG='check_url_params.ts: ';
const DEBUG=true;

export function check_url_params(args:{url_params:string|undefined,
                                       session_token:string|null,
                                       parent_tag:string,
                                       navigate:NavigateFunction,
                                       finished?:()=>void}) : void {
    if(args.url_params===undefined) {
        console.error(args.parent_tag+'check_url_params: url_params==undefined')
        args.navigate(ECOMM_NOT_FOUND_URL);
        return;
    }

    if(args.session_token==null) {
        console.error(args.parent_tag+'check_url_params: session_token==null')
        args.navigate(ECOMM_NOT_FOUND_URL);
        return;
    }
    
    const server = getECommServerComm();
    server.handle_check_ecomm_url_params_and_session_token({url_params:args.url_params,
                                          session_token:args.session_token}).then( result=> {
	if( ! result.ok || result.status===MsgStatus.ERROR) {
	    console.error(args.parent_tag+'check_url_params: error');
	    args.navigate('/not-found');		    
	}
        if(args.finished) args.finished();
    }).catch((error)=> {
	console.error(args.parent_tag+'check_url_params: error='+error);
	args.navigate('/not-found');
        if(args.finished) args.finished();        
    });    
}
