// File: mobile_devices_table.tsx
//

import * as React from 'react';
import { EComm} from '../../extlinks/portal_comm_types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from '../utils/utils_config';

const TAG='mobile_licenses_table.tsx: ';
const DEBUG=true;


type MobileDevice = EComm.ECommGetUserLicensesInfo.MobileDevice;

const Para = (props:{text:string}) : React.ReactElement => {
    return(<>
	<p style={{lineHeight:'100%', marginTop:'0.5em', marginBottom: '0.5em'}} >{props.text}</p>
	</>
    )
}

export function MobileDevicesTable(props:{mobile_devices : MobileDevice[]}) : React.ReactElement {

    return(<>
    <TableContainer component={Paper} 
		    sx={{boxShadow:3, maxWidth:'97vw', borderRadius:'10px', pb: 2}}>
    
    <Table style={{maxWidth:'97vw'}}>
	
      <TableHead style={{borderRadius:'10px', backgroundColor:TABLE_HEADER_BG_COLOR}}>

	<TableRow>	  	
	  <TableCell
	      style={{color:TABLE_HEADER_FONT_COLOR}} colSpan={6}
	      align="center">Your Activated Mobile Devices</TableCell>
	</TableRow>	  
	
	<TableRow>

	  <TableCell  size="small" 
 		style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left"><Para text="Activation"/><Para text="Code"/></TableCell>	  
	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR, lineHeight:'100%'}}
	      align="left"><Para text="Device"/><Para text="Model"/></TableCell>
	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">Platform</TableCell>	  

	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">Activations</TableCell>
	</TableRow>

      </TableHead>
      <TableBody>{props.mobile_devices.map( (device, index) =>  {

	      //const expiry_date = device.expiry_date == null ? 'None' :
	      //        (new Date(mobile_device.expiry_date)).toLocaleDateString();
	      
	      return (
		  <TableRow
		      key={String(index)}
		      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		  >
		    <TableCell size="small" align="left">{device.activation_code}</TableCell>		    		    
		    <TableCell size="small" align="left">{device.device_desc}</TableCell>
		    <TableCell size="small" align="left">{device.platform}</TableCell>
		    <TableCell size="small" align="left">{device.num_act}</TableCell>		    
		  </TableRow>
	      );
	  })}</TableBody>	      
    </Table>
    </TableContainer>          
        </>);
}

