// File: display_free_mobile_licenses.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { get_session_token, LOGGER } from '../../../utils_common/logger';
import { getAdminServerComm } from '../admin_server_comm';
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { GetFreeMobileLicenses, MsgStatus } from '../../../extlinks/portal_comm_types';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from '../utils_config';

const TAG='display_free_mobile_licenses.tsx: ';
const DEBUG=false;

type LicenseInfo = GetFreeMobileLicenses.LicenseInfo;

function LicenseInfoRow(props : {license_info:LicenseInfo}) : React.ReactElement {

    const x = props.license_info;

    const issue_date = (new Date(x.issue_datetime)).toLocaleDateString();
    const expiry_date = x.expiry_datetime==='NONE'? 'NONE' :
	(new Date(x.expiry_datetime)).toLocaleDateString();    

    return(

          <TableRow>

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">{x.user_info.email}
	    </TableCell>	
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">{x.user_info.first_name}
	    </TableCell>
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">{x.user_info.last_name}
	    </TableCell>
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">{issue_date}
	    </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">{expiry_date}
	    </TableCell>

            <TableCell
	        size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR, maxWidth:'10em', whiteSpace:'normal',
		wordWrap:'break-word'}}
		align="left">{x.license_key}
	    </TableCell>	    	    
	
            <TableCell
	        size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">{x.portal_acct_exists ? 'yes' : 'no' }
	    </TableCell>	    	    
	
          </TableRow>	
    )
}

let KEY=0;

function LicenseInfosTable(props : {licenseInfos:LicenseInfo[]}) : React.ReactElement {
    
    return (
   <TableContainer component={Paper} style={{ borderRadius:'5px'  }}
		   sx={{ boxShadow:1}} >

      <Table sx={{borderTop: '1px solid #E0E0E0' }} >
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>

          <TableRow>

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Email
	    </TableCell>	
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">First Name
	    </TableCell>
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Last Name
	    </TableCell>
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Issue Date
	    </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Expiry Date
	    </TableCell>	    
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">License Key
	    </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Account Exists
	    </TableCell>	    	    
	
          </TableRow>

        </TableHead>
	
        <TableBody>
        {
	    props.licenseInfos.map((x) => (<LicenseInfoRow key={String(KEY++)} license_info={x}/>))
	}
        </TableBody>
	
        </Table>
	
    </TableContainer>	 	
    )
}


export function DisplayFreeMobileLicenses(props : { setSpinnerOn:((on:boolean)=>void)}) :React.ReactElement {

    const [ errMsg, setErrMsg ] = React.useState<string|null>(null);
    const [ licenseInfos, setLicenseInfos ] = React.useState<LicenseInfo[]|null>(null);

    const onClick = ():void => {

	props.setSpinnerOn(true);
	setErrMsg(null);
	
	const session_token = get_session_token();
	const server = getAdminServerComm();
	server.handle_get_all_free_mobile_licenses({
	    session_token,
	}).then( (x)=> {
	    if(x.status===MsgStatus.OK) {
		console.log(TAG+'x.license_infos.length=' +
		    x.license_infos.length);
		setLicenseInfos(x.license_infos);
		props.setSpinnerOn(false);		
		return;
	    } else {
		LOGGER.error(TAG+'submit: server error');
		console.error(TAG+'submit: error');
		setErrMsg('Server Error.');
		props.setSpinnerOn(false);				
		return;
	    }
	}).catch( (error)=> {
	    props.setSpinnerOn(false);					    
	    LOGGER.error(TAG+'submit: error=' + error);
	    console.error(TAG+'submit: error=' + error);
	    setErrMsg('Server Error.');		
	});
    
    }
    
    return (
	<>
	 <Box
	     sx={{ml:1,mr:1}}
	     style={{display:'flex',
	     flexDirection:'column',
	     alignItems:"center",
	     justifyContent:"flex-start" }} >

	   <Typography sx={{mb:4}} variant="h5">Display All Free Mobile Licenses</Typography> 
	 
	   <Button size="small" type="submit"
		   variant="contained"
		   onClick={onClick}>DISPLAY</Button>

	   <Box style={{height:"1em"}}/>
	   <>
	 {
             licenseInfos != null    &&
	     licenseInfos.length === 0 &&
		 (<>
                  <Typography sx={{mt:2}} variant='h6'>
		    No free mobile licenses exist.
                  </Typography>		    
      	          </>)  
	 }
	 {   licenseInfos != null    &&
	     licenseInfos.length > 0 &&
		 (<>
		   <LicenseInfosTable licenseInfos={licenseInfos}/>
		   
		   <Box style={{height:"1em"}}/>	 

		   {/*
		   <Button onClick={onClick} size="small"
			   type="submit" variant="outlined">REFRESH</Button> */}
		 </>)
	 
	  } </>
	   
         </Box>
	 
	 { errMsg && (<Alert  sx={{boxShadow:4, mt:2}}
			      onClose={()=>setErrMsg('')}>{errMsg}</Alert>) }
	 
	</>
    )
}
