// File: RequireAuth.tsx
//

import React from 'react';
import { Location,Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAuthStatus } from '../auth_provider/use_auth';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import {useAdminPagesContext} from './utils_admin/admin_pages_provider';
import { AdminRole } from '../extlinks/portal_comm_types';
import {  User } from 'firebase/auth';
import { NotFound } from '../pages_pub/other_pages/not_found';

const TAG='AdminRequireAuth.tsx: ';

const DEBUG=true;

const theme = createTheme();

const HasAdminRole = (props : {user:User|null, location:Location}) : React.ReactElement => {

    const user = props.user;
    const location = props.location;
    
    return (
	<>
	{ user == null &&
  	    ( <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
		  <Navigate to='/signin' state={{ from : location }} replace />
	      </Container>
	      </ThemeProvider>)
	}
	
	{ user != null &&  ! user.emailVerified &&
 	    (
	      <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
	 	  <Navigate to='/email-not-verified' state={{ from : location }} replace />
	        </Container>
	     </ThemeProvider>)
	}
	
	{ user != null && user.emailVerified &&
	    (<Outlet />)	  
	}
	</>
    )
}

export const AdminRequireAuth = () : React.ReactElement => {
    
    const {user} = useAuthStatus();
    const location = useLocation();
    
    //const [adminRole, setAdminRole] = React.useState<AdminRole>(AdminRole.NONE);

    const { current_user_admin_role, is_loading } = useAdminPagesContext();

    return (
	<>
        { is_loading && (<></>) }
	  
	{ current_user_admin_role === AdminRole.NONE &&  !is_loading &&
	    (<NotFound/>)
	}
	  
	{  current_user_admin_role !== AdminRole.NONE && !is_loading &&
	    (<HasAdminRole user={user} location={location}/>)
	}
	</>	  

    );
    
}


