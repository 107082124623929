// File: activate_mobile_app.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import { MainLayout} from '../utils/main_layout';
import  Box  from '@mui/material/Box';
import { Link as RouterLink} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Spinner} from '../../utils_common/spinner'
import { AlertDialogProps, AlertDialog } from '../../utils_common/alert_dialog';
import { getServerComm } from '../utils/server_comm';
import { MobileActivatedDevicesSelectLicense} from '../utils/mobile_device_select_license';
import { useMainPagesContext } from '../utils/pub_main_pages_provider';
import { GetUserLicensesInfo,
   } from '../../extlinks/portal_comm_types';
import { useNavigate} from 'react-router-dom';
import { get_session_token, LOGGER } from '../../utils_common/logger';

const TAG='activate_mobile_app.tsx: ';
const DEBUG=false;

const FONT='body1';

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;

function MoreThanOneDevices(props : {
    onSelChange : (key:string|null)=>void,
    onSubmit : ()=>void,
    mobile_license_infos : LicenseInfo[]}) :  React.ReactElement {
    
    return (
 <Box
       sx={{
	   display: 'flex',
	   ml: 2,
	   mr: 2,
	   flexDirection: 'column',
	   alignItems: 'center',
       }}
   >
   
     <Box>   
       <p> Select the mobile license to activate.</p>
     </Box>
      
    <Box sx={{ mt:0}}>	  
  	<MobileActivatedDevicesSelectLicense
	  onChange={(key)=>props.onSelChange(key) }
            mobile_license_infos={props.mobile_license_infos}/>
    </Box>

     <Box sx={{mt:3}}>       
       <p> Press button to obtain an activation code and send it to your email address.</p>
     </Box>
       
    <Box textAlign='center' sx={{mt:0}}>
         <Button size="small"	    
		 type="submit"
		 variant="contained"
		 sx={{ mt: 0, mb: 0 }}
		 onClick={props.onSubmit}
           >
             SUBMIT
         </Button>
     </Box>

     <Box sx={{mt:2}}>
       <p> Then, follow these &nbsp;
	 <RouterLink to="/mobile-upgrade/mobile-act-instruct">
	   instructions
         </RouterLink>
    &nbsp;   on how to download the app and input the activation code on your device.
	 </p>
       </Box>
   
 </Box>);
    
}

function OneDevice(props : {
    onSelChange : (key:string|null)=>void,
    onSubmit : ()=>void,
    mobile_license_infos : LicenseInfo[]}) :  React.ReactElement {
    
    return (
 <Box
       sx={{
	   display: 'flex',
	   ml: 2,
	   mr: 2,
	   flexDirection: 'column',
	   alignItems: 'center',
       }}
   >

    <Box>
      <p>The following mobile license is available for mobile activation.</p>
    </Box>       
   
    <Box sx={{ mt:0}}>	  
  	<MobileActivatedDevicesSelectLicense
	onChange={(key)=>props.onSelChange(key) }
        mobile_license_infos={props.mobile_license_infos}/>
   </Box>

    <Box sx={{ mt:2}}>	     
       <p style={{marginTop:"0.5em"}}> Press button to obtain and activation code and send it to your email address.</p>
   </Box>
       
    <Box textAlign='center' sx={{mt:1}}>
         <Button size="small"	    
		 type="submit"
		 variant="contained"
		 sx={{ mt: 0, mb: 0 }}
		 onClick={props.onSubmit}
           >
             SUBMIT
         </Button>
     </Box>

      <Box sx={{mt:3}}>
       <p> Follow these &nbsp;
	 <RouterLink to="/mobile-upgrade/mobile-act-instruct">
	   instructions 
         </RouterLink>
   &nbsp; to download the app and input the activate code on your device.
	 </p>
	 </Box>
	      
   
 </Box>);
    
}

export function ActivateMobileApp() : React.ReactElement {

    const count = React.useRef<number>(0);
    
    {
	const navigate = useNavigate();		
	count.current = count.current+1;
	if(DEBUG) console.log(TAG+'***count='+count.current);
	if(count.current > 50) navigate('/home');
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_ACTIVATE_MOBILE_APP','activate_mobile_app.tsx');
    }
    
    //const [ actCode , setActCode] = React.useState<string|null>(null);
    const [ msg, setMsg ] = React.useState<string>('');
    const [ waiting, setWaiting ] = React.useState<boolean>(false);
    const [dialogText, setDialogText] =React.useState<string>('');
    const [ selKey, setSelKey ] =  React.useState<string|null>(null);
    const [start, setStart] = React.useState<boolean>(false);

    React.useEffect( ()=>{ setStart(true); });
	
    const main_pages_state = useMainPagesContext();
    const isLoading = main_pages_state.is_loading;

    const licenseInfos = main_pages_state.user_licenses_info ?
	main_pages_state.user_licenses_info.license_infos : [];

    console.log(TAG+'licenseInfos.length='+licenseInfos.length);
    
    //let mobile_license_infos =licenseInfos.filter( (x) => x.mobile_device_activations_are_left );
    let mobile_license_infos = licenseInfos.filter( (x)=>x.license_type.includes_mobile );

    console.log(TAG+'mobile_license_infos.length='+mobile_license_infos.length);

    //mobile_license_infos = [ mobile_license_infos[0] ]
    
    const server = getServerComm();
    
    const onChange = React.useCallback( (key:string|null):void => {
	setSelKey(key);
	if(key) console.log('license key=',key.replace(/HEL/,'MOB'));
	else    console.log('license key=null');
    },[]);
    
    const submit_fun = () : void => {

	if(selKey == null) {
	    setMsg('need to select license before submitting');
	    return;
	}
	
	console.log('submit:' + selKey.replace(/HEL/,'MOB'));
	    
	setWaiting(true);
	const session_token=get_session_token();
 	server.handle_create_send_pending_activation({session_token, license_key:selKey}).then( (x) => {
	    const { status : msg_status, error_msg,  act_code_expiry_period_mins } = x;
	    if(error_msg != null) {
		LOGGER.error('x.error_msg='+x.error_msg);
		setMsg(error_msg);
	    } else if(msg_status !== 'OK') {
		LOGGER.error('Server error retrieving activation code');
		setMsg('Server error retrieving activation code');
	    } else {
		setMsg('An activation code has been sent to your email address\n(supersedes any previous code)');
		if(act_code_expiry_period_mins != null) {		
		    setDialogText('Code expires in ' + String(act_code_expiry_period_mins) + ' minutes.');
		} else {
		    setDialogText('');
		    console.error(TAG+'act_code_expiry_period_mins==null');
		}
	    }
	    setWaiting(false);
	}).catch( (error)=> {
	    LOGGER.error('Server error retrieving activation code: error='+
		String(error));	    
	    setWaiting(false);	    
	    setMsg('Server error retrieving activation code');	    
	})
    }
    
    const dialog_is_open = () :boolean => {
       return msg !== '';
    }

    const close_dialog = () : void => {
	setMsg('');
	setDialogText('');
    }

    const dialog_props : AlertDialogProps = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,
	title : msg,
	text : dialogText,
	choice1 : 'OK',
	choice2 : '',

	center_body:true,
	bold_body:true,
	body_font_size:'150%'
    }

  
    return (
  <MainLayout title='Mobile Activation'>	

    <Spinner open={waiting||isLoading} />
    <>
    { (start && mobile_license_infos != null && mobile_license_infos.length===0 && !isLoading) &&
	
	  (  <>
    	  <Typography align='center' variant={FONT}>
	    You have no mobile device licenses to activate.
          </Typography>

	  </>
 	  )

    }
    
    { (start && mobile_license_infos != null && mobile_license_infos.length===1 && !isLoading) &&
	
	(
          <OneDevice onSelChange={onChange}
		     onSubmit={submit_fun}
		     mobile_license_infos={mobile_license_infos}/>

         )
    }
      
    { (start && mobile_license_infos != null && mobile_license_infos.length> 1 && !isLoading) &&
	
	(<>

        <MoreThanOneDevices onSelChange={onChange}
			    onSubmit={submit_fun}			    
			    mobile_license_infos={mobile_license_infos}/>

         </>)
    }


      
    </>
    
    <AlertDialog {...dialog_props} />      
	    
  </MainLayout>
  );
}
