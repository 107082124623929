// File: forgot_password.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { AlertDialog } from '../../utils_common/alert_dialog';
import {IPageProps} from '../interfaces/page';
//import { get_on_local_host } from '../../config/firebase_config';
import { get_on_local_host, get_portal_domain_url } from '../../config/firebase_config';
import { getServerComm } from '../utils/server_comm';
import { MsgStatus } from '../../extlinks/portal_comm_types';
//import toast, { Toaster } from 'react-hot-toast';

const TAG='forgot_password.tsx: ';

const theme = createTheme();

export function ForgotPassword(_props : IPageProps ) : React.ReactElement {
    
    const [ msg, setMsg ] = React.useState<string>('');

    const [ email, setEmail ] = React.useState<string>('');

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();
	console.log(email);
	
	const portal_domain_url =  get_portal_domain_url();

	if(portal_domain_url == null) {
	    setMsg('internal error');
	    console.error('internal error: portal_domain_url==null');
	    return;
	}
	
	if(email.trim()==='') {
	    setMsg('Need to input email.')
	    return;
	}
	
	const server = getServerComm();

	server.handle_send_reset_password_link({
	    email : email.trim(),
	    webhost_is_local : get_on_local_host()
	}).then((result)=> {
	    if(result.status === MsgStatus.OK && result.error_msg===null) {
		setMsg('Check your email for password reset link.')
	    }  else if(result.error_msg!==null) {
		console.error(TAG+'handleSubmit: result.error_msg!==null');
		setMsg(result.error_msg);
	    } else {
		console.error(TAG+'handleSubmit: result.error_msg==null but error');		
		setMsg('Internal Error');
	    }
	}).catch((error)=>{
	    console.error(TAG+'handleSubmit: error='+error);
	    setMsg('Internal Error');	    
	});
    };

    const dialog_is_open = () :boolean=> {
       return msg !== '';
    }

    const close_dialog = () : void => {
	setMsg('');
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,

	title : msg,
	text : '',
	choice1 : 'OK',
	choice2 : ''
    }
    
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center'>
         <TDBAppLogoLarge/>
	</Typography>

         <Box style={{height:'1em'}}/>	 
	 
          <Typography component="h1" variant="h5" noWrap={false} >
            Input your email to have a password reset  link sent to you.
          </Typography>
          <Box component="form"
	       onSubmit={(ev:React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       noValidate sx={{ mt: 1 }}>
	    
            <TextField
		margin="normal"
		required
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim())}}	    
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
	      
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>

          </Box>
        </Box>

	<AlertDialog {...dialog_props} />
	
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
