// File: select_filter_rows.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { GetMobUpgradeableLicenses } from '../../../extlinks/portal_comm_types';
import { DropDownListMenu } from '../drop_down_list_menu';

const TAG='select_filter_rows.tsx: '

type LicenseInfo = GetMobUpgradeableLicenses.ParentLicenseInfo;

export interface RowFilter {
    filter(row:LicenseInfo):boolean;
    name:string;
}

class UpgradePurchased implements RowFilter {
    readonly name='upgrade-purchased'
    filter(x : LicenseInfo) : boolean {
	return x.mobupgrade_purchase_date != null  && x.mobupgrade_purchase_date.trim() !=='';
    }
}

class UpgradeNotPurchased implements RowFilter {
    readonly name='upgrade-not-purchased'
    filter(x : LicenseInfo) : boolean {
	return x.mobupgrade_purchase_date == null  || x.mobupgrade_purchase_date.trim() ==='';
    }
}

class IsSubscription  implements RowFilter {
    readonly name='is-subscription'    
    filter(x : LicenseInfo) : boolean {
	return x.subscription_status === 'IS_SUBSCRIPTION'
    }
}

class IsNotSubscription   implements RowFilter {
    readonly name='is-not-subscription'        
    filter(x : LicenseInfo) : boolean {
	return x.subscription_status !== 'IS_SUBSCRIPTION'
    }
}

class Invited implements RowFilter {
    readonly name='not-invited'            
    filter(x : LicenseInfo) : boolean {
	return x.invite_sent_date !== null &&  x.invite_sent_date.trim() !== '';
    }
}

class NotInvited implements RowFilter {
    readonly name='not-invited'            
    filter(x : LicenseInfo) : boolean {
	return x.invite_sent_date === null;
    }
}

class NoneFilter implements RowFilter  {
    readonly name='none'                
    filter(_x : LicenseInfo) : boolean {
	return true;
    }
}

export const NONE_ROW_FILTER = new NoneFilter();


const FILTERS : [string, RowFilter][] = [
	[ 'none',  new NoneFilter() ],
	[ 'invited before',  new Invited()],	
	[ 'not invited before',  new NotInvited()],
	[ 'is subscription' , new IsSubscription() ],
	[ 'not subscription' , new IsNotSubscription() ],	
	[ 'upgrade purchased' , new UpgradePurchased() ],
	[ 'upgrade not purchased' , new UpgradeNotPurchased() ],
    ]

export function get_row_filter(name:string) : RowFilter|null {
    for(let x of FILTERS) {
	const filter = x[1];
	if(filter.name === name) {
	    return filter;
	}
    }
    return null;
}

export function SelectFilterRows(props:{setFilter:(filter:RowFilter)=>void,
    filter:RowFilter,
    onChange?:()=>void}) :  React.ReactElement {

    const filters = FILTERS;
    
    const items :string[] = filters.map( (x)=>x[0] );

    const [ selectedIndex, setSelectedIndex ] = React.useState<number>(0);
    
    const setItemIndex = (index:number):void=> {
	console.log(TAG+'setItemIndex: index=' + index);
	if(index >= items.length || index < 0) return;
	console.log(TAG+'filters[index][1]'+filters[index][1].name);
	props.setFilter(filters[index][1]);
	setSelectedIndex(index);
    }

	React.useEffect(()=> {
	    for(let i=0;i<filters.length;i++) {
		if(filters[i][1].name === props.filter.name) {
		    setSelectedIndex(i);
		}
	    }
	},[props.filter.name]);
	
    return (
	<Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
	  select filter 
          <DropDownListMenu setSelectedIndex={setItemIndex}
			    selectedIndex={selectedIndex}
			    minWidth={"12em"}
			    onChange={props.onChange}
			    items={items}
	  />
	</Box>);
}

