// File: routes_new_auth_password.tsx
//

import {Route} from "react-router-dom";
import {Routes} from 'react-router-dom';

import {SignIn} from "../pages_pub/reg_pages/signin";
import { Home } from "../pages_pub/portal_pages/home";
import {EmailVerified} from "../pages_pub/reg_pages/email_verified";
import {EmailNotVerified} from "../pages_pub/reg_pages/email_not_verified";
import {ForgotPassword} from "../pages_pub/reg_pages/forgot_password";
import {ActivateMobileApp} from "../pages_pub/portal_pages/activate_mobile_app";
import {MobileUpgrade} from "../pages_pub/portal_pages/mobile_upgrade/mobile_upgrade";
import { MobileUpgradeLaunchPopup } from "../pages_pub/portal_pages/mobile_upgrade/mobile_upgrade_launch_popup";
import { MobileActInstruct } from "../pages_pub/portal_pages/mobile_upgrade/mobile_act_instruct";
import {NotFound} from "../pages_pub/other_pages/not_found";
import {DisplayMobileDevices} from "../pages_pub/portal_pages/display_mobile_devices";
import {DesktopAppLicenseKey} from "../pages_pub/portal_pages/desktop_app_license_key";
import { ChangePassword } from "../pages_pub/portal_pages/change_password";
import { ChangeEmailVerified } from  "../pages_pub/portal_pages/change_email_verified";
import { PubRequireAuth } from "../pages_pub/PubRequireAuth";
import { PubMainPagesProvider } from '../pages_pub/utils/pub_main_pages_provider';
import { AdminRequireAuth } from "../pages_admin/AdminRequireAuth";
import { AdminPagesProvider } from '../pages_admin/utils_admin/admin_pages_provider';
import { AdminHome } from '../pages_admin/auth_pages/admin_home';
import { ListAllLicenses } from "../pages_admin/auth_pages/list_all_licenses";
import { ListLicensesEmailSearchResults } from "../pages_admin/auth_pages/list_licenses_email_search_results";
import { EditLicense } from "../pages_admin/auth_pages/edit_license";
import { EditAdminList } from "../pages_admin/auth_pages/edit_admin_list";
import { AdminCannotChangeEmail } from "../pages_pub/portal_pages/admin_cannot_change_email";
import { MobileUpgradeDirectToLink } from "../pages_pub/portal_pages/mobile_upgrade/mobile_upgrade_direct_to_link";
import { MOBILE_UPGRADE_USES_POPUP } from '../config/config';
import { TimedOut } from "../pages_other/timed_out";
import { SendMobUpgradeInvitesPage } from "../pages_admin/auth_pages/send_mobupgrade_invites";
import { MobileLiteVersionLinks } from "../pages_pub/portal_pages/mobile_upgrade/mobile_lite_version_links";
import { LicensesStatusForMobUpgrade } from "../pages_pub/portal_pages/mobile_upgrade/licenses_status_for_mobupgrade";
import { PreHome } from "../pages_pub/portal_pages/prehome";
import { FreeMobileLicenses } from "../pages_admin/auth_pages/free_mobile_licenses";
import { CreateAcctFromEmailLink } from "../pages_pub/reg_pages/create_acct_from_email_link";
import { CREATE_ACCT_ENDING_URL, MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL,
    SIGNIN_ENDING_URL, MOBILE_UPGRADE_SIGNIN_ENDING_URL,
    PASSWORD_RESET_ENDING_URL,
    PWLESS_SIGNIN_ENDING_URL,
    PWLESS_REQ_LINK_ENDING_URL,
} from '../extlinks/portal_comm_types';
import { PasswordResetFromLink } from "../pages_pub/reg_pages/password_reset_from_link";
import { CreateAcctAssignedPassword } from "../pages_pub/reg_pages/create_acct_for_assigned_password";
import { ListMobUpgradeSearchResults } from "../pages_admin/auth_pages/list_mobupgrade_search_results";
import { PWLessRequestEmailLink } from "../pages_pub/reg_pages/pwless_request_email_link";
import { PWLessSignInFromEmailLink } from "../pages_pub/reg_pages/pwless_signin_from_email_link";
import { ECOMM_LICENSE_INFO_URL, ECOMM_MOBUPGRADE_LANDING_URL, ECOMM_DISPLAY_LICENSE_KEYS_LANDING_URL,
    ECOMM_NOT_FOUND_URL, ECOMM_EMAIL_LOGIN_URL
       } from "../extlinks/portal_comm_types";
import { ECommMobileUpgradeLanding } from "../pages_ecomm/mobile_upgrade_landing";
import { ECommDisplayLicenseKeysLanding } from "../pages_ecomm/display_license_keys_landing";
import { ECommLicenseInfoLanding } from "../pages_ecomm/license_info_landing";
import { ECommDesktopInstallInstructions, ECOMM_DESKTOP_INSTALL_URL } from '../pages_ecomm/display_license_keys/desktop_install';
import { ECommMobileInstallInstructions, ECOMM_MOBILE_INSTALL_URL } from '../pages_ecomm/display_license_keys/mobile_install';
import { ECommRequireAuth } from "../pages_ecomm/EcommRequireAuth";
import { ECommEmailLogin } from "../pages_ecomm/email_login_page";
import { ECommNotFound } from "../pages_ecomm/ecomm_not_found";
import { ECommAuthProvider } from "../auth_provider/ecomm_auth_provider";

interface PageProps {
}

export function AppRoutes(_props:PageProps) : React.ReactElement {

    
    return (
	<Routes>

	  <Route path='/not-found' element={<NotFound />} />

	  <Route path='/timed-out' element={<TimedOut />} />	  
	  
	  <Route  path='/'
		  element={<SignIn name={"Sign In"}/>}
          />

  {/* we have two separate route names for signin so we can avoid infinite loops*/}
	  <Route  path={SIGNIN_ENDING_URL} element={<SignIn name={"Sign In"}/>}
          />

	  <Route  path={MOBILE_UPGRADE_SIGNIN_ENDING_URL} element={<SignIn name={"Mobile Upgrade Sign In"}/>}
          />	  

	  <Route path={CREATE_ACCT_ENDING_URL+'/:url_params'} element={<CreateAcctFromEmailLink name={"Create Account"}/>} />

	  <Route path={MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL+'/:url_params'}
		 element={ <CreateAcctFromEmailLink name={"Mobile Upgrade Create Acct"}/>} />

	  <Route path={PASSWORD_RESET_ENDING_URL+'/:url_params'}
	        element={<PasswordResetFromLink name={"Password Reset"}/>} />

	  <Route path='/create-account'
		 element={ <CreateAcctAssignedPassword name={"Create Acct"}/>} />

	
	  <Route path={PWLESS_REQ_LINK_ENDING_URL}
		 element={<PWLessRequestEmailLink name={"Request Email Signin Link"} />} />

	  <Route path={PWLESS_SIGNIN_ENDING_URL+ '/:token'}
                 element={<PWLessSignInFromEmailLink name={"Signin"} />} />
	  
	 { /*
	      
	  <Route path='/no-eligible-acct'
		  element={<NoEligibleAcct name={"No Eligible Acct"}/>}	    
	    />
	      <Route path='/select-email' element={<SelectEmail name={"Select Email"}/>} />
	  <Route path='/make-account' element={<MakeAccount name={"Make Account"}/>} />	  
	  <Route path='/register-account' element={<RegisterAccount name={"Register Account"}/>} />
	  <Route path='/invited-email'element={<InvitedEmail name={"Invited Email"}/>}/>	      
	   */
	 }

	  <Route path='/forgot-password'
		 element={<ForgotPassword name={"Forgot Password"}/>}
          />
	  
	  <Route path='/email-verified/:tag/:token' element={<EmailVerified name={"Email Verified"}/>}
             />

	  <Route
              path='/email-not-verified'
              element={<EmailNotVerified name={"Email Not Verified"}/>}
           />
	    
	    <Route path={ECOMM_NOT_FOUND_URL}
		   element={<ECommNotFound/>}
	    />
	    
	    <Route path={ECOMM_EMAIL_LOGIN_URL+'/:url_params'}
		   element={<ECommEmailLogin/>}
	    />	  

	  <Route element={<ECommAuthProvider>
	                     <ECommRequireAuth/>
       	                  </ECommAuthProvider>} >
	    
	    <Route path={ECOMM_MOBUPGRADE_LANDING_URL+'/:url_params'}
		   element={<ECommMobileUpgradeLanding/>}
	    />

	    <Route path={ECOMM_DISPLAY_LICENSE_KEYS_LANDING_URL+'/:url_params'}
		   element={<ECommDisplayLicenseKeysLanding/>}
	    />
	    
	    <Route path={ECOMM_LICENSE_INFO_URL+'/:url_params'}
		   element={<ECommLicenseInfoLanding/>}
	    />
	    
	    <Route path={ECOMM_DESKTOP_INSTALL_URL+'/:url_params'}
		   element={<ECommDesktopInstallInstructions/>}
	    />
	    
	    <Route path={ECOMM_MOBILE_INSTALL_URL+'/:url_params'}
		   element={<ECommMobileInstallInstructions/>}
	    />
	  </Route>

	  <Route element={<PubRequireAuth />} >

            <Route path='/prehome'
		   element={<PreHome/>} />

            <Route path='/home'
		   element={
		       <PubMainPagesProvider>
			 <Home/>
		       </PubMainPagesProvider>} />	    

            <Route path='/mobile-upgrade/mobile-lite-version-links'
		   element={
		   <PubMainPagesProvider>
		     <MobileLiteVersionLinks/>
		   </PubMainPagesProvider> } />

            <Route path='/mobile-upgrade/licenses-status'
		   element={
		   <PubMainPagesProvider>
		     <LicensesStatusForMobUpgrade/>
		   </PubMainPagesProvider> } />	    
	    
	    <Route path='/mobile-upgrade/mobile-upgrade'
		   element={
		       <PubMainPagesProvider>
			 <MobileUpgrade/>
		       </PubMainPagesProvider>} />

	    {MOBILE_UPGRADE_USES_POPUP ?
	          (<Route path='/mobile-upgrade/mobile-upgrade-launch-popup'
		   element={
		       <PubMainPagesProvider>
			 <MobileUpgradeLaunchPopup/>
		       </PubMainPagesProvider>} />) :
	    	    
	    (<Route path='/mobile-upgrade/mobile-upgrade-direct-to-link'
		   element={
		       <PubMainPagesProvider>
			 <MobileUpgradeDirectToLink/>
		       </PubMainPagesProvider>} />)
	    }
	    
	    <Route path='/mobile-upgrade/mobile-act-instruct'
		   element={
		       <PubMainPagesProvider>
			 <MobileActInstruct/>
		       </PubMainPagesProvider>} />


	    <Route path='/activate-mobile'
		   element={
		       <PubMainPagesProvider>
			 <ActivateMobileApp/>
		       </PubMainPagesProvider>} />
	    
	    <Route path='/display-mobile'
		   element={
		       <PubMainPagesProvider>
			 <DisplayMobileDevices/>
		       </PubMainPagesProvider>} />
	    
	    <Route path='/desktop-app-key'
		   element={
		       <PubMainPagesProvider>
			 <DesktopAppLicenseKey/>
		       </PubMainPagesProvider>} />


	  <Route element={
	      <AdminPagesProvider>
		<AdminRequireAuth />
	      </AdminPagesProvider>} >
	    	    
	    <Route path='/change-email'
		   element={
		       <PubMainPagesProvider>
			 <ChangeEmailVerified/>
		       </PubMainPagesProvider>} />
  	  </Route>

	    	    
	    <Route path='/admin-cannot-change-email'
		   element={
		       <PubMainPagesProvider>
			 <AdminCannotChangeEmail/>
		       </PubMainPagesProvider>} />
	    

	    <Route path='/change-password'
		   element={
		       <PubMainPagesProvider>
			 <ChangePassword/>
		       </PubMainPagesProvider>} />


	  
	  </Route>

     {/********************************************************************/}

	  <Route element={
	      <AdminPagesProvider>
		<AdminRequireAuth />
	      </AdminPagesProvider>} >
	    
            <Route
		path='/admin'
		element={
		    <AdminHome/>} />
	    	    
            <Route
		path='/admin/home'
		element={
		    <AdminHome/>} />
	    
            <Route 
	      path='/admin/list-all-licenses'
		element={
		    <ListAllLicenses/>} />	    

            <Route 
		   path='/admin/list-licenses-email-search-results'
		   element={
			 <ListLicensesEmailSearchResults/>} />

            <Route 
		   path='/admin/edit-license'
		   element={
			 <EditLicense/>} />

            <Route 
		   path='/admin/free-mobile-licenses'
		   element={
		       <FreeMobileLicenses/>} />


            <Route 
		   path='/admin/edit-admin-list'
		   element={
			 <EditAdminList/>} />

            <Route 
	      path='/admin/send-mobupgrade-invites'
		element={
		    <SendMobUpgradeInvitesPage/>} />


            <Route 
	      path='/admin/list-mobupgrade-search-results'
		element={
		    <ListMobUpgradeSearchResults/>} />	    	    


	</Route>	  	  

	</Routes>);
}    

