// File: urls.ts
//


export const TDB_WEBSITE_URL="https://discoverybible.com/";

export const APPLE_CONNECT_STORE_MOBILE_APP='https://apps.apple.com/us/app/the-discovery-bible/id1488046956?platform=iphone';
export const GOOGLE_PLAY_STORE_MOBILE_APP='https://play.google.com/store/apps/details?id=com.thediscoverybible.applite';




