// File: use_mobupgrade_routes.ts
//

export class UseMobUpgradeRoutes {

    private static KEY = 'USE_MOBUPGRADE_ROUTES';

    static clear() : void {
        window.localStorage.removeItem( UseMobUpgradeRoutes.KEY);
    }

    static is_on() : boolean {
        const  x = window.localStorage.getItem( UseMobUpgradeRoutes.KEY);
        return x !== undefined && x ==='true';
    }

    static set_on() : void {
        window.localStorage.setItem( UseMobUpgradeRoutes.KEY,'true');
    }
    
    static set_off() : void {
        window.localStorage.setItem( UseMobUpgradeRoutes.KEY,'false');        
    }
}


