// File: server_comm.ts
//

// File: server_comm.ts
//
//import {getAuth } from 'firebase/auth';        
import {
    MsgStatus,
    ResultType,
    RequestType,
    ResponseType,
    ReqEmailMobileActCode,
    GetUpgradePopupSession,
    CheckLicenseEligibleForMobileUpgrade,
    PopupCreateMobileUpgradeLicense,
    ReqMobUpgradeFSProdURL,
    EComm,
    SessionManage,
    ECOMM_INIT_SESSION_COOKIE_API_URL,
    GET_SESSION_TOKEN_API_URL,
} from '../../extlinks/portal_comm_types';

import * as https from 'https';
import { getFunctions,  httpsCallableFromURL } from "firebase/functions";
import { get_firebase_functions_api,
         get_firebase_functions_portalcomm } from '../../config/firebase_config';

const TIMEOUT=30000;


const TAG='server_comm.ts: ';
const DEBUG=true;

const PORTAL_FUN_URL=get_firebase_functions_portalcomm();
const API_FUN_URL=get_firebase_functions_api();

// for calling onCallable firebase functions
async function query_server<TIn,TOut extends ResultType>(input : TIn,
                                                         cmd:string,
                                                         options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null> {

    const functions = getFunctions();

    const portalFun = httpsCallableFromURL<RequestType<TIn>,ResponseType<TOut>>(
        functions,
        PORTAL_FUN_URL,
        { timeout:TIMEOUT}        
//        {limitedUseAppCheckTokens:true }   // is in beta
    );

    const msg_input:RequestType<TIn> = { cmd:cmd,  input:input }
    
    return new Promise<TOut|null>( (resolve,reject) => {

        if(options && options.cancelExec) {
            setInterval( ()=> {
                if(options && options.cancelExec && options.cancelExec()) {
                    resolve(null);
                }
            }, 5000);
        }
        
        portalFun(msg_input).then( (x) => {
            resolve(x.data.result);
        }).catch( (error) => {
            console.error(TAG+'query_server: error=' + String(error));
            console.error(TAG+'query_server: problems with appcheck for localhost?');                        
            //console.trace();
            //throw new Error(error);
            reject(error);
        });
    });
}

// for calling onRequest firebase functions
async function query_server_on_request<TIn,TOut>(input : TIn,
                                                 ending_url:string,
                                                 options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null> {

    const url = API_FUN_URL+ending_url;
    
    console.log(TAG+'query_server_on_request: url='+url);
    //console.log(TAG+'query_server_on_request: input=',input);
    
    return new Promise<TOut|null>( (resolve,reject) => {

        if(options && options.cancelExec) {
            setInterval( ()=> {
                if(options && options.cancelExec && options.cancelExec()) {
                    resolve(null);
                }
            }, 5000);
        }

        try {
            const xhttp = new XMLHttpRequest();

            xhttp.open('POST', url, true);
            xhttp.setRequestHeader('Content-Type', 'application/json');
            xhttp.setRequestHeader('Cache-Control', 'private');            

            xhttp.onreadystatechange = function() {
                
                if(this.readyState == 4) {
                    if(this.status==200) {
                        console.log(TAG+'query_server_on_request: this.response.headers=' ,this.getAllResponseHeaders());
                        console.log(TAG+'query_server_on_request: this.responseText=' + this.responseText);
                        resolve(JSON.parse(this.responseText) as TOut);                    
                    } else {
                        console.log(TAG+'query_server_on_request: this.responseText=' + this.responseText);                        
                        console.log(TAG+'query_server_on_request: error: statusText='+this.statusText);
                        console.log(TAG+'query_server_on_request: error: status='+this.status);                        
                        resolve(null);
                    }
                }
            }
            
            xhttp.send(JSON.stringify(input));
            
        } catch(error) {
            console.log(TAG+'query_server_on_request: catch error=' + error);
            resolve(null);
        }
        
    });
}

async function query_server_on_request_with_fetch<TIn,TOut>(input : TIn,
                                                 ending_url:string,
                                                 options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null> {

    const url = API_FUN_URL+ending_url;
    
    console.log(TAG+'query_server_on_request: url='+url);
    //console.log(TAG+'query_server_on_request: input=',input);

    let response : Response|null;
    try {

        response = await fetch(url,{method:'POST',
                                    body: JSON.stringify(input),
                                          headers : {
                                              "Content-Type": "application/json",
                                          },
                                          mode:'cors'});
        
        console.log(TAG+'query_server_on_request: response=',response);
        //console.log(TAG+'query_server_on_request: response.text()=',await response.text());
        
        //console.log(TAG+'query_server_on_request: response.body=',response.body);    
        //console.log(TAG+'query_server_on_request: response.json()=',await response.json());
    } catch(error) {
        console.error(TAG+'query_server_on_request: fetch: error='+error);
        response = null;
    }
    
    return new Promise<TOut|null>( (resolve,reject) => {

        if(options && options.cancelExec) {
            setInterval( ()=> {
                if(options && options.cancelExec && options.cancelExec()) {
                    resolve(null);
                }
            }, 5000);
        }

        if(response==null) {
            resolve(null);
            return;
        }

        
        response.json().then( (js)=>{
            
            console.log(TAG+'query_server_on_request: js=',js);
            
            resolve(JSON.parse(js) as TOut);
            //resolve(js as TOut);
            
        }).catch(error=>{
            console.error(TAG+'query_server_on_request: error='+error);
            resolve(null);
        });
        
    });
}

export class ECommServerComm {
    private ECommServerComm_:undefined;

    async handle_init_session_cookie(input:SessionManage.ECommInitSessionCookie.InType) : Promise<SessionManage.ECommInitSessionCookie.OutType> {
        const ending_url = '/sessions' + ECOMM_INIT_SESSION_COOKIE_API_URL;
        const out = await query_server_on_request<SessionManage.ECommInitSessionCookie.InType,
                                                  SessionManage.ECommInitSessionCookie.OutType>(input, ending_url);  
        if(out==null) {
            return SessionManage.ECommInitSessionCookie.ERROR_OUT;
        }
        return out;
    }

    async handle_refresh_session_cookie_and_token(input:SessionManage.GetSessionCookieToken.InType) : Promise<SessionManage.GetSessionCookieToken.OutType> {
        const ending_url = '/sessions' + GET_SESSION_TOKEN_API_URL;
        const out = await query_server_on_request<SessionManage.GetSessionCookieToken.InType,
                                                  SessionManage.GetSessionCookieToken.OutType>(input, ending_url);  
        if(out==null) {
            return SessionManage.GetSessionCookieToken.ERROR_OUT;
        }
        return out;
    }
    
    async handle_req_email_mobile_act_code(input:ReqEmailMobileActCode.InType) : Promise<ReqEmailMobileActCode.OutType> {
        
        const out = await query_server<ReqEmailMobileActCode.InType,
                                       ReqEmailMobileActCode.OutType>(input,
                                                                  ReqEmailMobileActCode.CMD);

        if(out == null) {
            return ReqEmailMobileActCode.ERROR_OUT;
        }

        return out;                              
    }


    async handle_req_session_token(input:EComm.ReqECommSessionToken.InType) : Promise<EComm.ReqECommSessionToken.OutType> {
        
        const out = await query_server<EComm.ReqECommSessionToken.InType,
                                       EComm.ReqECommSessionToken.OutType>(input,
                                                                  EComm.ReqECommSessionToken.CMD);

        if(out == null) {
            return EComm.ReqECommSessionToken.ERROR_OUT;
        }

        return out;                              
    }


    async handle_refresh_session_token(input:EComm.RefreshECommSessionToken.InType) : Promise<EComm.RefreshECommSessionToken.OutType> {
        
        const out = await query_server<EComm.RefreshECommSessionToken.InType,
                                       EComm.RefreshECommSessionToken.OutType>(input,
                                                                  EComm.RefreshECommSessionToken.CMD);

        if(out == null) {
            return EComm.RefreshECommSessionToken.ERROR_OUT;
        }

        return out;                              
    }
    
    async handle_req_session_token_is_ok(input:EComm.ReqSessionTokenIsOK.InType) : Promise<EComm.ReqSessionTokenIsOK.OutType> {
        
        const out = await query_server<EComm.ReqSessionTokenIsOK.InType,
                                       EComm.ReqSessionTokenIsOK.OutType>(input,
                                                                  EComm.ReqSessionTokenIsOK.CMD);

        if(out == null) {
            return EComm.ReqSessionTokenIsOK.ERROR_OUT;
        }

        return out;                              
    }

    async handle_desktop_license_eligible_for_mobile_upgrade(input:CheckLicenseEligibleForMobileUpgrade.InType) : Promise<CheckLicenseEligibleForMobileUpgrade.OutType> {

        if(DEBUG) console.log(TAG+'entering handle_desktop_eligible_for_mobile_upgrade');

        const out = await query_server<CheckLicenseEligibleForMobileUpgrade.InType,
                                        CheckLicenseEligibleForMobileUpgrade.OutType>(input,
                                                                  CheckLicenseEligibleForMobileUpgrade.CMD);

        if(DEBUG) console.log(TAG+'handle_desktop_license_eligible_for_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid :false,
                error_message:'server error'
            }
        }

        return out;
    }

    async handle_get_encrypted_session(input : GetUpgradePopupSession.InType) : Promise<GetUpgradePopupSession.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_get_encrypted_session');

        const out = await query_server<GetUpgradePopupSession.InType,
                                        GetUpgradePopupSession.OutType>(input,
                                                                  GetUpgradePopupSession.CMD);

        if(DEBUG) console.log(TAG+'handle_get_encrypted_session: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                encrypted_session : {
                    secured_data : '',
                    secured_key : ''
                }
            }
        }


        return out;
    }


    async handle_popup_create_mobile_upgrade(input:PopupCreateMobileUpgradeLicense.InType) : Promise<PopupCreateMobileUpgradeLicense.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<PopupCreateMobileUpgradeLicense.InType,
                                        PopupCreateMobileUpgradeLicense.OutType>(input,
                                                             PopupCreateMobileUpgradeLicense.CMD);

        if(DEBUG) console.log(TAG+'handle_popup_create_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid:false,
                error_message:'server error',
                cancel_order_status:PopupCreateMobileUpgradeLicense.CancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED
            }
        }

        return out;
    }


    async handle_get_mob_upgrade_prod_url(input:ReqMobUpgradeFSProdURL.InType) : Promise<ReqMobUpgradeFSProdURL.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<ReqMobUpgradeFSProdURL.InType,
                                        ReqMobUpgradeFSProdURL.OutType>(input,
                                                             ReqMobUpgradeFSProdURL.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                fs_prod_url: ''
            }
        }

        return out;
    }

    async handle_req_landing_page_type(input:EComm.ReqECommLandingPageType.InType) : Promise<EComm.ReqECommLandingPageType.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_landing_page_type');
        const out = await query_server<EComm.ReqECommLandingPageType.InType,
                                                             EComm.ReqECommLandingPageType.OutType>(input,
                                                             EComm.ReqECommLandingPageType.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return EComm.ReqECommLandingPageType.ERROR_OUT;
        }

        return out;
    }

    async handle_req_license_keys(input:EComm.ECommReqLicenseKeys.InType) : Promise<EComm.ECommReqLicenseKeys.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_license_keys');
        const out = await query_server<EComm.ECommReqLicenseKeys.InType,
                                                             EComm.ECommReqLicenseKeys.OutType>(input,
                                                             EComm.ECommReqLicenseKeys.CMD);

        if(DEBUG) console.log(TAG+'handle_req_license_keys: out=',out);

        if(out == null) {
            return EComm.ECommReqLicenseKeys.ERROR_OUT;
        }

        return out;
    }

    async handle_req_user_licenses_info(input:EComm.ECommGetUserLicensesInfo.InType) : Promise<EComm.ECommGetUserLicensesInfo.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_req_user_licenses_info');
        const out = await query_server<EComm.ECommGetUserLicensesInfo.InType,
                                                             EComm.ECommGetUserLicensesInfo.OutType>(input,
                                                             EComm.ECommGetUserLicensesInfo.CMD);

        if(DEBUG) console.log(TAG+'handle_req_user_licenses_info: out=',out);

        if(out == null) {
            return EComm.ECommGetUserLicensesInfo.ERROR_OUT;
        }

        return out;
    }

    async handle_check_ecomm_url_params_and_session_token(input:EComm.CheckECommUrlParamsAndSessionToken.InType) : Promise<EComm.CheckECommUrlParamsAndSessionToken.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_check_ecomm_url_params_and_session_token');
        const out = await query_server<EComm.CheckECommUrlParamsAndSessionToken.InType,
                                                             EComm.CheckECommUrlParamsAndSessionToken.OutType>(input,
                                                             EComm.CheckECommUrlParamsAndSessionToken.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return EComm.CheckECommUrlParamsAndSessionToken.ERROR_OUT;
        }

        return out;
    }        

    async handle_check_ecomm_url_params(input:EComm.CheckECommUrlParams.InType) : Promise<EComm.CheckECommUrlParams.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_check_ecomm_url_params');
        const out = await query_server<EComm.CheckECommUrlParams.InType,
                                                             EComm.CheckECommUrlParams.OutType>(input,
                                                             EComm.CheckECommUrlParams.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return EComm.CheckECommUrlParams.ERROR_OUT;
        }

        return out;
    }        


    
}
 
export function getECommServerComm() : ECommServerComm {
    return new ECommServerComm();     
}
