// File: mobile_lite_version_links.tsx
//


import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { MainLayout } from '../../utils/main_layout';
import {
    APPLE_CONNECT_STORE_MOBILE_APP,
    GOOGLE_PLAY_STORE_MOBILE_APP
} from '../../../config/urls';
import { AndroidLogo, AppleIOSLogo } from '../../utils/store_icons';

const TAG='mobile_lite_version_links.tsx: ';
const DEBUG=false;

const FONT='body1';

function LimitedVersion()  :React.ReactElement {

    const ios_url = APPLE_CONNECT_STORE_MOBILE_APP;
    const android_url = GOOGLE_PLAY_STORE_MOBILE_APP;
    
   return  (
    <Box sx={{width:'100%', mt:0}}>	      
<Box sx={{ borderRadius: '10px',
           ml:2,mr:2,p:3 }}>	      
	  
    	  <Typography align='center' variant={FONT}>
        Download  the Lite version of the mobile app from the store for your device.
          </Typography>

       <Box style={{display:'flex', flexDirection:'column',
	   marginTop:'1em',
	  alignItems : 'center'}}>

       <Box style={{display:'flex', flexDirection:'row',
	   marginTop:'0', alignItems : 'center', justifyContent:'center'}}>
	 <AppleIOSLogo/>
	<Link  component='button'
	  variant={FONT}
	  style={{ textDecoration:'none'}}
	  onClick={()=> {
	      window.open(ios_url);
	  }}>
       IOS  Apple Store 
	</Link>
	</Box>

       <Box style={{display:'flex', flexDirection:'row', height:'1em',
	   marginTop:'1em',marginBottom:'0.5em', alignItems : 'center', justifyContent:'center'}}>
	 <AndroidLogo/>
	<Link  component='button'
	  variant={FONT}	  
	  style={{ textDecoration:'none'}}	  
	  onClick={()=> {
	      window.open(android_url);
	  }}>
         Android Google Play Store   
	</Link>
	</Box>
	
	</Box>	

	  <div style={{height:"1em"}}/>
	  
    	  <Typography align='center' variant={FONT}>
       You will need to install the Lite version before installing the full version.
          </Typography>		  
</Box>
</Box>);
}


export function MobileLiteVersionLinks(props : {}) :React.ReactElement {

    return (<>
      
    <MainLayout title='Download Limited Mobile Version'
		dont_refresh_user_info={true}
    >
    
      <LimitedVersion/>

  </MainLayout> 
      </>	
    );		  	        
}
