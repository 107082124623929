// File: spinner.tsx
//

import * as React from 'react';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';

export function Spinner(props : {open:boolean} ) : React.ReactElement {

    return (
	<Backdrop
	invisible={true}
	open={props.open} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	<CircularProgress/>
	</Backdrop>);
}
