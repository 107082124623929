// File: load_script.tsx
//

const DEBUG=true;
const TAG='load_script.tsx: ';

// return script element and function to remove loadSuccess and
// loadFailed event listeners
export function loadScript(src:string,
                           loadSuccess: ()=>void,
                           loadFailed: ()=>void) : { script_elem:HTMLScriptElement,
                                                     remove_listeners : ()=>void } {
	
	
    if(DEBUG) console.log(TAG+'entering loadScript');
			       
    // Fetch existing script element by src
    // It may have been added by another intance of this hook
    let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
    if( script) {
        script.remove();
    }
        
    //if( ! script) {
	
    script = document.createElement("script");

    script.src = src;
    script.async = true;
    
    // Add script to document head
    document.head.appendChild(script);
//}

    //console.log('head.inner=Html='+ document.head.innerHTML);
    
    // Add event listeners
    script.addEventListener("load", loadSuccess);
    script.addEventListener("error", loadFailed);

    const remove_listeners = ():void => {
        //if (script) {
            script.removeEventListener("load", loadSuccess);
            script.removeEventListener("error", loadFailed)
        //}
    }
    
    // Remove event listeners on cleanup
    return {script_elem : script, remove_listeners};
}
