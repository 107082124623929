// File: reg_logger_session_token.ts
//

const alphabet = "0123456789abcdefghijklmnopqrstuvwxyz";

function get_random_string(n:number) : string {

    let out ='';
    for(let i=0;i<n;i++) {
        const r= Math.floor(Math.random()*alphabet.length);
        out += alphabet[r].toUpperCase();
    }
    return out;
}

const KEY = 'RegLoggerSessionToken'

export function get_reg_logger_session_token() : string {
    let token = window.localStorage.getItem(KEY);
    if(token) return token;
    token = get_random_string(10);
    window.localStorage.setItem(KEY,token);
    return token;
}

export function clear_reg_logger_session_token() : void {
    window.localStorage.setItem(KEY,'');    
}


