// File: mobupgradeable_licenses_table.tsx

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { GetMobUpgradeableLicenses } from '../../../extlinks/portal_comm_types';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from '../utils_config';
import { HelpPopup } from '../help_popup';
import { MaxItemsPerPage} from './max_table_items_per_page';
import { PageSelection} from './page_selection';
import { RowFilter, SelectFilterRows } from './select_filter_rows';
import { createTheme } from '@mui/material/styles';
import { getAdminServerComm } from '../admin_server_comm';
import { get_session_token } from '../../../utils_common/logger';
import { MsgStatus } from '../../../extlinks/portal_comm_types';
import { MsgDialogSimple } from '../../../utils_common/msg_dialog';
import { SetOneInvitePerLicense } from './one_invites_per_license';
import { get_on_local_host } from '../../../config/firebase_config';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import { LicenseSorter } from '../mobupgrade_invites/select_license_sorter';

//import IconButton from '@mui/material/IconButton';

const TAG='mobupgradeable_licenses_table.tsx: ';

type LicenseInfo = GetMobUpgradeableLicenses.ParentLicenseInfo;

export type EditLicenseLocState = {
    license:LicenseInfo,
}
    
export type ListAllLicensesLocState = {
    page_num?:number,
    allValidUserLicenses : LicenseInfo[]
}

const EMAILS_ABBREVS=(
<>
<div>

  <Table sx={{textAlign:'left'}}>
  <TableBody>    
    <tr key='x1'><td style={{paddingRight:'2em'}}>W</td><td>web portal</td></tr>
    <tr key='x2'><td>FS</td><td>fastspring</td></tr>
    <tr key='x3'><td>AS</td><td>activation server</td></tr>
    <tr key='x4'><td>AC</td><td>active campaign</td></tr>
  </TableBody>        
  </Table>
</div>
</>
);

function get_emails_entry(row :  LicenseInfo) : React.ReactElement {

    function set_emails_mp(emails : string[], src:string, mp : Map<string,string[]>) : void {
	for(let x of emails) {
	    if( ! mp.has(x)) mp.set(x,[]);
	    const y = mp.get(x);
	    if(y) mp.set(x, y.concat(src));
	}
    }
    
    const mp = new Map<string,string[]>();

    const portal_email = row.portal_email ? row.portal_email: '';

    if(row.portal_email) mp.set(row.portal_email,['W'])
    
    set_emails_mp(row.as_emails,'AS',mp);
    
    set_emails_mp(row.fs_emails,'FS',mp);
    
    set_emails_mp(row.ac_emails,'AC',mp);

    let lines :string[] = [];
    
    if(portal_email !== '') {
	for(let [key,value] of mp) {
	    if(key === portal_email) {
		lines.push(key + ' (' + value.join(',') + ')');
		break;
	    }
	}
    }
    
    for(let [key,value] of mp) {
	if(key !== portal_email && key !== '' ) {
	    lines.push(key+ ' (' + value.join(',') + ')');
	}
    }

    let cnt = 0;
    
    return  (<>
	{
	    lines.map( (line) => {
		return (<div key={String(cnt++)}>{line}</div>)
	    })
	}
          </>
    )
}

function get_emails_of_license_info(row :  LicenseInfo) : string[] {
    let emails = row.ac_emails;
    emails = emails.concat(row.fs_emails);
    emails = emails.concat(row.as_emails);
    if(row.portal_email) emails = emails.concat(row.portal_email);
    const x = new Set<string>(emails);
    return Array.from(x);
}

function HeadingsRow(props:{checkBoxChecked:boolean,
    checkBoxOnChange:(checked:boolean)=>void}) :  React.ReactElement {


	
    const handleChange = (e:React.ChangeEvent<HTMLInputElement>):void => {
	if(e.target) {
	    props.checkBoxOnChange(e.target.checked);
	}
    }
    
    return (
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}> 
          <TableRow key='start-row'>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">
	      <Checkbox size='small' onChange={handleChange} checked={props.checkBoxChecked}/>	      
	    </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">
	      <HelpPopup label={'Email'} open={true} msg={EMAILS_ABBREVS}/>	      
	    </TableCell>
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Invited
	     </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Issued
	     </TableCell>

	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Expiry
	     </TableCell>
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Desktop License
	     </TableCell>

	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Subscription
	     </TableCell>	     	     
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Upgrade Purchased
	     </TableCell>	     
	
          </TableRow>
        </TableHead>
		
    );
}

function LicenseInfoRow(props :
    {   headingChecked : boolean,
	checkBoxOnChange : (checked:boolean, index:number)=>void,
	rowIndex:number,
	indexToLicenseInfo:React.MutableRefObject<Map<number, LicenseInfo>>,
	license_info : LicenseInfo }) :  React.ReactElement {
    
    const x = props.license_info;
    
    const issue_date = x.issue_date ? x.issue_date : '';
    const expiry_date = x.expiry_date ? x.expiry_date : '';
    const invite_sent_date = x.invite_sent_date ? x.invite_sent_date : '';
    const mobupgrade_purchase_date = x.mobupgrade_purchase_date ? x.mobupgrade_purchase_date : '';        
    const emails = get_emails_entry(x);

    const handleCheckbox = (e:React.ChangeEvent<HTMLInputElement>):void => {
	if(e.target) {
	    if(e.target.checked) props.indexToLicenseInfo.current.set(props.rowIndex, props.license_info);
	    else                 props.indexToLicenseInfo.current.delete(props.rowIndex);
	    props.checkBoxOnChange(e.target.checked, props.rowIndex);	    
	}
    }

//	    console.log('rowIndex='+props.rowIndex);

   const is_subscription = props.license_info.subscription_status==='IS_SUBSCRIPTION' ? 'Y':'';
	    
    return (
 <TableRow key={'LC-'+x.desktop_license_key + '-' + (props.rowIndex+1)}
		     sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >


      { props.headingChecked &&
          (<TableCell size="small" align="left">
   	     <Checkbox size='small' checked={props.headingChecked} onChange={handleCheckbox}/>
          </TableCell>)
      }
      
      { ! props.headingChecked &&
          (<TableCell size="small" align="left">
	     <Checkbox size='small' onChange={handleCheckbox}/>
          </TableCell>)
      }

            
      <TableCell size="small" align="left">{emails}</TableCell>
      
      <TableCell size="small" align="left">{invite_sent_date}</TableCell>
      
      <TableCell size="small" align="left">{issue_date}</TableCell>
      
      <TableCell size="small" align="left">{expiry_date}</TableCell>
      
      <TableCell size="small" align="left" style={{ maxWidth:'10em', whiteSpace:'normal',
	  wordWrap:'break-word'}}>{x.desktop_license_key}</TableCell>

     <TableCell size="small" align="left">{is_subscription      }</TableCell>	                              
 
      <TableCell size="small" align="left">{mobupgrade_purchase_date}</TableCell>	                              
    
</TableRow>);
    
}

export function LicenseInfoRows(props : { licensesInRange:LicenseInfo[],
    checkBoxOnChange : (checked:boolean, index:number)=>void,    
    setIndexToLicenseInfo : (mp : Map<number,LicenseInfo>)=>void,
    headingChecked:boolean}) : React.ReactElement {

	//console.log(TAG+'entering LicenseInfoRows');
	
	const indexToLicenseInfo =
	    React.useRef<Map<number,LicenseInfo>>(new Map<number,LicenseInfo>());
	
	React.useEffect(()=>{
	    indexToLicenseInfo.current  = new Map<number,LicenseInfo>();
	    props.setIndexToLicenseInfo(indexToLicenseInfo.current)
	},[])
	    
    return (<>

        { props.licensesInRange.map((entry, index) => {
		
		return (<LicenseInfoRow
			    key={'key'+index}
			    indexToLicenseInfo={indexToLicenseInfo}
			    checkBoxOnChange={props.checkBoxOnChange}
			    rowIndex={index}
			    headingChecked={props.headingChecked}
			    license_info={entry}/>
		);
          }
	)
	}
      
    </>);
}

// max_range is inclusive;
// page_num starts at 0 for first page
// key is a dummy parameter used to force re-render this component by its parent
export function MobUpgradeableLicensesTable(props :
    {   pageNum:number|null,
	setPageNum : (n:number)=>void,
	split_pages:boolean,
	numPerPage:number,
	setNumPerPage:(num:number)=>void,
	licenseSorter? : LicenseSorter,
	rowFilter : RowFilter,
	setRowFilter:(filter:RowFilter)=>void,
	onFilterChange:()=>void,
	refreshContents? : ()=>void,
	allLicenseInfos : LicenseInfo[],
	filteredLicenseInfos : LicenseInfo[]}) : React.ReactElement {
	    
	//console.log(TAG+'props.allValidUserLicenses.length='+
	//    props.upgradeable_license_infos.length);
	
	if(props.split_pages && props.pageNum == null) {
	    console.error('props.page_num == null');
	    return (<></>);
	}

        const [ isWaiting,setIsWaiting] = React.useState<boolean>(false);
	    
        const [ dialogMsg, setDialogMsg ] = React.useState<string>('');
        const [ sendInvitesDialogMsg, setSendInvitesDialogMsg ] = React.useState<string>('');	    
	    
	const [minRange, setMinRange ] = React.useState<null|number>(null)
	const [maxRange, setMaxRange ] = React.useState<null|number>(null)
	    
	React.useEffect( ()=> {
	    //console.log(TAG+'numPerPage='+props.numPerPage);
	},[props.numPerPage]);

	React.useEffect( ()=> {
	    //console.log(TAG+'rowFilter='+props.rowFilter.name);
	},[props.rowFilter]);
	
	React.useEffect( ()=> {
	    if(props.split_pages && props.pageNum!=null) {

		const min_range = Math.min(props.filteredLicenseInfos.length-1, props.pageNum * props.numPerPage);
		const max_range = Math.min( props.filteredLicenseInfos.length, min_range + props.numPerPage);

		console.log('props.filteredLicenseInfos.length='+
		    props.filteredLicenseInfos.length);
		console.log('props.page_num=' + props.pageNum);		
		console.log('min_range=' + min_range);	    
		console.log('max_range=' + max_range);		
				
		
		setMinRange(min_range);
		setMaxRange(max_range);
	    } else {
		setMinRange(0);
		setMaxRange(props.filteredLicenseInfos.length);		
	    }
	    console.log('maxRange=' + maxRange);
	}, [props.filteredLicenseInfos,props.filteredLicenseInfos.length, props.pageNum, props.numPerPage,
	    props.licenseSorter,
	    props.rowFilter]);


	const [ headingChecked, setHeadingChecked ] = React.useState<boolean>(false);

	const [ indexToLicenseInfo, setIndexToLicenseInfo ]
	    = React.useState<Map<number,LicenseInfo>>(new Map<number,LicenseInfo>());


	const checkBoxOnChange = (_checked:boolean, _index:number):void =>{
	    //console.log('indexToLicenseInfo.keys=' , Array.from(indexToLicenseInfo.keys()));
	}

	React.useEffect(()=>{
	    
	    setHeadingChecked(false);
	    
	},[ props.filteredLicenseInfos ])	


    const [oneInvitePerEmail, setOneInvitePerEmail ] = React.useState<boolean>(true);

    const send_invites = ():void=> {
	if(indexToLicenseInfo.size===0) {
	    console.log(TAG+'indexToLicenseInfo.current.size==0');
	    setDialogMsg('No licenses selected.');
	    return;
	}

	setSendInvitesDialogMsg('Will attempt to send invites for ' + indexToLicenseInfo.size + ' licenses.');
    }
	    
    const proceed_send_invites = ():void=> {
	// sort by index
	const entries = Array.from(indexToLicenseInfo.entries()).sort(
	    (x:[number,LicenseInfo], y:[number,LicenseInfo]) : number => {
		if(x[0]>y[0]) return 1;
		if(x[0]<y[0]) return -1;
		return 0
	    } );
	
	const server_license_infos : {current_email:string, desktop_license_key:string }[]  = []
	for(let entry of entries) {
	    const license_info : LicenseInfo = entry[1];
	    const emails = get_emails_of_license_info(license_info);
	    for(let email of emails) {
		server_license_infos.push({
		    current_email : email,
		    desktop_license_key: license_info.desktop_license_key
		});
	    }
	}

	const submit_to_server = ():void => {
	    setIsWaiting(true);
	    const server = getAdminServerComm();
	    const session_token = get_session_token();
	    server.handle_send_mobupgrade_invites({
		session_token,
		license_infos: server_license_infos,
		one_invite_per_email : oneInvitePerEmail,
		web_host_is_local: get_on_local_host(),
	    }).then( (result)=> {
		setIsWaiting(false);		
		if(result.status===MsgStatus.OK) {
		    let msg = `${result.num_invites_sent} invites sent for ${result.num_license_key_emails_sent} licenses`;
		    if(result.num_skipped_invite_already_sent>0) {
			msg += `\nskipped ${result.num_skipped_invite_already_sent} licenses because already sent`;
		    }
		    if(result.num_send_errors > 0) {
			msg += `\nthere were ${result.num_send_errors} send errors`;
		    }
		    setDialogMsg(msg);
		    if(props.refreshContents) props.refreshContents();
		} else {
		    
		}
	    }).catch( (error)=> {
		setIsWaiting(false);				
		console.error(TAG+'error='+error);
	    });
	}

	submit_to_server();	
	
    }

    const [licensesInRange, setLicensesInRange ] = React.useState<LicenseInfo[]|null>(null);		   
    React.useEffect( ()=>{
	if(minRange!=null) {
	    if(maxRange != null) {
		const xmaxRange = Math.min(maxRange,props.filteredLicenseInfos.length);
		console.log('minRange='+minRange);
		console.log('xmaxRange='+xmaxRange);
		const x = [...props.filteredLicenseInfos.slice(minRange,xmaxRange)];
		setLicensesInRange(x);
		if(licensesInRange) {
		  console.log(TAG+'licensesInRange.length='+licensesInRange.length);
		}
	    } else {
		const x = [...props.filteredLicenseInfos.slice(minRange,props.filteredLicenseInfos.length)];
		setLicensesInRange(x);
		if(licensesInRange) {
		  console.log(TAG+'licensesInRange.length='+licensesInRange.length);
		}		
	    }
	}
    },[minRange,maxRange, props.filteredLicenseInfos]);
		  
    const theme = createTheme();
	
    return (
	
    <Box style={{width:'100%'}}>

         <Box sx={{pl:2,pr:2}} style={{ display:'flex', flexDirection:'row',
	     alignItems:'flex-end',
	     justifyContent:'space-between'}}>
              <SelectFilterRows setFilter={props.setRowFilter}
				filter={props.rowFilter}
  	                       onChange={props.onFilterChange}/>

	 { props.refreshContents !== undefined && 
	  (<Box textAlign="center" sx={{}}>
	      <Button style={{borderRadius:'4pt',border:"1pt solid black",minWidth:'6em',
	         paddingTop:'1pt',paddingBottom:'1pt', paddingRight:'0.5em',paddingLeft:'0.5em',
	         backgroundColor:theme.palette.primary.main,
	         color:'white' }} sx={{mb:1,mr:3}}
		 size="small" onClick={()=>{ if(props.refreshContents) props.refreshContents() }}>
            REFRESH
	     </Button>	      
	  </Box>)
	 }

 	  <MaxItemsPerPage setNumPerPage={props.setNumPerPage}/>
	
         </Box>

	<Box sx={{mt:1}}>
	   <SetOneInvitePerLicense
	         oneInvitePerLicense={oneInvitePerEmail}
	         setOneInvitePerLicense={setOneInvitePerEmail}/>
	</Box>	

	 <Box textAlign="center" sx={{mt:2,mb:0}}>
	  <Button style={{borderRadius:'4pt',border:"1pt solid black",minWidth:'6em',
	      paddingTop:'1pt',paddingBottom:'1pt', paddingRight:'0.5em',paddingLeft:'0.5em',
	      backgroundColor: 'pink',
	      color:'black' }} sx={{mb:1}}
		  size="small" onClick={send_invites}>
            SEND INVITES
	  </Button>	      
	</Box>

	{
	    props.pageNum != null &&
		(<>

		  <PageSelection current_page_num={props.pageNum==null ? 0:props.pageNum}
				 setPageNum={props.setPageNum}				 
				 numPerPage={props.numPerPage}
				 rowFilter={props.rowFilter}
				 allLicenseInfos={props.allLicenseInfos}
				 filteredLicenseInfos={props.filteredLicenseInfos }
				
		 />
		    <Box sx={{mb:1.5}} ></Box>
		</>
		)
	}
	    
	  
   <TableContainer component={Paper} style={{ borderRadius:'5px'  }}
		   sx={{ boxShadow:3}} >

      <Table
	  sx={{ borderTop: '3px solid #E0E0E0',
	   borderBottom: '3px solid #E0E0E0'}} >

	<HeadingsRow checkBoxOnChange={setHeadingChecked} checkBoxChecked={headingChecked}/>
	
        <TableBody>

	  <>
        { licensesInRange != null &&
		
            (<LicenseInfoRows
			    checkBoxOnChange={checkBoxOnChange}
			    headingChecked={headingChecked}
		            setIndexToLicenseInfo={setIndexToLicenseInfo}		 
			    licensesInRange={licensesInRange}/>)
	}</>
	  
        </TableBody>
	
        </Table>
	
    </TableContainer>
	
	{ props.pageNum != null &&
	<PageSelection current_page_num={props.pageNum==null ? 0:props.pageNum}
		       setPageNum={props.setPageNum}
		       numPerPage={props.numPerPage}
		       rowFilter={props.rowFilter}
		       allLicenseInfos={props.allLicenseInfos}		       
    		       filteredLicenseInfos={props.filteredLicenseInfos } 
	/>}

    <MsgDialogSimple service_close={()=>{setSendInvitesDialogMsg('')}}
		     msg={sendInvitesDialogMsg}
		     button_label1={'Cancel'}
		     button_label2={'Proceed'}		     
		     service_button2={proceed_send_invites}		     
    />

    <MsgDialogSimple service_close={()=>{setDialogMsg('')}}
		     msg={dialogMsg}
		     button_label1={'OK'}
		     button_label2={''}		     
		     
    />

	      
	<Backdrop invisible={true} open={isWaiting}
		  sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	  <CircularProgress/>
	</Backdrop>
    
	</Box>	
    );

}
