// File: mobile_install.tsx
//

import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { getECommServerComm } from '../utils/ecomm_server_comm';
import { useLocation } from 'react-router-dom';
import { APPLE_CONNECT_STORE_MOBILE_APP, GOOGLE_PLAY_STORE_MOBILE_APP
       } from '../../config/urls';
import { AndroidLogo, AppleIOSLogo } from '../utils/store_icons';
import { MsgStatus, EComm, ECOMM_NOT_FOUND_URL } from '../../extlinks/portal_comm_types';
import { check_url_params } from '../utils/check_url_params';
import { Copyright } from '../../utils_common/copyright';
import { MsgAlertDialog } from '../../utils_common/alert_dialog';
import { Progress } from '../../utils_common/progress';
import { useECommAuthStatus } from '../../auth_provider/ecomm_auth_provider';

const TAG='mobile_install.tsx: ';
const DEBUG=true;

type Licenses = EComm.ECommReqLicenseKeys.Licenses;
type MobileLicense = EComm.ECommReqLicenseKeys.MobileLicense;

export const ECOMM_MOBILE_INSTALL_URL='/ecomm/mobile-install';

const FONT='body1';

const FONT_COLOR='#008B8B';

function ContentCard(props : {title:string, children : string|React.ReactElement|
                           React.ReactElement[]} ) :  React.ReactElement {
      //const { url_params } = useParams();    
    //const navigate = useNavigate();
    			       
			       
    return (
	<Box style={{height:'100%', width:'80%'}}
	     sx={{mt:0,mb:5}}
	     >
	  
	  <Card elevation={4} sx={{ml:1, mr:1}}
		style={{ height: '100%'}}>
	    
	    <CardActionArea component='div'
			    style={{textDecoration:'none'}}	      
	    >
	      <CardHeader title={props.title}
			  style={{color:FONT_COLOR,
			      display:'flex', flexDirection:'column',
			      alignItems : 'center', justifyContent:'center'}}
			  component='div'/>
		
	      <CardContent sx={{  paddingTop : "0" }}>

		{props.children}
	      </CardContent>
	      
	    </CardActionArea>
	    
	  </Card>
	</Box>
	);
}

function ScrollToTop() :  React.ReactElement {

    const { pathname } = useLocation();

    React.useEffect( ()=>{
	window.scrollTo(0, 0);
    },[pathname]);

    return (<></>);
}

function  ECommMobileInstallInstructionsImpl(props:{mobile_license: MobileLicense}) : React.ReactElement {

    const ios_url = APPLE_CONNECT_STORE_MOBILE_APP;
    const android_url = GOOGLE_PLAY_STORE_MOBILE_APP;

    return (
      <Box>
    <ScrollToTop/>
    
      <Box style={{display:'flex', flexDirection:'column',
	  alignItems : 'center', justifyContent:'center'}}>

	<Box sx={{mb:4}}>
	  <Typography textAlign='center' style={{fontWeight:'bold', fontSize:"1.8em"}}>
	    Mobile App Installation Instructions
	  </Typography>	    
	</Box>	  
	  
          <ContentCard title="First install the Lite Version of the mobile app from the app store, if you haven't done so already.">
	    <Box style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>	    
	      
	      <Box style={{display:'flex', flexDirection:'column',
		  marginTop:'0.0em',
		  alignItems : 'center'}}>

		<Box style={{display:'flex', flexDirection:'row'}}>
		  <AppleIOSLogo/>
		<Link  component='button'
		       variant={FONT}
		       style={{ textDecoration:'none'}}
		       onClick={()=> {
			   window.open(ios_url);
		       }}>
       IOS  Apple Store 
		</Link>
		</Box>
		
		<Box style={{marginTop:'0.5em', display:'flex', flexDirection:'row'}}>
		  <AndroidLogo/>
		<Link  component='button'
		       variant={FONT}	  
		       style={{ textDecoration:'none'}}	  
		       onClick={()=> {
			   window.open(android_url);
		       }}>
         Android Google Play Store 
		</Link>
		</Box>
		
	      </Box>
	      
	    </Box>
	  </ContentCard>
	  
          <ContentCard title="Press the upgrade page icon on the app">
	    <Box style={{marginBottom:'1em',display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>	    
              <img src={require('../utils/app-snapshot.png')} alt=""/>

	    </Box>

	  </ContentCard>
 	  
          <ContentCard title="Press the activate button on the upgrade page.">
	    <Box 	
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
              <img   src={require('../utils/app-upgrade-blurred.png')} alt=""
	        style={{margin:'-3em',
		    backgroundSize:'contain', transform:'scale(0.64)'}}/>	      

	    </Box>

	  </ContentCard>

          <ContentCard title="Input email and activation code">

	    <Box
		sx={{mb:2,mt:2}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
	      
	    <Box>
	      <Typography>
	      email : {props.mobile_license.license_email}
	      </Typography>		
	    </Box>
	    
	    <Box sx={{mt:1}}>
	      <Typography>	      
	      activation code : {props.mobile_license.mobile_activation_code}
	      </Typography>		
	    </Box>

	    <Box
		sx={{mt:1}}
		 style={{display:'flex', flexDirection:'column',
		alignItems : 'center', justifyContent:'center'}}>
              <img   src={require('../utils/app-activation-page.png')} alt=""
	        style={{margin:'0em', 
		    backgroundSize:'contain', transform:'scale(0.9)'}}/>
	      
	    </Box>

	    
	    <Box sx={{mt:1}}>
	      <Typography>	      
	      Next, press the Submit button.
	      </Typography>
           </Box>
	    <Box sx={{mt:1}}>	      
	      <Typography>	      
	      You are now set.
	      </Typography>		      
	    </Box>
	    
	    </Box>
	  </ContentCard>	  	  	  
	
      </Box>

  	   </Box>

    )
}

export function  ECommMobileInstallInstructions() : React.ReactElement {

    const count = React.useRef<number>(0);
    
    const navigate = useNavigate();

    const server = getECommServerComm();
    
    React.useEffect( ()=> {
	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate('/home');	
    },[])

    const { url_params } = useParams();
			       
    const [ msg, setMsg ] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const licenses = React.useRef<Licenses|null>(null);
    const [ noLicenses, setNoLicenses ] = React.useState<boolean>(false);

    const auth_status = useECommAuthStatus();
    
    React.useEffect( ()=> {
	check_url_params({parent_tag:TAG, url_params,
	    session_token : auth_status.session_token,
	    navigate});
    },[url_params]);

    React.useEffect( ()=> {

	const session_token = auth_status.session_token;

	if(session_token==null) {
	    console.error(TAG+'session_token==null');
	    navigate(ECOMM_NOT_FOUND_URL);
	    return;
	}
	
	if(url_params && licenses.current == null) {
	    setIsLoading(true);
	    setNoLicenses(false);
	    server.handle_req_license_keys({url_params, session_token}).then((result)=>{
		setIsLoading(false);
		if(result.status === MsgStatus.ERROR) {
		    setNoLicenses(true);		    
		    setMsg('Server error. Try again later.');
		} else {
		    licenses.current = result.licenses;
		    if(result.licenses == null) {
			setNoLicenses(true);
		    }
		}
	    }).catch((error)=>{
		setIsLoading(false);	    
	    });
	}
    },[]);
    
    return(
       <Box sx={{mt:0,pt:5, pb:2}} style={{backgroundColor : 'rgb(240,240,240)'}}>

	{ licenses.current != null && licenses.current.mobile_license != null &&
	      <ECommMobileInstallInstructionsImpl mobile_license={licenses.current.mobile_license}/>
	}

	  <Copyright sx={{ mt: 5, mb: 4 }} />
	  
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />
	  
	{ <Progress isLoading={isLoading}/> }

      </Box>

    )
}

