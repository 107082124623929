// File: sidebar.tsx
//


import * as React from 'react';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import { AdminSideBarMainItems } from './sidebar_items';
import { AdminSideBarProps } from './side_bar_props';

export function AdminSideBar(props : AdminSideBarProps) :React.ReactElement {
    return (
	<Box>

	  <List>
	    <AdminSideBarMainItems {... props}/> 
{/*	    
            <Divider sx={{ my: 1 }} />
	    <SideBarSecondaryItems/>
*/}	    
	  </List>
	</Box>    )
}
