// File: display_license_keys_landing.tsx
//

import * as React from 'react';
import { useParams, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Copyright } from '../utils_common/copyright';
import { TDBAppLogoLarge } from '../utils_common/tdb_large_logo';
import { getECommServerComm } from './utils/ecomm_server_comm';
import { MsgAlertDialog } from '../utils_common/alert_dialog';
import { EmailCommLandingPage } from './components/email_comm_landing_page';
import { Progress } from '../utils_common/progress';
import { MsgStatus, EComm,
    ECommNonMobupgradeInviteType,
    ECOMM_LICENSE_INFO_URL,
    ECOMM_NOT_FOUND_URL
} from '../extlinks/portal_comm_types';
import { ECOMM_DESKTOP_INSTALL_URL } from './display_license_keys/desktop_install';
import { ECOMM_MOBILE_INSTALL_URL } from './display_license_keys/mobile_install';
import Link from '@mui/material/Link';
import { check_url_params } from './utils/check_url_params';
import { useECommAuthStatus } from '../auth_provider/ecomm_auth_provider';
import { ECOMM_TESTING } from '../config/config';

const TAG='display_license_keys_landing.tsx: ';
const DEBUG=true;

//const TESTING=true;

//type MobileLicense = EComm.ECommReqLicenseKeys.MobileLicense;
//type DesktopLicense = EComm.ECommReqLicenseKeys.DesktopLicense;
type Licenses = EComm.ECommReqLicenseKeys.Licenses;

function get_information_line_ending(landing_page_type : ECommNonMobupgradeInviteType) : string {
    switch(landing_page_type) {
	case 'NONE':
	    console.error('landing_page_type==NONE');
	    return '';
	case 'DESKTOP_FULL_PURCHASE':
	    return '';
	case 'DESKTOP_PLUS_MOBILE_FULL_PURCHASE':
	    return 's';
	case 'DESKTOP_SUBSCRIPTION_PURCHASE':
	    return '';
	case 'DESKTOP_PLUS_MOBILE_SUBSCRIPTION_PURCHASE':
	    return 's';
	case 'FREE_DESKTOP':
	    return '';
	case 'FREE_DESKTOP_PLUS_MOBILE':
	    return 's';
	case 'FREE_MOBILE':
	    return '';
    }    
}

function get_intro_title(landing_page_type : ECommNonMobupgradeInviteType) : string {
    switch(landing_page_type) {
	case 'NONE':
	    console.error('landing_page_type==NONE');
	    return '';
	case 'DESKTOP_FULL_PURCHASE':
	    return 'Thank-you for purchasing the Discovery Bible desktop version!'	    
	case 'DESKTOP_PLUS_MOBILE_FULL_PURCHASE':
	    return 'Thank-you for purchasing the Discovery Bible desktop and mobile versions!'	    	    
	case 'DESKTOP_SUBSCRIPTION_PURCHASE':
	    return 'Thank-you for the first installment of the Discovery Bible desktop version!'
	case 'DESKTOP_PLUS_MOBILE_SUBSCRIPTION_PURCHASE':
	    return 'Thank-you for the first installment of the Discovery Bible desktop and mobile versions!'
	case 'FREE_DESKTOP':
	    return 'You have been granted a free license for the Discovery Bible desktop version!'
	case 'FREE_DESKTOP_PLUS_MOBILE':
	    return 'You have been granted a free license for the Discovery Bible desktop and mobile versions!'	    
	case 'FREE_MOBILE':
	    return 'You have been granted a free license for the Discovery Bible mobile version!'
    }
}

function Contents(props: {licenses: Licenses, url_params:string,
                 landing_page_type :  ECommNonMobupgradeInviteType }) : React.ReactElement {

    return (<>

      <Box sx={{mt:6,mb:1}} style={{display:'flex', flexDirection:'column',alignItems:'center'}}>
	      <Typography  align='center' style={{fontWeight:'bold',fontSize:'1.5em'}}>
		{get_intro_title(props.landing_page_type)}
	      </Typography>

      </Box>

      { props.licenses.desktop_license != null &&
	  <Box sx={{mt:3}} style={{ fontWeight: 'bold',
	      display:'flex', flexDirection:'column',alignItems:'center'}}>
              <Link href={ ECOMM_DESKTOP_INSTALL_URL+'/'+props.url_params}>
	      Instructions on installation of the desktop version.
              </Link>
	  </Box>
      }

      { props.licenses.mobile_license != null &&
	  <Box sx={{mt:3}} style={{fontWeight: 'bold',
	      display:'flex', flexDirection:'column',alignItems:'center'}}>
            <Link  href={  ECOMM_MOBILE_INSTALL_URL+'/'+props.url_params}>
	      Instructions on installation of the mobile version.	      
            </Link>
	  </Box>
      }      

      { (props.licenses.desktop_license != null || props.licenses.mobile_license != null) &&	
	  <Box sx={{mt:3}} style={{fontWeight: 'bold',
	      display:'flex', flexDirection:'column',alignItems:'center'}}>
            <Link  href={'/' + ECOMM_LICENSE_INFO_URL +'/'+props.url_params}>
	      Information about your license{get_information_line_ending(props.landing_page_type)}.
            </Link>
	  </Box>
      }
	  
    </>)
}

export function ECommDisplayLicenseKeysLanding(props:{}) : React.ReactElement {

    const { url_params } = useParams();
    
    const navigate = useNavigate();

    const server = getECommServerComm();
    
    const [ msg, setMsg ] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [ licenses, setLicenses ] = React.useState<Licenses|null>(null);
    const [ noLicenses, setNoLicenses ] = React.useState<boolean>(false);
    const [ landingPageType, setLandingPageType ] = React.useState<ECommNonMobupgradeInviteType>('NONE')

    const auth_status = useECommAuthStatus();
    
    React.useEffect( ()=> {
	if(auth_status.session_token != null) {
	    setIsLoading(true);
	    check_url_params({parent_tag:TAG, url_params,
		session_token : auth_status.session_token,
		navigate,
		finished:()=>{ setIsLoading(false) } });
	}
    },[url_params])

    React.useEffect( ()=> {

	const session_token = auth_status.session_token;

	if(session_token==null) {
	    console.error(TAG+'session_token==null');
	    if(ECOMM_TESTING) {
		setMsg('PT1: session_token==null');
	    } else {
		navigate(ECOMM_NOT_FOUND_URL);
	    }
	    return;
	}	
	
	if(url_params) {
	    setIsLoading(true);
	    setNoLicenses(false);
	    server.handle_req_license_keys({url_params, session_token}).then((result)=>{
		setIsLoading(false);
		if(result.status === MsgStatus.ERROR) {
		    setNoLicenses(true);		    
		    setMsg('Server error. Try again later.');
		} else {
		    setLicenses(result.licenses);		    
		    if(result.licenses == null) {
			setNoLicenses(true);
		    }
		    setLandingPageType(result.landing_page_type);
		}
	    }).catch((error)=>{
		setIsLoading(false);	    
	    });
	}
    },[url_params]);
    
    return (<>
      
<EmailCommLandingPage challengeOff={true}>

  <Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>	  
	    
  	    <Typography align='center' sx={{mt:4}}>
              <TDBAppLogoLarge/>
 	    </Typography>

	  <>
           { licenses != null && url_params !== undefined &&
	      <Contents licenses={licenses}
			url_params={url_params}
			landing_page_type={landingPageType}
	      />
           }
	  </>
	  
	  <>
          { noLicenses &&
	      <Box>
		<Typography  align='center'>
		  You have no licenses.
		</Typography>		  
	      </Box>
	  }
          </>
	    
	  <Copyright sx={{ mt: 5, mb: 4 }} />
	  
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />
	  
	{ <Progress isLoading={isLoading}/> }


 </Box>
	  
</EmailCommLandingPage>
	
    );
      

    </>);
}
