// File: select_filter_rows.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { DropDownListMenu } from '../drop_down_list_menu';
import { ListAllLicensesMsg } from '../../../extlinks/portal_comm_types';

const TAG='select_filter_rows.tsx: '

type LicenseInfo = ListAllLicensesMsg.UserLicense;

export interface RowFilter {
    filter(row:LicenseInfo):boolean;
    name:string;
}

class IsSubscription  implements RowFilter {
    readonly name='is-subscription'    
    filter(x : LicenseInfo) : boolean {
	return x.subscription_status === 'IS_SUBSCRIPTION'
    }
}

class IsNotSubscription   implements RowFilter {
    readonly name='is-not-subscription'        
    filter(x : LicenseInfo) : boolean {
	return x.subscription_status !== 'IS_SUBSCRIPTION'
    }
}

class NoneFilter implements RowFilter  {
    readonly name='none'                
    filter(_x : LicenseInfo) : boolean {
	return true;
    }
}

const FILTERS= [ new NoneFilter(), new IsNotSubscription(), new IsSubscription() ];

export function get_row_filter(name:string) : RowFilter|null {
    const x = FILTERS.filter(f=>f.name===name);
    if(x.length===0) return null;
    return x[0];
}

export const NONE_ROW_FILTER = new NoneFilter();

export function SelectFilterRows(props:{setFilter:(filter:RowFilter)=>void,
    filter:RowFilter,
    onChange?:()=>void}) :  React.ReactElement {

    const filters : [string, RowFilter][] = [
	[ 'none',  new NoneFilter() ],
	[ 'is subscription' , new IsSubscription() ],
	[ 'not subscription' , new IsNotSubscription() ],	
    ]
    
    const items :string[] = filters.map( (x)=>x[0] );

    const [selectedIndex, setSelectedIndex]  = React.useState<number>(0);
    
    const setItemIndex = (index:number):void=> {
	console.log(TAG+'setItemIndex: index=' + index);
	if(index >= items.length || index < 0) return;
	console.log(TAG+'filters[index][1]'+filters[index][1].name);
	props.setFilter(filters[index][1]);
	setSelectedIndex(index);
    }

	React.useEffect(()=> {
	    for(let i=0;i<filters.length;i++) {
		if(filters[i][1].name === props.filter.name) {
		    setSelectedIndex(i);
		}
	    }
	},[props.filter.name]);

    
  return (
	<Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
	  select filter 
          <DropDownListMenu setSelectedIndex={setItemIndex}
			    selectedIndex={selectedIndex}
			    minWidth={"12em"}
			    onChange={props.onChange}
			    items={items}
	  />
	</Box>);
}


