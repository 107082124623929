// File: email_not_registered.tsx
//


import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import {IPageProps} from '../interfaces/page';
//import { getAuth, onAuthStateChanged} from 'firebase/auth';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { useAuthStatus} from '../../auth_provider/use_auth';

//import { AlertDialog } from '../utils/alert_dialog';

const TAG='email_not_verified.ts: ';
//const DEBUG=true;

const theme = createTheme();

// goes to this page if email already signed but email not verified
export function EmailNotVerified(_props : IPageProps) : React.ReactElement {
    
    const navigate = useNavigate();

    const {user} = useAuthStatus();

    React.useEffect( ()=> {
	if(user!=null && user.emailVerified) {
	    console.log(TAG+'useEffect:user!=null && user.emailVerified');
	    //setVerified(true);
	    navigate('/home');
	} else {
	    console.log(TAG+'useEffect:user==null');
	}
    },[]);
    
    
    return (
  <ThemeProvider theme={theme}>
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box  sx={{
          marginTop: 8,
          ndisplay: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
      }}>
	
	<Typography align='center'>
          <TDBAppLogoLarge/>
	</Typography>

        <Box sx={{mt:5}}>

	 {user == null || ! user.emailVerified
	     ? (<>
	       <Typography component="h1" variant="h5"  align='center'>
            Email  <b>{user && user.email}</b> has not been verified.
             Click on the verification link that was
            sent to your email.
               </Typography>
	       <div style={{height:'1em'}}/>
	       <Typography component="h1" variant="h5"  align='center'>
               If verification link has expired or you wish to try again,
		 click on the following link.
               </Typography>	       
	       <Link to='/select-email'>
		 <Typography sx={{mt:2}}
			     component="h1" variant="h5"  align='center'>
               Try Again
		 </Typography>
	       </Link>	   	     
	     </>
             )
	 
	     : (<></>)

	 }

         </Box>
	 
        </Box>	

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );	

}

