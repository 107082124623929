// File: mobile_upgrade_direct_to_link.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useMainPagesContext } from '../../utils/pub_main_pages_provider';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { MainLayout} from '../../utils/main_layout';
import { SideBarType } from '../../utils/side_bar';
import { useLocationState } from '../../../utils_common/use_location_state';
import { MobileUpgradeState } from './mobile_upgrade';
import { getServerComm } from '../../utils/server_comm';
import { AlertDialogProps, AlertDialog } from '../../../utils_common/alert_dialog';
import { useNavigate } from 'react-router-dom';
import { get_session_token, LOGGER } from '../../../utils_common/logger';
import { MOBILE_UPGRADE_PRICE_USD, MsgStatus } from '../../../extlinks/portal_comm_types'; 

const TAG='mobile_upgrade_direct_to_link.tsx: ';
const DEBUG=false;

const CLOSED_DIALOG_PROPS : AlertDialogProps = {
    open : ()=>false,
    handle_close : ()=>{},
    handle_choice1 :()=>{},
    handle_choice2 : ()=>{},
    title : '',
    text : '',
    choice1 : '',
    choice2 : ''
}

export function MobileUpgradeDirectToLink() : React.ReactElement {
    
    const navigate = useNavigate();

//    const [ dialogOpen, setDialogOpen ] = React.useState<boolean>(false);

//    const [ purchaseUrl, setPurchaseUrl ] = React.useState<string>('');
    
    const count = React.useRef<number>(0);
    {

	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate('/home');
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_MOBILE_UPGRADE_LAUNCH_POPUP_PAGE','mobile_upgrade_launch_popup.tsx');
    }
    
    const [ spin, setSpin ] = React.useState<boolean>(false);
    
    const main_pages_state = useMainPagesContext();
    const mainPagesIsLoading = main_pages_state.is_loading;

    const [ dialogProps, setDialogProps ] =
	React.useState<AlertDialogProps>(CLOSED_DIALOG_PROPS);

    const close_dialog = () : void => {
	setDialogProps(CLOSED_DIALOG_PROPS);	
    }
    
    // note: have to return after call
    const open_dialog = (title:string,	txt : string,
	ok_close_hook : (()=>void)) : void => {
	    LOGGER.log_event('MOBILE_UPGRADE_POPUP_PAGE_OPEN_DIALOG',
		'title='+title);
	let open = true;
	setDialogProps({
	    open: ()=>{ return open; },
	    handle_close : close_dialog,
	    handle_choice1 : ()=>{ok_close_hook(); close_dialog() },
	    handle_choice2 : close_dialog,
	    title : title,
	    text : txt,
	    choice1 : 'OK',
	    choice2 : ''
	});
    }

    // useRef avoids infinite rerenders
    //const loaded = React.useRef(false);

    const server = getServerComm();
    
    let state : MobileUpgradeState|null = useLocationState<MobileUpgradeState>();

    const parent_desktop_license_key = state?.parent_desktop_license_key;    

    React.useEffect( ()=> {

	console.log('useEffect PT1: state=',state);
	
	if( !state || ! state.is_mobile_upgrade_state  ||
	    parent_desktop_license_key==null || parent_desktop_license_key==='') {
	    LOGGER.error(TAG+'PT1 parent_desktop_license_key=' +
		parent_desktop_license_key);
	    navigate('/home');
	    return;
	}
    },[]);

    const checkout_impl = async ():Promise<void>=>{

	LOGGER.info(DEBUG, 'entering checkout');

	const session_token = get_session_token();

	const license_status =
	    await server.handle_desktop_license_eligible_for_mobile_upgrade({
		session_token,
		desktop_license_key:  parent_desktop_license_key });

	if(license_status.status === MsgStatus.ERROR ||
	    ! license_status.is_valid) {
	    LOGGER.error(TAG+'license_status not valid: error: ' +
		license_status.error_message);
	    if(license_status.error_message) {
		open_dialog(license_status.error_message,'',		    
		    ()=>{ navigate('/home') });
	    } else {
		open_dialog('license not valid or not eligible','', ()=>{ navigate('/home') });
	    }
	    return;
	}

	console.info(TAG+'service_purchase: PT1');

	if(!parent_desktop_license_key) {
	    open_dialog('internal error occurred','', ()=>{ });
	    return;
	}

	const result = await server.handle_get_mob_upgrade_prod_url({session_token,
                                              	                    parent_license_key:parent_desktop_license_key});

	if(result.status===MsgStatus.ERROR) {
	    open_dialog('internal error occurred','', ()=>{ });
	    return;
	}

	//setPurchaseUrl(result.fs_prod_url);
	console.info(TAG+'fs_prod_url='+result.fs_prod_url);

	//navigate(result.fs_prod_url);
	window.location.replace(result.fs_prod_url);

	//navigate('/activate-mobile');
	
	return;
    }

    const checkout = ():void=> {
	setSpin(true);	
	checkout_impl().then( ()=>{ setSpin(false) }).catch((error:string)=>{
	    setSpin(false);		    
	    console.error('checkout_impl returned error='+error);
	})
	setSpin(false);		    	
    }

    return (
	<MainLayout title='Mobile Upgrade Purchase'
		    dont_refresh_user_info={true}
		    side_bar_type={SideBarType.MOBUPGRADE_CHILD_PAGE}>
	  
	  <Box style={{paddingLeft: "1em",paddingRight: "2em", display:"flex",flexDirection:"column",
	      alignItems:"center"}}>

	  <Box component="div" alignItems="center">
	    The purchase price of the mobile upgrade is {MOBILE_UPGRADE_PRICE_USD} USD.
         </Box>
	 
	  <Box component="div" alignItems="center">	    
	      <ol>
	 <li>    Press the GO TO PURCHASE SITE button below to make the purchase.</li>
         <li style={{marginTop:"0.5em"}}>This will exit this site and redirect you to the external webpage of our e-commerce vendor <b><i>Fastspring</i></b>.</li>
         <li style={{marginTop:"0.5em"}}>After you make the purchase, come back to this web portal to activate the app on your devices.</li>	 
	 </ol>
	 
	  </Box>
	  
	  </Box>
	  
	  <Box 	      sx={{mt:2}}
	      style={{width:'100%',
	      display:'flex', flexDirection:'column',
	      justifyContent:'center', alignItems:'center'}}>

	    <Button  type="submit" variant="contained" size="small" onClick={()=>checkout()} >
	              GO TO PURCHASE SITE
	    </Button>

          </Box>

	   <AlertDialog {...dialogProps} />

	  <Backdrop invisible={true} open={mainPagesIsLoading || spin}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>


	</MainLayout>
    )
	
}
