// File: licenses_status_for_mobupgrade.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MainLayout } from '../../utils/main_layout';
//import {  SideBarType } from '../../utils/side_bar';
//import { getServerComm } from '../utils/server_comm';
//import { AlertDialog } from '../../../utils_common/alert_dialog';
import { useMainPagesContext } from '../../utils/pub_main_pages_provider';
import { GetUserLicensesInfo } from '../../../extlinks/portal_comm_types';
import { MobileUpgradeableLicensesTable } from '../../utils/mobile_upgradeable_licenses_table';

//const TAG='licenses_status_for_mobupgrade.tsx: ';
//const DEBUG=false;

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;

function
get_initially_upgradeable_license_infos(license_infos:LicenseInfo[]) : LicenseInfo[] {
    return license_infos.filter( (x)=> {
	if(x.expiry_date != null && 
	    (new Date(x.expiry_date)).getTime() < Date.now()) return false;
	return x.license_type.includes_upgrade_to_mobile;
    });
}


export function LicensesStatusForMobUpgrade() :React.ReactElement {

    const [start, setStart] = React.useState<boolean>(false);
    React.useEffect( ()=>{ setStart(true); });

    const main_pages_state = useMainPagesContext();
    const isLoading = main_pages_state.is_loading;
    
    const user_licenses_info =  main_pages_state.user_licenses_info;    
    const licenseInfos = user_licenses_info ?
	user_licenses_info.license_infos : [];
    
    const initially_upgradeable_licenses =
	get_initially_upgradeable_license_infos(licenseInfos);
    
    return (<>
      
    <MainLayout title='Desktop Licenses Status for Mobile Upgrade'
		dont_refresh_user_info={true}
    >
    
	<>
       {   (start && ! isLoading && initially_upgradeable_licenses.length > 0) &&
	  (<>
	    <div style={{height:"2em"}}/>	  
	    <Box textAlign='center'>	    	
	      <MobileUpgradeableLicensesTable license_infos={licenseInfos}/>
	    </Box>
	    <Typography sx={{mt:2}}>
	      * email that claimed license first
	    </Typography>	      
	  </>)
       }
       {   (start && ! isLoading && initially_upgradeable_licenses.length === 0) &&
	   (
	       <Typography textAlign="center">
		 You have no upgradeable desktop licenses.
	       </Typography>	       
	   )
       }	  
	</>

  </MainLayout> 
      </>	
    );		  	        
}
