// File: select_license_expiration.tsx
//

import * as React from 'react';
import Radio from '@mui/material/Radio';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { AddFreeMobileLicenses } from '../../../extlinks/portal_comm_types';

const TAG='select_license_expiration.tsx: ';
//const DEBUG=false;

export const INDEFINITE_LICENSE_DURATION=AddFreeMobileLicenses.INDEFINITE_LICENSE_DURATION;

export const DEFAULT_EXPIRY_DURATION=INDEFINITE_LICENSE_DURATION;

export function
SelectLicenseExpiration(props : {setExpiryDuration:(expiry_duration:number)=>void }) :React.ReactElement {
    
    const default_value = String(DEFAULT_EXPIRY_DURATION);

    const onChange = (ev:React.ChangeEvent<HTMLInputElement>):void => {
	console.log('ev.target.value='+ev.target.value);
	const n = Number(ev.target.value);
	if(Number.isInteger(n)) {
	    props.setExpiryDuration(n);
	} else {
	    console.error(TAG+'invalid expiration='+ev.target.value);
	}
    }
    
    return (
<FormControl >
  <Typography variant='h6'>
Expiration
  </Typography>    
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue={default_value}
    name="radio-buttons-group"
  >
    <FormControlLabel value="14"
		      control={<Radio onChange={onChange}/>}
		      label="14 days" />
    <FormControlLabel value="30"
		      control={<Radio  onChange={onChange}/>}
		      label="30 days" />
    <FormControlLabel value="60"
		      control={<Radio  onChange={onChange}/>}
		      label="60 days" />    
    <FormControlLabel value="90"
		      control={<Radio  onChange={onChange}/>}
		      label="90 days" />
    <FormControlLabel value="120"
		      control={<Radio  onChange={onChange}/>}
		      label="120 days" />    
    <FormControlLabel style={{alignItems:'flex-start'}}
		      sx={{mt:1}}
		      value={default_value}
		      control={<Radio sx={{mt:0.5,p:0,mr:1,ml:1}} onChange={onChange} />}
		      label="No Expiration" />
  </RadioGroup>
</FormControl>
    )
}

