import React, { useEffect, useState } from 'react';
import { Spinner } from 'reactstrap';
import { AUTH } from './config/firebase_config';
import { AppRoutes} from './routes/routes';
import {onAuthStateChanged} from 'firebase/auth';
import { QueryClient, QueryClientProvider } from 'react-query';

const DEBUG=false;

export interface IApplicationProps { }

const queryClient = new QueryClient();

export const Application: React.FunctionComponent<IApplicationProps> = (_props) => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        onAuthStateChanged(AUTH, user => {
            if (user) {
                if(DEBUG) console.info('User detected.');
            } else {
                if(DEBUG) console.info('No user detected');
            }

            setLoading(false);
        })
    }, []);

    if(loading) {
        return <Spinner color="info" />
    }

    return (
	<React.StrictMode>
	<QueryClientProvider client={queryClient}>
	  {
	      <AppRoutes/>
	  }
	</QueryClientProvider>
	</React.StrictMode>	

    );

}

 
