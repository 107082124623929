// File: mobile_activated_devices.tsx
//

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetUserLicensesInfo } from '../../extlinks/portal_comm_types';
import { TABLE_HEADER_FONT_COLOR,
    TABLE_HEADER_BG_COLOR } from './utils_config';

interface DeviceInfo extends GetUserLicensesInfo.MobileDeviceInfo {
}

const Para = (props:{text:string}) : React.ReactElement => {
    return(<>
	<p style={{lineHeight:'100%', marginTop:'0.5em', marginBottom: '0.5em'}} >{props.text}</p>
	</>
    )
}

export const MobileDeviceList =
    ( props : {devices :  DeviceInfo[]}): React.ReactElement  => {

return (
    <TableContainer component={Paper} 
		    sx={{boxShadow:3, maxWidth:'97vw', borderRadius:'10px', pb: 2}}>
    
    <Table style={{maxWidth:'97vw'}}>
	
      <TableHead style={{borderRadius:'10px', backgroundColor:TABLE_HEADER_BG_COLOR}}>

	<TableRow>	  	
	  <TableCell
	      style={{color:TABLE_HEADER_FONT_COLOR}} colSpan={6}
	      align="center">Your Activated Mobile Devices</TableCell>
	</TableRow>	  
	
	<TableRow>
	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR, lineHeight:'100%'}}
	      align="left"><Para text="Device"/><Para text="Model"/></TableCell>
	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">Platform</TableCell>	  
	  <TableCell  size="small" 
	      style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left">Activations</TableCell>	  
	  <TableCell  size="small" 
 		style={{color:TABLE_HEADER_FONT_COLOR}}
	      align="left"><Para text="License"/><Para text="Key"/></TableCell>
	  <TableCell  size="small" 
 		style={{color:TABLE_HEADER_FONT_COLOR, lineHeight:'100%'}}
	      align="left"><Para text="License"/><Para text="Expiration"/></TableCell>
	  <TableCell  size="small" 
 		style={{color:TABLE_HEADER_FONT_COLOR, lineHeight:'100%'}}
		      align="left"><Para text="License"/><Para text="Activation"/></TableCell>	    	  	  
	</TableRow>

      </TableHead>
      <TableBody>{props.devices.map( (device) =>  {
	      return (
		  <TableRow
		      key={device.device_id_hash}
		      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		  >
		    <TableCell size="small" align="left">{device.model.trim()}</TableCell>
		    <TableCell size="small" align="left">{device.platform}</TableCell>
		    <TableCell size="small" align="left">{device.num_times_device_activated}</TableCell>
		    <TableCell size="small" align="left">{device.license_key.replace(/HEL/,'MOB')}</TableCell>
		    <TableCell size="small" align="left">{device.expiry_date}</TableCell>
		    <TableCell size="small" align="left">{device.activation_date}</TableCell>
		  </TableRow>
	      );
	  })}</TableBody>	      
    </Table>
    </TableContainer>    
);
	
}

export function
MobileActivatedDevicesTable(  props : {mobile_device_infos : DeviceInfo[] }): React.ReactElement  {
  return  (
     <>
	  <MobileDeviceList devices={props.mobile_device_infos}/>
      </>
     );
    
    /*
  return  (
     <>
     { props.mobile_device_infos.length === 0
       ? (<div style={{ height:"3em"}}></div>)
       : (<MobileDeviceList devices={props.mobile_device_infos}/>)
      }
      </>
     );
    */
}
