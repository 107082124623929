// File: send_mobupgrade_invites_settings.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

function
SetOneInvitePerLicenseImpl(props: { oneInvitePerLicense : boolean,
                               setInvitePerLicense : (value:boolean)=>void }) : React.ReactElement {
					   
    const onChange = (evt:React.ChangeEvent<HTMLInputElement>):void=>{
         props.setInvitePerLicense(evt.target.checked);	    
    }	
	
    return (
	
     <Box sx={{ml:0,pl:0,pr:1}}>

       <FormControlLabel sx={{ml:0,mr:1,pr:0}}
			 control={<Checkbox style={{margin:"0", paddingLeft:'0'}}
					    checked={props.oneInvitePerLicense}
					    onChange={onChange}/>}
			 labelPlacement='start'
   			       label={<Typography variant='h6' style={{margin:'0', paddingLeft:'0',marginRight:'1em'}}>
			       If an invite has already been sent for a license, don&apos;t send another one.</Typography>}
       />
	
   </Box>)
	
}

export function SetOneInvitePerLicense(props : {
    oneInvitePerLicense:boolean,
    setOneInvitePerLicense:(value:boolean)=>void}) :React.ReactElement {
	
    return (
	
    <Box>
	
	<Box sx={{ml:2,mt:0,mb:0}} style={{ display:'flex', flexDirection:'row',justifyContent:'center' }}>
	
	  <Box sx={{p:0,mr:1,ml:0}}
	      style={{width:'50%', borderRadius:'10px'}}>

             <Box style={{height:'1em', width:'100%'}} />	     

             <SetOneInvitePerLicenseImpl oneInvitePerLicense={props.oneInvitePerLicense}
					 setInvitePerLicense={props.setOneInvitePerLicense} />	
  	  </Box>

  	</Box>
	  
	<Box style={{width:'100%', height:'1em'}}/>

        <Box>

	</Box>	   
	  
   </Box>	
    )
	
    }
