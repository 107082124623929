// File: user_profile_menu.tsx
//

import * as React from 'react';
import  {useNavigate} from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Fade from '@mui/material/Fade';
import PersonIcon from '@mui/icons-material/Person';
import { LogoutDialog } from '../../utils_common/logout_dialog';


export function UserProfileMenu() : React.ReactElement  {

    const [ openLogout, setOpenLogout ] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLElement>) :void => {
	setAnchorEl(event.currentTarget);
    };
    const handleClose = (path:string|null) :void => {
	setAnchorEl(null);
	if(path) navigate(path);
    };

    return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
	<PersonIcon style={{fill:'white'}}/>
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={()=>handleClose(null)}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>handleClose('/change-email')}>Change email</MenuItem>
        <MenuItem onClick={()=>handleClose('/change-password')}>Change password</MenuItem>	
        <MenuItem onClick={()=> {setOpenLogout(true);handleClose(null)} }>Logout</MenuItem>
      </Menu>

      <LogoutDialog open={()=> openLogout }
	close={ ()=> setOpenLogout(false) }/>
    </div>
  );
}

