// File: outgoing_types.ts
//

import { z  } from "zod";

/////////////////////////////////////////////////////

export const CREATE_ACCT_ENDING_URL='/create-acct';
export const SIGNIN_ENDING_URL='/signin';

export const MOBILE_UPGRADE_CREATE_ACCT_ENDING_URL='/create-acct/mobupgrade'
export const MOBILE_UPGRADE_SIGNIN_ENDING_URL='/signin/mobupgrade'

export const PASSWORD_RESET_ENDING_URL='/password-reset';

export const PWLESS_SIGNIN_ENDING_URL='/signin-from-link';
export const PWLESS_REQ_LINK_ENDING_URL='/req-signin-link';

/////////////////////////////////////////////////////
export const ECOMM_EMAIL_LOGIN_URL='/ecomm-email-login';
export const ECOMM_NOT_FOUND_URL='/ecomm-not-found';
export const ECOMM_MOBUPGRADE_LANDING_URL='/ecomm-mobupgrade-landing';
export const ECOMM_DISPLAY_LICENSE_KEYS_LANDING_URL='/ecomm-license-keys-landing';
export const ECOMM_LICENSE_INFO_URL='/ecomm-license-info';

/////////////////////////////////////////////////////

export const ECOMM_INIT_SESSION_COOKIE_API_URL='/ecomm-init-session-cookie';
export const GET_SESSION_TOKEN_API_URL='/get-session-token';

export const SESSION_TOKEN_TTL_SECS=10*60; // 10 minutes
export const SESSION_COOKIE_TTL_SECS=SESSION_TOKEN_TTL_SECS;


/////////////////////////////////////////////////////

export const MOBILE_UPGRADE_PRICE_USD='100';

/////////////////////////////////////////////////////


export interface AuthInType {
    session_token:string|null;            
}

export interface RequestTypeAny {
    cmd : string;
    input : object;
    user_test_email?:string
}

export interface RequestTypeAnyAuth {
    cmd : string;
    input : AuthInType;
    user_test_email?:string
}

export interface RequestType<T> {
    cmd : string,
    input : T
}

export interface AdminRequestType<T>  extends  RequestType<T> {
    cmd : string,
    is_admin_request:boolean,  // always set to true
    input : T
}

export interface ResultType {
    status : MsgStatus;
    [key:string]:any;  // allows any additional property of any type
}

export interface ResponseTypeAny {
    result :  ResultType|null;
}

export interface ResponseType<T extends ResultType> {
    result : T|null
}

export enum MsgStatus {
    OK='OK',
    ERROR='ERROR'
}

// check if email has been verified (will enforce on server end) so
// this is to give user the correct error message since
// the firebase create password function only gives internal-error
// because we are enforcing it on before_first_signin() blocking function
export namespace GetEmailIsVerified {
    export const CMD='get-email-is-verified';
    export interface InType {
        email : string
    }
    export const IN_INSTANCE : InType = {
        email:''
    }
    /*
    export type VerifyEmailStatus = 'ERROR' | 'VERIFIED' |'DOES_NOT_EXIST' |
        'EXISTS_BUT_EXPIRED' | 'EXISTS_BUT_NOT_VERIFIED';
    */
    export type VerifyEmailStatus = 'ERROR' | 'VERIFIED' |'NOT_VERIFIED'|'DOES_NOT_EXIST';
        
    export interface OutType {
        status : MsgStatus,
        email_status : VerifyEmailStatus,
    }    
    export const ERROR_OUT : OutType = {
        status : MsgStatus.ERROR,
        email_status : 'ERROR'
    }
}

// check if email already used by existing firebase auth account
export namespace GetEmailIsAlreadyReg {
    export const CMD='get-email-is-already-reg';
    export interface InType {
        email : string
    }
    export const IN_INSTANCE : InType = {
        email:''
    }
    export interface OutType {
        status : MsgStatus,
        email_is_registered : boolean
    }    
}

// check if registered email is eligible for a new portal account
// (doesnt check if email already used by existing firebase auth account)
export namespace GetNewEmailIsEligible {
    export const CMD='get-new-email-is-eligible';
    export interface InType {
        email : string
    }
    export const IN_INSTANCE : InType = {
        email:''
    }

    /*
    export enum NewEmailEligibility {
        SERVER_ERROR='SERVER_ERROR',
        ELIGIBLE='ELIGIBLE',
        IN_ELIGIBLE='EMAIL_NOT_ELIGIBLE',
        ALREADY_USED= 'EMAIL_ALREADY_IN_USE',                       
    }*/
    export type NewEmailEligibility = 
        'SERVER_ERROR' | 'ELIGIBLE' | 'EMAIL_NOT_ELIGIBLE' | 'EMAIL_ALREADY_IN_USE' |
        'SERVER_INTERNAL_ERROR';
    
    export interface OutType {
        status : MsgStatus,
        email_status: NewEmailEligibility,
    }

    export const ERROR_OUT : OutType = {
        status : MsgStatus.ERROR,
        email_status: 'SERVER_INTERNAL_ERROR',
    }
}

export namespace DeleteNonVerifiedEmailAcct {
    export const CMD='delete-non-verified-email-acct';
    export interface InType {
        email : string
    }
    export const IN_INSTANCE : InType = {
        email:''
    }
    export interface OutType {
        status : MsgStatus
    }    
}

// requests server to send an email verify link
// for passwordless signin
export namespace PWLessRequestEmailLoginLink {
    export const CMD='pwless-request-email-login-link';
    export interface InType {
        email:string,
        web_host_is_local:boolean,
    }
    export const IN_INSTANCE : InType = {
        email:'',
        web_host_is_local:true,
    }

    export type ErrorStatus =
        'SERVER_INTERNAL_ERROR' |
        'ERROR_SENDING_EMAIL_LINK' |
        'NO_ACCT_WITH_EMAIL_EXISTS' |
        'USER_IS_ADMIN' |                
        null ;     
    
    export interface OutType {
        status : MsgStatus,
        error_msg:null|string,
        error_status:ErrorStatus,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        error_msg:null,
        error_status:'SERVER_INTERNAL_ERROR',
    }
}

    
export const PortalURLRedirectDestSchema = z.enum(['HOME', 'ADMIN_HOME', 'MOBUPGRADE', 'NONE']);

export type PortalURLRedirectDest = z.infer<typeof PortalURLRedirectDestSchema>;

// requests server for custom signin token
// that can be used for passwordless signin
export namespace PWLessGetSigninTokenFromUrlToken {
    export const CMD='pwless-get-signin-token-from-url-token';
    export interface InType {
        url_token:string,
        email:string,
    }
    export const IN_INSTANCE : InType = {
        url_token:'',
        email:'',
    }
    
    export type RedirectDest = PortalURLRedirectDest;

    type ErrorStatus = 'ERROR_CREATING_ACCT' |
        'INVALID_URL_TOKEN' |        
        'EMAIL_ALREADY_IN_USE' |                      
//        'INIT_PASSWORD_NOT_CORRECT' |      
        'EMAIL_NOT_ELIGIBLE' |
        'INTERNAL_ERROR' |
        'SERVER_INTERNAL_ERROR' |
        'EMAIL_IS_FOR_ADMIN_USER' |                
        'SERVER_ERROR' |
             null ;     

    
    export interface OutType {
        status : MsgStatus,
        error_msg:null|string,
        error_status: ErrorStatus,
        signin_custom_token: null|string,
        redirect_dest : RedirectDest,        
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        error_msg:null,
        error_status: 'SERVER_INTERNAL_ERROR',
        signin_custom_token: null,
        redirect_dest : 'NONE',
    }
}

// checks user inputted password of email before account creation for
// initially assigned required passwords;
//
// for warning the user; the condition on email+password will be enforced
// on the server-side when the email verification link is requested
export namespace CheckPasswordBeforeAcct {
    export const CMD='check-password-before-acct';
    export interface InType {
        email : string,
        password:string,
    }
    export const IN_INSTANCE : InType = {
        email:'',
        password:'',
    }
    export interface OutType {
        status : MsgStatus
        password_is_ok:boolean,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        password_is_ok:false,
    }
}

export namespace CreateAcctFromUrlTokenGetEmail {
    export const CMD='create-acct-from-url-token-get-email';
    export interface InType {
        url_token:string,
    }
    export const IN_INSTANCE : InType = {
        url_token:''
    }

    export type ErrorStatus =
        'ERROR_SENDING_EMAIL_LINK' |        
        null ;     
    
    export interface OutType {
        status : MsgStatus,
        email:string | null,
        error_msg:null|string,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        email:null,
        error_msg:null,
    }
}

export namespace CreateAcctFromUrlToken {
    export const CMD='create-acct-from-url-token';
    export interface InType {
        url_token:string,
        email:string,
        password:string,
        cont_url_ending:string,
        session_is_local:boolean,
    }
    export const IN_INSTANCE : InType = {
        url_token:'',
        email:'',
        password:'',
        cont_url_ending:'',
        session_is_local:true,
    }

    export type ErrorStatus =
        'ERROR_SENDING_EMAIL_LINK' |        
        null ;     
    
    export interface OutType {
        status : MsgStatus,
        acct_with_email_exists : boolean,        
        error_msg:null|string,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        acct_with_email_exists :false,
        error_msg:null,
    }
}

// create account with email and required password;
// create new account set user.customClaims['password_ok']=true
// so that it can be checked during signin
export namespace CreateNewUserAcct {
    export const CMD='create-new-user_acct';
    export interface InType {
        email : string,
        password:string,
        cont_url_ending:string,
        session_is_local:boolean,
    }
    export const IN_INSTANCE : InType = {
        email:'',
        password:'',
        cont_url_ending:'',
        session_is_local:false,
    }

    export type ErrorStatus =
        'ERROR_CREATING_ACCT' |
        'EMAIL_ALREADY_IN_USE' |                      
        'INIT_PASSWORD_NOT_CORRECT' |      
        'EMAIL_NOT_ELIGIBLE' |
        'INTERNAL_ERROR' |
        'ERROR_SENDING_EMAIL_LINK' |
        'SERVER_INTERNAL_ERROR' |        
        'SERVER_ERROR' |
             null ;     
    
    export interface OutType {
        status : MsgStatus,
        error_status: ErrorStatus,
        error_msg:null|string,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        error_status: 'SERVER_INTERNAL_ERROR',
        error_msg:null,
    }
}

export namespace SendEmailVerifyLink {
    export const CMD='send-email-verify-link';
    export interface InType {
        email : string,
        cont_url_ending: string,
        session_is_local:boolean,
    }
    export const IN_INSTANCE : InType = {
        email:'',
        cont_url_ending:'',
        session_is_local:false,
    }

    export type ErrorStatus =  'ACCT-NOT-FOUND' | 'ERROR-EMAILING' | 'COMM-ERROR' |  'INTERNAL-ERROR' | null ; 
    export interface OutType {
        status : MsgStatus,
        error_status : ErrorStatus;
        error_msg:string|null,
    }
    
    export const ERROR_OUT:OutType = {
        status: MsgStatus.ERROR,
        error_status : 'INTERNAL-ERROR',
        error_msg:null
    }
}

export namespace VerifyEmailAcct {
    export const CMD='verify-email-acct';
    export interface InType  {
        link_token : string,
    }
    export const IN_INSTANCE : InType = {
        link_token:'',
    }
    
    export type ErrorStatus="COMM_ERROR"| 'TOKEN_EXPIRED' | "BAD_TOKEN" | "FIREBASE_AUTH_ERROR_EXCEPT"|
        "INTERNAL_ERROR" | "ACCOUNT_ALREADY_CREATED"|"ERROR_UPDATING_EMAIL_VERIFIED"|null;
    
    export interface OutType {
        status : MsgStatus,
        error_status:ErrorStatus,
    }
    export const ERROR_OUT:OutType = {
        status:MsgStatus.ERROR,
        error_status: 'COMM_ERROR',
    }
}

export namespace SendResetPasswordLink {
    export const CMD='send-reset-password-link';
    export interface InType {
        email:string,
        webhost_is_local:boolean,
    }
    export const IN_INSTANCE : InType = {
        email:'',
        webhost_is_local:true,
    }
    export type SendResetStatus = 'OK'|'Internal_Error'|'Email_Has_No_Account'|'Error_Sending_Email';
    
    export interface OutType {
        status : MsgStatus,
        error_msg : string|null
        send_reset_status: SendResetStatus,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        error_msg:'Server Comm Error.',
        send_reset_status: 'Internal_Error',
    }
}

export namespace GetResetPasswordFromUrlTokenNotExpired {
    export const CMD='get-reset-password-from-url-token-not-expired';
    export interface InType {
        url_token:string,
    }
    export const IN_INSTANCE : InType = {
        url_token:'',
    }
    
    export interface OutType {
        status : MsgStatus,
        not_expired:boolean
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        not_expired:true
    }
}

export namespace ResetPasswordFromUrlToken {
    export const CMD='reset-password-from-url-token';
    export interface InType {
        url_token:string,
        email:string,
        password:string,
    }
    export const IN_INSTANCE : InType = {
        url_token:'',
        email:'',
        password:'',
    }
    export type ResetStatus = 'OK'|'Internal_Error' | 'Password_Is_Weak' |
        'Could_Not_Find_Token'  | 'Email_Does_Not_Match' | 'Error_Changing_Password'     ;
    
    export interface OutType {
        status : MsgStatus,
        error_msg : string|null
        reset_status:ResetStatus,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        error_msg:'Server Comm Error.',
        reset_status:'Internal_Error',
    }
}

export namespace CreateSendPendingActivation {
    export const CMD='create-send-pending-activation';
    export interface InType extends AuthInType {
        session_token:string|null        
        license_key:string,
    }
    export const IN_INSTANCE : InType = {
        session_token:'',
        license_key:'',
    }    
    export interface OutType {
        status : MsgStatus,
        error_msg : string|null
        act_code_expiry_period_mins : number,
    }
    export const ERROR_OUT:OutType={
        status: MsgStatus.ERROR,
        error_msg:'Server Comm Error.',
        act_code_expiry_period_mins : 15,
    }
}

// called by the landing page for requesting that the server send
// out a new code for mobile activation; users got to the landing
// page by link in a email notifying that a license has been offered
// or purchased
export namespace ReqEmailMobileActCode {
    export const CMD='req-email-mobile-act-code';
    
    export interface InType extends AuthInType {
        session_token:string|null,
        email_entered:string,
        url_encrypted_params:string,
    }
    export const IN_INSTANCE : InType = {
        session_token:'',
        email_entered:'',
        url_encrypted_params:'',
    }

    export enum Status {
        OK="OK",
        EMAIL_NOT_FOUND="EMAIL_NOT_FOUND",
        EMAIL_MISMATCH="EMAIL_MISMATCH",
        LICENSE_EXPIRED='LICENSE_EXPIRED',
        LICENSE_NOT_VALID='LICENSE_NOT_VALID',
        ERROR_CHECKING_LICENSE_STATUS='ERROR_CHECKING_LICENSE_STATUS',
        AT_MAX_NUM_DEVICES_PER_LICENSE='AT_MAX_NUM_DEVICES_PER_LICENSE',
        PENDING_ACT_CODE_EXPIRED="PENDING_ACT_CODE_EXPIRED",
        ERROR_CREATING_PENDING_ACT_CODE="ERROR_CREATING_PENDING_ACT_CODE",
        ERROR_SENDING_EMAIL="ERROR_SENDING_EMAIL",
        COULD_NOT_FIND_ACT_CODE="COULD_NOT_FIND_ACT_CODE",
        COULD_NOT_RETRIEVE_NUM_ACTIVATIONS="COULD_NOT_RETRIEVE_NUM_ACTIVATIONS",
        URL_PARSE_ERROR="URL_PARSE_ERROR",
        RECEIVE_ERROR="RECEIVE_ERROR",
    }
    export interface OutType {
        status : MsgStatus,        
        act_code_status : Status,
    }

    export const ERROR_OUT = {
        status : MsgStatus.ERROR,
        act_code_status : Status.RECEIVE_ERROR,
    }
}

// called on login 
// (1. email change will be recorded on login based on if
//   login email is different than that previously stored for acct
//  2. purchase will be recorded in purchase)
export namespace ReportUserLogin {
    export const CMD='report-user-login';

    export interface InType  extends AuthInType {
        session_token:string|null,
    }
    
    export const IN_INSTANCE : InType = {
        session_token:'',
    }    
    export interface OutType {
        status : MsgStatus,        
    }
}

export namespace GetEmailChangeStatus {
    export const CMD='get-email-change-status';
    export interface InType  extends AuthInType {
        session_token:string|null,
        new_email : string,
    }
    export const IN_INSTANCE : InType = {
        session_token:'',
        new_email:''
    }

    export enum EmailChangeStatus {
        NEW_EMAIL_OK='NEW_EMAIL_OK',
        NEW_EMAIL_INVALID='NEW_EMAIL_INVALID',
        EMAIL_ALREADY_IN_USE='EMAIL_ALREADY_IN_USE',
        ADMINS_CANT_CHANGE_EMAIL='ADMINS_CANT_CHANGE_EMAIL',
        SERVER_ERROR='SERVER_ERROR',
    }
    
    export interface OutType {
        status : MsgStatus,
        email_change_status: EmailChangeStatus,
    }
}


export interface UserPurchaseInfo {
    // has legacy desktop app and has not taken the upgrade yet
    eligible_for_mobile_upgrade : boolean,
    // has one or more mobile device licensed with app
    has_mobile_app:boolean,
    // has one or more desktop license
    has_desktop_license : boolean 
}

export namespace GetDesktopActivationInfo {

    export const CMD='get-desktop-activation-info';
    
    export interface DesktopActivationInfo {
        emails_of_key : string[],
        license_key:string,        
        num_desktop_activations : number|null,
        max_desktop_activations : number|null,
        issue_date : string|null,        
        expiry_date:null | string,
        is_subscription:boolean,
        as_user_id : string,
        as_sku : string,
        as_is_free:boolean|null,
    }
     
    export interface InType  extends AuthInType  {
        session_token:string|null
    }

    export const IN_INSTANCE : InType = {
        session_token:''        
    }
    
    export interface OutType {
        status : MsgStatus;
        desktop_activation_infos : DesktopActivationInfo[]|null
    }

    export const ERROR_OUT:OutType = {
        status : MsgStatus.ERROR,
        desktop_activation_infos:null,
    }
}

export namespace GetUserLicensesInfo {

    export const CMD='get-user-licenses-info';

   export interface LicenseType {
       // license allows mobile                    
       includes_mobile:boolean;
       // license allow desktop                    
       includes_desktop:boolean;
       // in addition, we have to check issue date 
       includes_upgrade_to_mobile : boolean;
       // true if this license has been upgraded to mobile already
       has_upgraded_to_mobile:boolean;        
   }
    
    export interface LicenseInfo {
        emails_of_license: string[],
        num_mobile_devices_activated : number,
        max_mobile_devices_activated_per_license : number,
        license_key:string,
        issue_date : string|null,        
        expiry_date:null | string,
        license_type : LicenseType,
        // makes sure that total number of devices activated on license
        // is not exceeded (does not check max activatoins per devices)
        mobile_device_activations_are_left:boolean,
        is_subscription:boolean;
    }
    
    export interface MobileDeviceInfo {
        device_id_hash:string,
        model : string,
        num_times_device_activated : number,
        max_num_times_device_activated : number,
        activation_date : null |string,        
        expiry_date : null |string,
        platform : string,
        license_key:string
    }

    export interface UserLicensesInfo {
        purchase_info : UserPurchaseInfo|null;
        license_infos : LicenseInfo[];
        mobile_device_infos : MobileDeviceInfo[];
    }
     
    export interface InType  extends AuthInType  {
        session_token:string|null
    }

    export const IN_INSTANCE : InType = {
        session_token:''        
    }
    
    export interface OutType {
        status : MsgStatus;
        user_licenses_info : UserLicensesInfo|null;
    }
}


export namespace SessionManage {

    // initializes the session cookie if the ecomm email and url params is correct
    export namespace ECommInitSessionCookie {
        export const InTypeSchema = z.object({
            url_params: z.string(),
            user_email : z.string()
        });
        export type InType = z.infer<typeof InTypeSchema>;

        export type ReqStatus = 'EMAIL_OK' | 'COMM_ERROR' | 'INVALID_EMAIL' |
            'URL_PARAMS_UNKNOWN' |
            'URL_PARAMS_EXPIRED';
        
        export type OutType = {
            status: ReqStatus
        }
        export const ERROR_OUT : OutType = {
            status :'COMM_ERROR'
        }
    }

    // refreshes session cookie and returns session token
    // (requires existing session cookie that is transferred in the header
    // in the http message from the browser to the firebase server
    export namespace GetSessionCookieToken {
        export type InType = {
        }
        export type OutType = {
            session_token:string|null,
        }
        export const ERROR_OUT : OutType = {
            session_token:null,
        }        
    }    
}

/*
export type ECommLandingPageType = 'NONE' | 'MOBUPGRADE' |
    'DESKTOP_FULL_PURCHASE' | 'DESKTOP_PLUS_MOBILE_FULL_PURCHASE' |
    'DESKTOP_SUBSCRIPTION_PURCHASE' | 'DESKTOP_PLUS_MOBILE_SUBSCRIPTION_PURCHASE' |
    'FREE_DESKTOP' | 'FREE_DESKTOP_PLUS_MOBILE' | 'FREE_MOBILE' |
    'USER_LICENSE_INFO';
*/

const ECommNonMobupgradeInviteTypeSchema = z.union([
    z.literal('NONE') ,    
    z.literal('DESKTOP_FULL_PURCHASE'),
    z.literal('DESKTOP_PLUS_MOBILE_FULL_PURCHASE'),
    z.literal('DESKTOP_SUBSCRIPTION_PURCHASE'),
    z.literal('DESKTOP_PLUS_MOBILE_SUBSCRIPTION_PURCHASE'),
    z.literal('FREE_DESKTOP'),
    z.literal('FREE_DESKTOP_PLUS_MOBILE'),
    z.literal('FREE_MOBILE'),
]);

export type ECommNonMobupgradeInviteType = z.infer<typeof ECommNonMobupgradeInviteTypeSchema>;

const ECommInviteTypeSchema = z.union([
    ECommNonMobupgradeInviteTypeSchema,    
    z.literal('MOBUPGRADE'), 
]);

export type ECommInviteType = z.infer<typeof ECommInviteTypeSchema>;

const ECommLandingPageTypeSchema = z.union([
    ECommInviteTypeSchema,    
    z.literal('USER_LICENSE_INFO'),
]);

export type ECommLandingPageType = z.infer<typeof ECommLandingPageTypeSchema>;

export namespace EComm {

    export namespace ReqECommSessionToken {    
        export const CMD='request-ecomm-session-token';

        export const HRS_BEFORE_SESSION_TOKEN_EXPIRES=0.5;
        
        export interface InType {
            url_params : string,
            user_email:string,
        }
        export const IN_INSTANCE : InType = {
            url_params : '',
            user_email:''
        }

        export type ReqStatus = 'INVALID_EMAIL' | 'INVALID_URL_PARAMS' |  'URL_PARAMS_EXPIRED'  | 'INTERNAL_ERROR' | 'OK';
        
        export type OutType = {
            session_token :string|null,
            req_status : ReqStatus,
            status : MsgStatus,        
        }
        export const ERROR_OUT : OutType = {
            session_token : null,
            req_status : 'INTERNAL_ERROR',
            status : MsgStatus.ERROR
        }
    }
    
    export namespace RefreshECommSessionToken {    
        export const CMD='refresh-ecomm-session-token';
        export interface InType {
            session_token:string
        }
        export const IN_INSTANCE : InType = {
            session_token:''            
        }
        export type OutType = {
            new_session_token : string|null,
            status : MsgStatus,        
        }
        export const ERROR_OUT : OutType = {
            new_session_token : null,            
            status : MsgStatus.ERROR
        }
    }

    export namespace ReqSessionTokenIsOK {    
        export const CMD='request-ecomm-session-token-is-ok';
        export interface InType {
            session_token : string
        }
        export const IN_INSTANCE : InType = {
            session_token:''
        }
        export type OutType = {
            session_token_is_ok:boolean,
            status : MsgStatus,        
        }
        export const ERROR_OUT : OutType = {
            session_token_is_ok:false,
            status : MsgStatus.ERROR
        }
    }

    // called before  email has been logged in
    export namespace CheckECommUrlParams{    
        export const CMD='check-ecomm-url-params';
        export interface InType {
            url_params : string,
        }
        export const IN_INSTANCE : InType = {
            url_params : '',
        }
        export type OutType = {
            ok:boolean,
            status : MsgStatus,        
        }
        export const ERROR_OUT : OutType = {
            ok:false,
            status : MsgStatus.ERROR
        }
    }

    export namespace CheckECommUrlParamsAndSessionToken {    
        export const CMD='check-ecomm-url-params-and-session-token';
        export interface InType {
            url_params : string,
            session_token:string,            
        }
        export const IN_INSTANCE : InType = {
            url_params : '',
            session_token:'',
        }
        export type OutType = {
            ok:boolean,
            status : MsgStatus,        
        }
        export const ERROR_OUT : OutType = {
            ok:false,
            status : MsgStatus.ERROR
        }
    }
  
    export namespace ReqECommLandingPageType {
        export const CMD='request-ecomm-landing-page-type';
        export interface InType {
            url_params : string,
            session_token:string,                        
        }
        export const IN_INSTANCE : InType = {
            url_params : '',
            session_token:'',
        }
        export type OutType = {
            ecomm_landing_page_type : ECommLandingPageType,
            status : MsgStatus,        
        }
        export const ERROR_OUT : OutType = {
            ecomm_landing_page_type : 'NONE',
            status : MsgStatus.ERROR
        }        
    }

    export namespace ECommReqLicenseKeys {
        export const CMD='ecomm-request-license-keys';
        export interface InType {
            url_params : string,
            session_token:string,                                    
        }
        export const IN_INSTANCE : InType = {
            url_params : '',
            session_token:'',            
        }
        export interface DesktopLicense {
            license_key : string,
            license_user_id: string,
            max_num_devices : number, 
            max_act_per_device : number,
            expiration_date: string|null    // iso UTC                    
        }
        export interface MobileLicense {
            mobile_activation_code : string,
            license_email: string,            
            max_num_devices : number, 
            max_act_per_device : number,
            expiration_date: string|null  // iso UTC                              
        }
        export interface  Licenses {
            desktop_license:DesktopLicense|null,
            mobile_license:MobileLicense|null,            
        }

        export type OutType = {
            licenses : Licenses|null,
            status : MsgStatus,
            landing_page_type : ECommNonMobupgradeInviteType,
        }
        export const ERROR_OUT : OutType = {
            licenses : null,
            status : MsgStatus.ERROR,
            landing_page_type : 'NONE',
        }
    }

    export namespace ECommGetUserLicensesInfo {

        export const CMD='ecomm-get-user-licenses-info';
        
        export interface InType  {
            url_params:string,
            session_token:string,                                                
        }

        export const IN_INSTANCE : InType = {
            url_params:'',
            session_token:'',
        }

        export interface DesktopLicense {
            license_key : string,
            license_user_id: string,
            max_num_devices : number, 
            max_act_per_device : number,
            issue_date: string,             // iso UTC                    
            expiration_date: string|null    // iso UTC                    
        }
        
        export interface MobileLicense {
            activation_code : string,
            license_email: string,            
            max_num_devices : number, 
            max_act_per_device : number,
            issue_date: string,             // iso UTC                                
            expiration_date: string|null  // iso UTC                              
        }
        
        export interface  Licenses {
            desktop_licenses:DesktopLicense[],
            mobile_licenses:MobileLicense[],
        }

        export interface MobileDevice {
            activation_code : string,
            device_desc:string,
            platform:string,
            num_act:number,
        }

        export interface LicensesInfo {
            desktop_licenses : DesktopLicense[],
            mobile_licenses : MobileLicense[],
            mobile_devices : MobileDevice[],
        }
        
        export type OutType = {
            licenses_info : LicensesInfo,
            status : MsgStatus,
        }
        
        export const ERROR_OUT : OutType = {
            licenses_info : {
                desktop_licenses : [],
                mobile_licenses :[],
                mobile_devices : [],
            },
            status : MsgStatus.ERROR,
        }
    }
    
}


/////////////////////FS SESSION URL////////////////////////////////

// request url for a FS session page (not a popup)
export namespace ReqMobUpgradeFSProdURL {
    
    export const CMD='request-mobile-upgrade-fs-product-url';
    // user id will be automatically sent by firebase
    export interface InType {
        session_token : string|null,
        parent_license_key:string,
    }
    export const IN_INSTANCE : InType = {
        session_token:'',
        parent_license_key:'',
    }
    export type OutType = {
        fs_prod_url : string,
        status : MsgStatus,        
    }        
}

/////////////////////POPUP TYPES COMMON////////////////////////////////

export interface MobUpgradePopupOrder {
    // fastspring order number that is globally unique irrespective
    // of company
    fs_order_id : string,
    // fastspring order number        
    fs_order_reference : string,
    // product 'path'
    product_path : string,
    // not really an 'sku' but its value
    // is a unique token sent in the encrypted order
    // and sent back by FS;
    // so we can check if the message is valid; this token
    // must be checked that it is not used more than one
    // (this is done by deleting the token the first time
    // an order is received with this 'sku')        
    sku : string,
    subtotal:number,
    total:number,
}

export enum PopupCancelOrderStatus {
    ORDER_MUST_NOT_BE_CANCELLED='ORDER_MUST_NOT_BE_CANCELLED',
    ORDER_ALREADY_CANCELLED='ORDER_ALREADY_CANCELLED',
    ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED='ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED',
    ORDER_CANCELLED='ORDER_CANCELLED'
}

/////////////////////PORTAL MOBILE UPGRADE POPUP////////////////////////////////

// encrypted session data in mobile upgrade popup store to be sent to FS 
export namespace GetUpgradePopupSession {
    
    export const CMD='get-upgrade-popup-session';
    
    export enum ReturnCode {
        SUCCESS='SUCCESS',
        FAILED='FAILED',        
     }

    export interface InType  extends AuthInType {
        session_token:string|null;        
        customer_email : string;
        parent_desktop_license_key:string;        
    }
    export const IN_INSTANCE : InType = {
        session_token:'',
        customer_email:'',
        parent_desktop_license_key: '',
    }
    
    export type OutType = {
        status : MsgStatus,                
        encrypted_session : {
            // object to pass to fastspring store builder library
            //  fastspring.builder.secure(secured_data, secured_key)
            // base64 of aes encrypted payload using key
	    secured_data : string,               
            // base64 of above key encrypted by rsa public/private encryption
            secured_key  : string    
        }
    }
}

export namespace CheckLicenseEligibleForMobileUpgrade {
    export const CMD='check-license-eligible-for-mobile-upgrade';
    export interface InType  extends AuthInType {
        session_token:string|null,        
        desktop_license_key : string
    }
    export const IN_INSTANCE : InType = {
        session_token:'',        
        desktop_license_key : ''
    }
    export type OutType = {
        status : MsgStatus,
        is_valid : boolean,
        error_message: string|null
    }    
}


// check the order from mobile upgrade FS popup store and if OK,
// create license and send back OK response
export namespace PopupCreateMobileUpgradeLicense {
    
    export const CMD='popup-create-mobile-upgrade-license';

    export const CancelOrderStatus=PopupCancelOrderStatus;

    // values obtained from info returned by FS
    // after the user does the popup store purchase
    export interface InType  extends AuthInType, MobUpgradePopupOrder {
        session_token:string|null,        
        // license key of the legacy desktop app that
        // is eligible for upgrade to the mobile app
        parent_desktop_license_key : string
    }
    
    export const IN_INSTANCE : InType = {
        session_token:'',
        fs_order_id : '',
        fs_order_reference : '',
        product_path : '',
        sku : '',
        parent_desktop_license_key:'',
        subtotal:0,
        total:0
    }
    
    export type OutType = {
        status : MsgStatus,
        is_valid : boolean,
        error_message: string|null,
        cancel_order_status : PopupCancelOrderStatus
    }
}

//////////////////////////////RELAY BROWSER LOGGING//////////////////////

export namespace RelayBrowserLogging {
    export const CMD='relay-browser-logging';
    export interface InType  extends AuthInType {
        // if non-empty, will be output to USER_PORTAL_EVENTS log
        // otherwise will go to USER_PORTAL log
        event_type:string, 
        session_token : string|null,        
        time:string,
        email:string,
        user_id:string,
        severity: string,
        msg : string,
        // will be spread into jsonPayload object  message
        // for event should include event_type:string
        obj:object     
    }
    export const IN_INSTANCE : InType = {
        event_type:'',
        session_token : '',        
        time:'',
        email: '',
        user_id:'',
        severity: '',
        msg : '',
        obj:{}
    }
    export type OutType = {
        status : MsgStatus        
    }    
}


export namespace RelayBrowserRegLogging {
    export const CMD='relay-browser-reg-logging'; 
    export interface InType {
        // if non-empty, will be output to USER_PORTAL_EVENTS log
        // otherwise will go to USER_PORTAL log
        event_type:string, 
        time:string,
        session_token : string|null,
        severity: string,
        msg : string,
        // will be spread into jsonPayload object  message
        // for event should include event_type:string
        obj:object     
    }
    export const IN_INSTANCE : InType = {
        event_type:'',
        time:'',
        session_token : '',                
        severity: '',
        msg : '',
        obj:{}
    }
    export type OutType = {
        status : MsgStatus        
    }    
}

/////////////////////////ADMIN/////////////////////////////
/* following are called by admin console only */

export enum AdminRole {
    NONE='NONE',
    MANAGER='MANAGER',
    EDITOR='EDITOR',
    VIEWER='VIEWER'
}

export function get_admin_role_from_str(role: string) : AdminRole {
    const admin_role = AdminRole[ role as keyof typeof AdminRole];
    if(admin_role===undefined) {
        console.error('outgoing_types: get_admin_role_from_str: role=' + role+ ', return NONE');
        return AdminRole.NONE;
    }
    return admin_role;
}

// manager only
export namespace ListAdmins{
    export const CMD='list-admins';    
    export const ALLOWED_ROLES=[AdminRole.MANAGER];
    export interface InType {
        session_token : string|null;                                                        
    }
    export const IN_INSTANCE : InType = {
        session_token : '',
    }

    export interface AdminInfo {
        portal_email:string|null;
        invited_email:string;
        admin_uid:string;
        admin_role:string;
        is_preassigned_admin:boolean;
        invite_date:string|null; // null if not invited yet
    }
    export interface OutType {
        status : MsgStatus;
        admin_infos: AdminInfo[];
        error_msg:string|null
    }
    export const ERROR_OUT : OutType = {
        status : MsgStatus.ERROR,
        admin_infos: [],
        error_msg:null,
    }
}

// only manager role
export namespace AddNewAdmin {
    export const CMD='add-new-admin';
    export const ALLOWED_ROLES=[AdminRole.MANAGER];        
    export interface InType {
        session_token : string|null;                                                
        invited_email:string;
        admin_role:string;
        send_invite:boolean;
        web_host_is_local:boolean;
    }
    export const IN_INSTANCE : InType = {
        session_token : '',
        invited_email:'',
        admin_role:'',
        send_invite:true,
        web_host_is_local:true,
    }
    export interface OutType {
        status : MsgStatus;
        error_msg: string|null;
    }
    export const ERROR_OUT: OutType = {
        status : MsgStatus.ERROR,
        error_msg: null,
    }
}

// only manager role
export namespace SendAdminInvite {
    export const CMD='send-admin-invite';
    export const ALLOWED_ROLES=[AdminRole.MANAGER];        
    export interface InType {
        session_token : string|null;                                                
        invited_email:string;
        web_host_is_local:boolean;
    }
    export const IN_INSTANCE : InType = {
        session_token : '',
        invited_email:'',
        web_host_is_local:true,
    }
    export interface OutType {
        status : MsgStatus;
        error_msg: string|null;
    }
    export const ERROR_OUT: OutType = {
        status : MsgStatus.ERROR,
        error_msg: null,
    }
}

// only manager role
export namespace ChangeAdminRole {
    export const CMD='change-admin-role';
    export const ALLOWED_ROLES=[AdminRole.MANAGER];        
    export interface InType {
        session_token : string|null;                                                        
        admin_uid:string,
        admin_role:string;
    }
    
    export const IN_INSTANCE : InType = {
        session_token : '',
        admin_uid:'',
        admin_role:''
    }

    export type ErrorStatus = 'NONE' | "Can't change admin role for pre-assigned managers." |
        'Admin user does not exist.'| 'Server Error.';
    
    export interface OutType {
        status : MsgStatus;
        is_preassigned_manager:boolean;
        error_status:ErrorStatus;
    }

    export const ERROR_OUT:OutType = {
        status: MsgStatus.ERROR,
        is_preassigned_manager:false,
        error_status: 'Server Error.'
    }
}

/*
// only manager role
export namespace GetEmailIsPreassignedManager {
    export const CMD='get-email-is-preassigned-manager';
    export const ALLOWED_ROLES=[AdminRole.MANAGER];        
    export interface InType {
        session_token : string|null;                                                        
        email:string;
    }
    
    export const IN_INSTANCE : InType = {
        session_token : '',
        email:'',
    }

    export interface OutType {
        status : MsgStatus;
        is_preassigned_manager:boolean;
    }        
}*/

// all admin roles
export namespace ListAllLicensesMsg {
    export const CMD='list-all-licenses-msg';
    export const ALLOWED_ROLES=[AdminRole.VIEWER,AdminRole.EDITOR, AdminRole.MANAGER];

    //export type LicenseSortMethod =  "by-portal-email" | "by-fs-email";
    
    export interface InType {
        session_token : string|null;
        min_range : number;
        // max_range is inclusive and can be negative (obeys array slice semantics)
        // max_range=null means no restriction on upper index limit
        max_range : number|null;         
    }
    export const IN_INSTANCE : InType = {
        session_token : '',
        min_range:0,
        max_range:0,
    }
    export interface LicenseType {
        has_upgraded_to_mobile:boolean;
        includes_desktop:boolean;
        includes_mobile:boolean;
        includes_upgrade_to_mobile:boolean;
    }

    export interface ProductInfo {
        fs_order_ref : string;             // can be blank if unknown
        fs_classic_product: string;        // can be blank if unknown
        fs_contextual_product_id: string;  // can be blank if unknown
        fs_classic_product_path: string;     // can be blank if unknown        
        as_sku:string;                     // can be blank if unknown        
    }

    export type SubscriptionStatus =
        'IS_SUBSCRIPTION' |
        'NOT_SUBSCRIPTION'|
        'UNKNOWN';
    
    export interface UserLicense {
        sorting_email:string;
        as_emails:string[];
        ac_emails:string[];
        fs_emails:string[];        
        portal_email:string|null;
        // following is same as DBLicense except no is_valid field
        license_key:string;
        //fs_order_ref:string;
        //user_id:string;  // 'NONE' if not known
        issue_datetime: string;   // 'NONE' if not known
        issue_datetime_msecs: number;
        expiry_datetime: string;  // 'NONE' if not known  or no expiry
        license_type : LicenseType;
        product_info: ProductInfo;
        mobile_upgrade_parent_key : string|null;
        subscription_status:SubscriptionStatus;
        as_is_free:boolean|null; // applies only to desktop license, null if not known
        as_user_id : string;  // applies only to desktop license, blank if not known
        as_sku:string; // applies only to desktop license, blank if not known
    } 
    export interface OutType {
        status : MsgStatus;
        //user_licenses_in_range : UserLicense[];
        user_licenses_in_range_compress_b64 : string;
        msgs:string[];
    }

    export const ERROR_OUT : OutType= {
        status : MsgStatus.ERROR,
        //user_licenses_in_range : UserLicense[];
        user_licenses_in_range_compress_b64 : '',
        msgs: [],
    }
}

// all admin roles
export namespace GetEditLicenseInfo {
    export const CMD='get-edit-license-info';
    export const ALLOWED_ROLES=[AdminRole.EDITOR,AdminRole.MANAGER,AdminRole.VIEWER];        
    export interface InType {
        session_token : string|null;
        license_key:string;
    }
    export const IN_INSTANCE : InType = {
        session_token:'',        
        license_key:'',
    }

    export enum DeviceType {
        mobile='mobile',
        desktop='desktop'
    }
    const DeviceTypeSchema = z.nativeEnum(DeviceType);

    const DeviceOfLicenseSchema = z.object({
        device_type : DeviceTypeSchema,
        activation_count:z.number(),        
    });
    export type DeviceOfLicense = z.infer<typeof DeviceOfLicenseSchema>;

    const LicenseTypeSchema = z.object( {
        includes_mobile:z.boolean(),
        includes_desktop:z.boolean(),
        includes_upgrade_to_mobile : z.boolean(),
        has_upgraded_to_mobile: z.boolean(),
        is_free_mobile:z.boolean(),
    });
    export type LicenseType = z.infer<typeof LicenseTypeSchema>;

    export const EditLicenseInfoSchema = z.object( {
        license_type : LicenseTypeSchema,
        max_mobile_devices_per_license:z.number(),
        devices_of_license : z.array(DeviceOfLicenseSchema),
        max_desktop_activations : z.number().nullable(),        
    });

    export type EditLicenseInfo = z.infer<typeof EditLicenseInfoSchema>;

    export interface OutType {
        status : MsgStatus;
        edit_license_info: EditLicenseInfo|null;
        msgs:string[];
    }        
}

// editor and manager only
export namespace ModifyLicenseInfo {
    export const CMD='modify-license-info';
    export const ALLOWED_ROLES=[AdminRole.EDITOR,AdminRole.MANAGER];

    export type EditLicenseInfo = GetEditLicenseInfo.EditLicenseInfo;
    
    export const InTypeSchema = z.object({
        session_token : z.string().nullable(),
        license_key:z.string(),
        orig_license_info: GetEditLicenseInfo.EditLicenseInfoSchema,
        new_license_info: GetEditLicenseInfo.EditLicenseInfoSchema,       
    });
    
    export type InType = z.infer<typeof InTypeSchema>;

    export interface OutType {
        status : MsgStatus;
        msgs:string[];
    }            
}

// only EDITOR or MANAGER roles
export namespace ResetDeviceActivationCount {
    export const CMD='reset-device-activation-count';
    export const ALLOWED_ROLES=[AdminRole.EDITOR,AdminRole.MANAGER];
    export type DeviceType = 'mobile' | 'desktop';
    export interface InType {
        session_token : string|null,                
        device_type:DeviceType
        license_key:string;
        new_activation_count:number;
    }
    export const IN_INSTANCE : InType = {
        session_token:'',        
        device_type:'mobile',
        license_key:'',
        new_activation_count:0
    }
    export interface OutType {
        status : MsgStatus;
    }        
}

// only EDITOR or MANAGER roles
export namespace ChangeMaxDeviceActivations {
    export const CMD='change-max-device-activations';
    export const ALLOWED_ROLES=[AdminRole.EDITOR,AdminRole.MANAGER];
    export type DeviceType = 'mobile' | 'desktop';
    export interface InType {
        session_token : string|null,                
        device_type:DeviceType
        license_key:string;
        new_max_activations:number;
    }
    export const IN_INSTANCE : InType = {
        session_token:'',        
        device_type:'mobile',
        license_key:'',
        new_max_activations:0
    }
    export interface OutType {
        status : MsgStatus;
    }        
}

// only EDITOR or MANAGER roles
export namespace ChangeMaxMobileDevicesPerLicense {
    export const CMD='change-max-mobile_devices-per-license';
    export const ALLOWED_ROLES=[AdminRole.EDITOR,AdminRole.MANAGER];
    export interface InType {
        session_token : string|null,                
        license_key:string;
        new_max_mobile_devices_per_license:number;
    }
    export const IN_INSTANCE : InType = {
        session_token:'',        
        license_key:'',
        new_max_mobile_devices_per_license:0         
    }
    export interface OutType {
        status : MsgStatus;
    }        
}

// MANAGER, EDITOR, VIEWER roles
export namespace GetMobUpgradeableLicenses {
    export const CMD='get-mobupgradeable-licenses';    
    export const ALLOWED_ROLES=[AdminRole.MANAGER, AdminRole.EDITOR,
                                AdminRole.VIEWER];
    export interface InType {
        session_token : string|null,
        min_range:number,   // for debugging             
        max_range:number|null, // for debugging        
    }

    export const IN_INSTANCE : InType = {
        session_token: '',
        min_range:0,
        max_range:null,        
    }

    export type SubscriptionStatus =
        'IS_SUBSCRIPTION' |
        'NOT_SUBSCRIPTION'|
        'UNKNOWN';
    
    export interface ParentLicenseInfo {
        sorting_email:string;
        as_emails:string[];
        ac_emails:string[];
        fs_emails:string[];
        // if user has portal acct then this is the current portal email,
        // not the original purchase email (null if user not on portal)
        portal_email : string|null,
        issue_date : string|null,
        issue_date_msecs : number,
        expiry_date : string|null,
        desktop_license_key : string,
        subscription_status:SubscriptionStatus,
        invite_sent_date: string|null,
        mobupgrade_purchase_date:string|null,
    }

    export interface OutType {
        status : MsgStatus,
        //parent_license_infos : ParentLicenseInfo[],
        parent_license_infos_compress_b64 : string,
        num_upgradeable_licenses:number,        
        error_msg:string,
    }

    export const ERROR_OUT : OutType = {
        status : MsgStatus.ERROR,
        //parent_license_infos : [],   // sorted by orig_email
        parent_license_infos_compress_b64 : '',
        num_upgradeable_licenses:0,
        error_msg:'',
    }
}

// MANAGER and EDITOR roles
export namespace SendMobUpgradeInvites {
    export const CMD='send-mobupgrade-invites';    
    export const ALLOWED_ROLES=[AdminRole.MANAGER, AdminRole.EDITOR];

    export const LicenseInfoSchema = z.object({
        current_email: z.string(),
        desktop_license_key:z.string(),
    });

    export type LicenseInfo = z.infer<typeof LicenseInfoSchema>;
    
    export const InTypeSchema = z.object( {
        session_token : z.string().nullable(),
        license_infos : z.array(LicenseInfoSchema),
        one_invite_per_email: z.boolean(),
        web_host_is_local:z.boolean(),
    });

    export type InType = z.infer<typeof InTypeSchema>;

    export interface OutType {
        status : MsgStatus,
        msg : string,
        num_invites_sent:number,
        num_license_key_emails_sent:number,
        num_send_errors:number,
        num_skipped_invite_already_sent:number,
    }

    export const ERROR_OUT : OutType = {
        status : MsgStatus.ERROR,
        msg : '',
        num_invites_sent : 0,
        num_license_key_emails_sent:0,
        num_send_errors:0,
        num_skipped_invite_already_sent:0,        
        
    }    
}

// MANAGER and EDITOR roles
// TODO: need to put conditions on web pages so that is not
// visible for user that has only VIEWER role
export namespace AddFreeMobileLicenses {
    export const CMD='add-free-mobile-licenses';    
    export const ALLOWED_ROLES=[AdminRole.MANAGER, AdminRole.EDITOR];

    export const INDEFINITE_LICENSE_DURATION=10000000000000000000;

    export enum AuthMethod {
        COMMON_ACT_CODE_NO_EMAIL='COMMON_ACT_CODE_NO_EMAIL',
        COMMON_ACT_CODE_SEND_EMAIL='COMMON_ACT_CODE_SEND_EMAIL',        
        SEND_INDIVIDUAL_ACT_CODE='SEND_INDIVIDUAL_ACT_CODE',
    }

    export const AuthMethodEnum = AuthMethod;
    
    const AuthMethodSchema = z.nativeEnum(AuthMethod);
    
    export const UserInfoSchema = z.object({
        email: z.string(),
        first_name: z.string(),
        last_name: z.string(),
    });

    export type UserInfo = z.infer<typeof UserInfoSchema>;
    
    export const InTypeSchema = z.object( {
        session_token : z.string().nullable(),
        user_infos: z.array(UserInfoSchema),
        // if true, overwrite existing license with the same email
        overwrite_existing:z.boolean(),
        // if null, no expiration
        license_duration_days : z.number().nullable(),
        act_code_expiry_hrs : z.number(),
        auth_method : AuthMethodSchema,
        max_devices : z.number(),
        web_host_is_local:z.boolean(),
    });

    export type InType = z.infer<typeof InTypeSchema>;

    export interface OutType {
        status : MsgStatus,
        msg : string,        
        // null if auth_method == AuthMethod.SEND_INDIVIDUAL_ACT_CODE
        common_activation_code: string|null,
    }        
}

// MANAGER, EDITOR, VIEWER roles
export namespace GetFreeMobileLicenses {
    export const CMD='get-free-mobile-licenses';    
    export const ALLOWED_ROLES=[AdminRole.MANAGER, AdminRole.EDITOR,
                                AdminRole.VIEWER];

    export interface InType {
        session_token : string|null
    }

    export interface UserInfo {
        email: string,
        first_name: string,
        last_name: string,
    }

    export interface LicenseInfo {
        user_info : UserInfo,
        license_key : string,
        issue_datetime : string,
        issue_datetime_msecs : number,        
        expiry_datetime : string,
        portal_acct_exists:boolean,
    }

    export interface OutType {
        status : MsgStatus,
        license_infos : LicenseInfo[],
    }        
}
  
export namespace SendECommInvites {
    
    export const ALLOWED_ROLES=[AdminRole.MANAGER, AdminRole.EDITOR ];
    
    export const CMD='send-ecomm-invites';
    
    export const UserInfoSchema = z.object({
        email: z.string(),
        first_name: z.string(),
        last_name: z.string(),
    });

    export type UserInfo = z.infer<typeof UserInfoSchema>;

    export const InTypeSchema = z.object({
        session_token : z.string().nullable(),
        invite_type : ECommNonMobupgradeInviteTypeSchema,        
        user_infos : z.array(UserInfoSchema),
        // if true, overwrite existing license with the same email
        overwrite_existing:z.boolean(),        
        web_host_is_local:z.boolean(),
        // if null, no expiration        
        license_duration_days : z.number().nullable(),
        max_devices : z.number(),        
    });
    export type InType = z.infer<typeof InTypeSchema>;

    export type OutType = {
        status : MsgStatus,        
    }
    export const ERROR_OUT : OutType = {
        status : MsgStatus.ERROR
    }
}


