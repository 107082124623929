// File: mobile_upgradeable_licenses_table.tsx
//



import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetUserLicensesInfo} from '../../extlinks/portal_comm_types';
import { TABLE_HEADER_BG_COLOR,TABLE_HEADER_FONT_COLOR } from './utils_config';

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;


function get_email_divs(emails : string[]) : React.ReactElement {
    
    let cnt = 0;
    
    return  (<>
	{
	    emails.map( (email) => {
		return (<div key={String(cnt++)}>{email}</div>)
	    })
	}
          </>
    )
}


export function
MobileUpgradeableLicensesTable(props : { license_infos :  LicenseInfo[] }): React.ReactElement  {

    return (
   <TableContainer component={Paper} style={{ borderRadius:'5px',  }} 
		   sx={{ pb:1}} >
      <Table sx={{ borderTop: '1px solid #E0E0E0' }} >
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>

	  
          <TableRow>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Email</TableCell>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Issued</TableCell>	    	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">License Key</TableCell>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Already Upgraded to Mobile</TableCell>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Expired</TableCell>	    
          </TableRow>
        </TableHead>
	
        <TableBody>
	  
          {props.license_infos.map((row) => {
	      
	      if( ! row.license_type.includes_upgrade_to_mobile) {
		  return null;
	      }
	      
	      const issue_date = row.issue_date == null ? 'Unknown' :
		  (new Date(row.issue_date)).toLocaleDateString();
	      
	      const is_expired = row.expiry_date != null && 
		  (new Date(row.expiry_date)).getTime() < Date.now();
	      const expired = is_expired ? 'YES' : 'NO';
	      const upgraded = row.license_type.has_upgraded_to_mobile ? 'YES' : 'NO';
	      
	      return (

            <TableRow
              key={'LC-'+row.license_key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell size="small" align="left">{get_email_divs(row.emails_of_license)}</TableCell>	      	      	      
              <TableCell size="small" align="left">{issue_date}</TableCell>	      	      
              <TableCell size="small" align="left">{row.license_key}</TableCell>
              <TableCell size="small" align="left">{upgraded}</TableCell>
              <TableCell size="small" align="left">{expired}</TableCell>	      	      
            </TableRow>
	      )
	      
          })}
	  
        </TableBody>
	
        </Table>
    </TableContainer>);

}


