// File: auth_provider.tsx
//

import React from 'react';
import { EComm, MsgStatus } from '../extlinks/portal_comm_types';
import { get_ecomm_session_token, store_ecomm_session_token } from '../pages_ecomm/utils/ecomm_session_tokens';
import { getECommServerComm } from '../pages_ecomm/utils/ecomm_server_comm';
import { ECOMM_TESTING } from '../config/config';

const TAG='ecomm_auth_provider.tsx: ';
const DEBUG=true

export interface ECommAuthContextState {
    session_token: string|null;
    refresh: ()=>void;
}

export const ECommAuthContext : React.Context<ECommAuthContextState>
         = React.createContext<ECommAuthContextState>({ session_token : null, refresh:()=>{} });

export const useECommAuthStatus = () : ECommAuthContextState => {
    return React.useContext<ECommAuthContextState>(ECommAuthContext);
}

async function refresh_token(args:{ session_token:string, reset_session_token:(token:string)=>void}) : Promise<boolean> {
    if(DEBUG) console.log(TAG+'entering refresh_token');
    const server = getECommServerComm();	    
    const result =
	await server.handle_refresh_session_token({session_token : args.session_token});
    
    if(result.status === MsgStatus.ERROR ||  result.new_session_token==null) {
	return false;
    }

    args.reset_session_token(result.new_session_token);
    store_ecomm_session_token({session_token : result.new_session_token});
    
    return true;
}

// return true iff current token is OK
async function maintain_token(args:{session_token:string,
                                    reset_session_token:(token:string)=>void}) : Promise<boolean> {
					
    if(DEBUG) console.log(TAG+'entering maintain_token');    
    try {
	const server = getECommServerComm();	    
	const result =
	    await server.handle_req_session_token_is_ok({session_token : args.session_token});
	
	if(result.session_token_is_ok) {

	    /*!!!!!!!!!!!!
	    const status =
		await refresh_token({session_token:args.session_token,
		                     reset_session_token:args.reset_session_token});
	    
	    return status;
	    */

	    return true;
	    
	} else {
	    
	    console.error(TAG+'PT0: session token is bad');
	    return false;
	}
    } catch(error) {
	console.error(TAG+'maintain_token: error=' + error);
	return false;	
    }
}

const MSECS_SESSION_TOKEN_EXPIRES=EComm.ReqECommSessionToken.HRS_BEFORE_SESSION_TOKEN_EXPIRES*3600*1000;

export function ECommAuthProvider(props : { children : React.ReactElement | React.ReactElement[]}) : React.ReactElement {
    
    //const [sessionToken, setSessionToken] = React.useState<string|null>(null);
    const [sessionToken,setSessionToken] = React.useState<string|null>(null);

    const refresh= ():void => {
	if(DEBUG) console.log(TAG+'entering refresh');
	const session_token = get_ecomm_session_token()
	if(session_token==null) {
	    if(DEBUG) console.log(TAG+'refresh: PT1: session_token=null');	    
	    return;
	}

	setSessionToken(session_token);	

	if(DEBUG) console.log(TAG+'refresh: PT2: session_token='+session_token);	    
	
	if(ECOMM_TESTING) return; //!!!!!!!!

	const reset_session_token = (token:string) : void=> {
	    store_ecomm_session_token({session_token:token});
	    setSessionToken(token);
	}
	
        maintain_token({session_token:session_token, reset_session_token}).then(
	    (result)=> {
		if(! result) {
		    console.error(TAG+'maintain_token: server returned result==false: session token is bad');
		    return;
		}
	    }
	).catch(
	    (error)=> {
		console.error(TAG+'maintain_token: error=' + error);
		return;
	});	
    }
    
    React.useEffect( ()=> {
	refresh();
    },[]);

    setInterval(()=>{
	refresh();
    }, MSECS_SESSION_TOKEN_EXPIRES);


    return (
        <ECommAuthContext.Provider value={{ session_token: sessionToken, refresh:refresh }}>
            {props.children}
        </ECommAuthContext.Provider>
    )
}

