// File: logger.ts
//

import { getAuth} from 'firebase/auth';
import { RelayBrowserLogging,
         RelayBrowserRegLogging } from '../extlinks/portal_comm_types';
import { getServerComm } from '../pages_pub/utils/server_comm';
import fnv from 'fnv-plus'
import { get_reg_logger_session_token } from './reg_logger_session_token';

const TAG='logger.ts: ';
const SERVER_COMM = getServerComm();

export function get_session_token() : string | null {
    const auth = getAuth();
    const user = auth.currentUser;
    if(user &&  user.email && user.refreshToken) {
        return fnv.hash(user.refreshToken).hex();
    }
    return null;
}

class Logger {

    private relay(severity:string, txt:string,
                  event_type:string,
                  obj?:object|null) : void {
        const auth = getAuth();
        const user = auth.currentUser;
        const session_token = get_session_token();
        if(user &&  user.email && session_token) {
            const relay_obj : RelayBrowserLogging.InType = {
                event_type,
                time: (new Date()).toISOString(),
                email: user.email,
                user_id: user.uid,
                session_token,
                severity,
                msg : txt,
                obj : obj===undefined || obj==null ? {}: obj, 
            }

            SERVER_COMM.handle_relay_browser_logging(relay_obj).then(()=>{}).catch(()=>{});
        }         
    }

    // this will go to USER_PORTAL_EVENTS logger
    log_event(event_type: string,
              txt:string,
              obj?:object|null) : void {
        this.relay('INFO',txt, event_type, obj)
    }

    // this will go to USER_PORTAL_EVENTS logger    
    log_event_error(event_type: string,
                    txt:string,
                    obj?:object|null) : void {
        this.relay('ERROR',txt, event_type, obj);
    }

    // all of following will go to USER_PORTAL logger
    
    info(debug:boolean, txt:string, obj?:object|null) : void {
        if(debug) {
            if(obj && Object.keys(obj).length>0) console.info(txt,obj);
            else                                 console.info(txt);
        }
        const event_type = '';
        this.relay('info',txt, event_type, obj);
    }
    warn(txt:string, obj?:object|null) : void {
        if(obj && Object.keys(obj).length>0) console.warn(txt,obj);
        else                                 console.warn(txt);                
        const event_type = '';        
        this.relay('warn',txt,event_type,obj);        
    }
    error(txt:string, obj?:object|null) : void {
        if(obj && Object.keys(obj).length>0) console.error(txt,obj);
        else                                 console.error(txt);                
        const event_type = '';        
        this.relay('error',txt, event_type,obj);        
    }        
}


class RegLogger {
/*
    private async relay_impl(severity:string, txt:string,
                             event_type:string,
                             obj?:object|null) : Promise<void> {
                             */
    private relay(severity:string, txt:string,
                  event_type:string,
                  obj?:object|null) : void {
        try {
            const session_token = get_reg_logger_session_token();
            const relay_obj : RelayBrowserRegLogging.InType = {
                event_type,
                session_token,            
                time: (new Date()).toISOString(),
                severity,
                msg : txt,
                obj : obj===undefined || obj==null ? {}: obj, 
            }
            
            SERVER_COMM.handle_relay_browser_reg_logging(relay_obj).then( ()=>{} ).
                catch( (error)=>{});
        } catch(ex) {
        }
    }

    /*
    private relay(severity:string, txt:string,
                  event_type:string,
                  obj?:object|null) : void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.relay_impl(severity,
                        txt,
                        event_type,
                        obj);
    }*/

    // this will go to USER_PORTAL_EVENTS logger
    log_event(event_type: string,
              txt:string,
              obj?:object|null) : void {
        this.relay('INFO',txt, event_type, obj)
    }

    // this will go to USER_PORTAL_EVENTS logger    
    log_event_error(event_type: string,
                    txt:string,
                    obj?:object|null) : void {
        this.relay('ERROR',txt, event_type, obj);
    }

    // all of following will go to USER_PORTAL logger
    
    info(txt:string, obj?:object|null) : void {
        if(obj && (typeof obj === 'string' ||
            Object.keys(obj).length>0)) console.info(txt,obj);
        else                            console.info(txt);
        const event_type = '';
        this.relay('info',txt, event_type, obj);
    }
    log(txt:string, obj?:object|null) : void {
        if(obj && (typeof obj === 'string' ||
            Object.keys(obj).length>0)) console.info(txt,obj);
        else                            console.info(txt);        
        const event_type = '';        
        this.relay('info',txt, event_type, obj);        
    }    
    warn(txt:string, obj?:object|null) : void {
        if(obj && (typeof obj === 'string' ||
            Object.keys(obj).length>0)) console.warn(txt,obj);
        else                            console.warn(txt);                
        const event_type = '';        
        this.relay('warn',txt,event_type,obj);        
    }
    error(txt:string, obj?:object|null) : void {
        if(obj && (typeof obj === 'string' ||
            Object.keys(obj).length>0)) console.error(txt,obj);
        else                            console.error(txt);                
        const event_type = '';        
        this.relay('error',txt, event_type,obj);        
    }        
}

export const LOGGER = new Logger();
export const REG_LOGGER = new RegLogger();
