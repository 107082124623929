// File: mobile_upgrade.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import {useNavigate} from 'react-router-dom';
import { MainLayout } from '../../utils/main_layout';
//import {  SideBarType } from '../../utils/side_bar';
//import { getServerComm } from '../utils/server_comm';
import { AlertDialog } from '../../../utils_common/alert_dialog';
import { useMainPagesContext } from '../../utils/pub_main_pages_provider';
import { get_default_license_key, MobileUpgradeableLicensesList } from '../../utils/upgradeable_licenses_list';
import { GetUserLicensesInfo } from '../../../extlinks/portal_comm_types';
import { LOGGER } from '../../../utils_common/logger';
import { MOBILE_UPGRADE_USES_POPUP } from '../../../config/config';
import { MOBILE_UPGRADE_PRICE_USD } from '../../../extlinks/portal_comm_types';
import { UseMobUpgradeRoutes } from '../../utils/use_mobupgrade_routes';

const TAG='mobile_upgrade.tsx: ';
const DEBUG=false;

export type MobileUpgradeState = {
    is_mobile_upgrade_state : boolean,
    parent_desktop_license_key : string
}

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;

const LARGE_FONT='h6';
//const FONT='h6';
const FONT='body1';
//const COLOR="textSecondary";

function
get_upgradeable_license_infos(license_infos:LicenseInfo[]) : LicenseInfo[] {
    
    return license_infos.filter( (x)=> {
	if(x.expiry_date != null && 
	    (new Date(x.expiry_date)).getTime() < Date.now()) return false;
	return  !x.license_type.has_upgraded_to_mobile &&
	    x.license_type.includes_upgrade_to_mobile;
    });
}

function
get_initially_upgradeable_license_infos(license_infos:LicenseInfo[]) : LicenseInfo[] {
    return license_infos.filter( (x)=> {
	if(x.expiry_date != null && 
	    (new Date(x.expiry_date)).getTime() < Date.now()) return false;
	return x.license_type.includes_upgrade_to_mobile;
    });
}

function DisplayUpgradeableLicenses(props : {upgradeable_license_infos : LicenseInfo[],
     onChange: (ev:any, key:string)=>void } ):React.ReactElement {
    return (
	<>
	{ props.upgradeable_license_infos.length>1 && 
	  (<Box sx={{mt:2}}>
	      
    	  <Typography align='center' variant={FONT}>
         Select one of your licenses that you wish to apply the upgrade.
          </Typography>
	      
	  <Box sx={{height:'1em'}}/>

          <MobileUpgradeableLicensesList upgradeable_license_infos={ props.upgradeable_license_infos }
					 onChange={props.onChange} />


    	  <Typography align='center' variant={FONT} sx={{mt:3}}>
         Then, press the button to get further purchase instructions.
	    </Typography>
	    
	  </Box>)
	}
	</>	
    )
	    
}

function Congrats(props : { upgradeable_license_infos : LicenseInfo[],
    submit_fun : ()=>void,    
    onChange : (ev:any, key:string)=>void })  :React.ReactElement {

   return  (
	  
          <Box
              sx={{
		   borderRadius: '10px',
		  
		  ml:2,mr:2,pr:3, pl:3,
		  display: 'flex',
		  flexDirection: 'column',
		  alignItems: 'center',
              }}
          >

    	  <Typography align='center'
		      variant={LARGE_FONT}>
          Congratulations!
          </Typography>

	  <div style={{height:"1em"}}/>
	  
    	  <Typography align='center' variant={FONT}>
As a purchaser of the Discovery Bible desktop-only version, you are eligible to purchase
the mobile version at the price of <b>${MOBILE_UPGRADE_PRICE_USD} US dollars</b>.
          </Typography>
    	  <Typography align='center' variant={FONT}>
(If you currently are on a subscription installment plan, you will receive upon final payment a license
that does not expire.)	    
          </Typography>

          <DisplayUpgradeableLicenses
	      upgradeable_license_infos={props.upgradeable_license_infos}
              onChange={props.onChange}/>
	  
	  <Box textAlign='center'>

          <Button
	    size="small"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 0 }}
	      onClick={()=>props.submit_fun()}
            >
            PURCHASE INSTRUCTIONS
          </Button>
	  </Box>
	  

	  <Box sx={{mt:4}}>
	      You can also try out the free &nbsp;
	    <Link to={'/mobile-upgrade/mobile-lite-version-links'}>
      <span  style={{fontWeight:'bold'}}>Lite Version</span>
	    </Link>
	    &nbsp; of the mobile app.
	  </Box>	    
</Box>);
}

function MainContent(props : { upgradeable_license_infos : LicenseInfo[]}) :React.ReactElement {

    const [ msg, setMsg ] = React.useState<string>('');

    const [ selKey, setSelKey ] =  React.useState<string|null>(null);
    
    const onChange = React.useCallback( (_ev:any, key:string|null):void => {
	setSelKey(key);
    },[]);

    React.useEffect( ()=> {
	if(props.upgradeable_license_infos.length===1) {
	    setSelKey(get_default_license_key(props.upgradeable_license_infos));
	}
    },[props.upgradeable_license_infos])
    
    const navigate = useNavigate();

    const submit_fun = ():void => {

	if(selKey == null) {

	    setMsg('Error, no license key was selected.');
	    
	} else {

	    LOGGER.log_event('MOBILE_UPGRADE_LICENSE_SELECTED',
		TAG+'selected license: ' + selKey);

	    const mobile_upgrade_state : MobileUpgradeState =
		 { is_mobile_upgrade_state:true,
		     parent_desktop_license_key: selKey }  ;

	    if(MOBILE_UPGRADE_USES_POPUP) {
		navigate('/mobile-upgrade/mobile-upgrade-launch-popup', {state : mobile_upgrade_state});
	    } else {
		navigate('/mobile-upgrade/mobile-upgrade-direct-to-link', {state : mobile_upgrade_state});
	    }
	    
	}
    }

    const dialog_is_open = () :boolean=> {
       return msg !== '';
    }

    const close_dialog = () : void => {
	setMsg('');
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,

	title : msg,
	text : '',
	choice1 : 'OK',
	choice2 : ''
    }
    
    return (<>
      
      <Congrats upgradeable_license_infos={ props.upgradeable_license_infos}
		submit_fun={submit_fun}
		onChange={onChange}/>

    <AlertDialog {...dialog_props} />
</>	
    );		  	        
}

function NoUpgradeAvail(props : {license_infos:LicenseInfo[]}) : React.ReactElement {
    
    const initially_upgradeable_licenses = get_initially_upgradeable_license_infos(props.license_infos);

    if(initially_upgradeable_licenses.length===0) {
	return (
	    <>
    	      <Typography align='center' variant={FONT}>
Sorry, you have no licenses that are eligible for mobile  upgrade.
              </Typography>
	    </>
	);
    } else {
	return (
	    <>
    	      <Typography align='center' variant={FONT}>
        All licenses that are eligible for mobile  upgrade have been used or expired.
              </Typography>
	    </>
	);	
    }
}

export function MobileUpgrade() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate('/home');
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_MOBILE_UPGRADE_PAGE','mobile_upgrade.tsx');
    }
    
    const [start, setStart] = React.useState<boolean>(false);
    React.useEffect( ()=>{ setStart(true); });

    const main_pages_state = useMainPagesContext();    
    
    //const [ licenseInfos, setLicenseInfos ] = React.useState<LicenseInfo[]>([]);

    /*
    React.useEffect( ()=>{
	//main_pages_state.refresh_user_licenses_info();
	const user_licenses_info =  main_pages_state.user_licenses_info;
	if(user_licenses_info) {
	    setLicenseInfos(user_licenses_info.license_infos);
	}
    },[]);
*/

    const user_licenses_info =  main_pages_state.user_licenses_info;    
    const licenseInfos = user_licenses_info ?
	user_licenses_info.license_infos : [];
    const isLoading = main_pages_state.is_loading;

    const upgradeable_license_infos =
	get_upgradeable_license_infos(licenseInfos);


    React.useEffect( ()=>{
	UseMobUpgradeRoutes.clear();
    },[]);
    
    
    return (

  <MainLayout title='Mobile App Upgrade Offer'>

      <Backdrop
	  invisible={true} open={isLoading}
	  sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>

      <>
	
       {
	   (start && ! isLoading) && 
	   ( upgradeable_license_infos.length===0
               ? (<NoUpgradeAvail license_infos={licenseInfos}/>) 
	       : (<MainContent upgradeable_license_infos={upgradeable_license_infos==null ? [] : upgradeable_license_infos}/>))
       }


	<>{ (start && ! isLoading) &&
	    
	    ( <Box sx={{mt:3}}>
	  
    	        <Box textAlign='center' >
		  <Typography variant='h6'>
		  See&nbsp;
	           <Link to='/mobile-upgrade/licenses-status'>status of your desktop licenses</Link>
 &nbsp;for mobile upgrades.
		  </Typography>		
	        </Box>
              </Box>		
	    )
	    
	}</>
	
	  <div style={{height:"1em"}}/>
      </>	  
  </MainLayout>
	 
  );
}
