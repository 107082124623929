// File: add_free_mobile_licenses_from_file.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { get_session_token, LOGGER } from '../../../utils_common/logger';
import { getAdminServerComm } from '../admin_server_comm';
import { MsgStatus } from '../../../extlinks/portal_comm_types';
import Alert from '@mui/material/Alert';
//import Checkbox from '@mui/material/Checkbox';
import { INDEFINITE_LICENSE_DURATION, DEFAULT_EXPIRY_DURATION } from './select_license_expiration';
//import {Spinner} from '../../utils_common/spinner'
import { AddFreeMobileLicenses } from '../../../extlinks/portal_comm_types';
//import { DEFAULT_MAX_DEVICES_PER_FREE_LICENSE, FreeMobileLicenseSettings } from './free_mobile_license_settings';
import {FreeMobileLicenseSettings,DEFAULT_MAX_DEVICES_PER_FREE_LICENSE } from './free_mobile_license_settings';
import { get_on_local_host } from '../../../config/firebase_config';
import { DEFAULT_ACT_CODE_EXPIRY_HRS } from './free_mobile_act_code_expiry_menu';

const TAG='free_mobile_licenses.tsx: ';
//const DEBUG=false;

type AuthMethod = AddFreeMobileLicenses.AuthMethod;
type UserInfo = AddFreeMobileLicenses.UserInfo;

export function
AddFreeMobileLicensesFromCSVFile(props : { setSpinnerOn: ((on:boolean)=>void) }) : React.ReactElement {

    const [ errorMsg, setErrorMsg ] = React.useState<string>('');
    const [ successMsg, setSuccessMsg ] = React.useState<string>('');
    const [ expiryDuration, setExpiryDuration] = React.useState<number>(DEFAULT_EXPIRY_DURATION);
    const [ actCodeExpiryDurationHrs, setActCodeExpiryDurationHrs] =
	React.useState<number>(DEFAULT_ACT_CODE_EXPIRY_HRS);
    const [ authMethod, setAuthMethod ] = React.useState<AuthMethod|null>(null);
    const [ oneLicensePerEmail, setOneLicensePerEmail] = React.useState<boolean>(true);
    const [ maxDevices, setMaxDevices ] = React.useState<number>(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);
    
    const convert_data_to_user_infos =
	(file_data:string) : UserInfo[] | null => {
	    
	    setErrorMsg('');
	    setSuccessMsg('');		
	    const lines = file_data.split('\n');
	    let out : UserInfo[] = [];
	    let i = 0;
	    for(let x of lines) {
		if(x.trim() !== '') {
		    const ws = x.split(',');
		    if(ws[0].trim()==='') {
			setErrorMsg('error in line: ' + (i+1));
			return null;
		    }
		    let w : [string,string,string,string] = ['','','',''];
		    for(let k=0; k<Math.min(ws.length,4); k++) {
			w[k] = ws[k].trim();
		    }

		    
		    const user_info : UserInfo = {
			email : w[0],
			first_name:w[1],
			last_name:w[2],
		    }
		    out.push(user_info);
		}
		i+=1;
	    }
	    
	    return out;
	}
    
    const send_to_server = (file_data:string) : void => {

	//const x = b64_compress('aaabbccccc');
	//console.log('decompress_b64(x)='+decompress_b64(x));
	//console.log('send_to_server: file_data=' + file_data);

	setErrorMsg('');
	setSuccessMsg('');

	if(maxDevices==null) {
	    console.log('maxDevices==null');
	    setErrorMsg("'Max number of devices allowed' not set");
	    return;
	}	

	if(authMethod == null) {
	    console.error(TAG+'send_to_server: authMethod == null');
	    return;
	}

	const user_infos = convert_data_to_user_infos(file_data);
	if(user_infos==null) return;

	props.setSpinnerOn(true);
		
	if(user_infos.length>0) {

	    const session_token = get_session_token();
	    const server = getAdminServerComm();
	    const overwrite_existing = ! oneLicensePerEmail;

	    const act_code_expiry_hrs :number = actCodeExpiryDurationHrs;
	    const web_host_is_local = get_on_local_host();	    

	    server.handle_add_free_mobile_licenses({
		session_token,
		user_infos,
		overwrite_existing,
		auth_method : authMethod,
		license_duration_days: expiryDuration>=  INDEFINITE_LICENSE_DURATION ? null: expiryDuration,
		max_devices:maxDevices,
		act_code_expiry_hrs,
		web_host_is_local
	    }).then( (x)=> {
		props.setSpinnerOn(false);
		if(x.status===MsgStatus.OK) {
		    if(x.msg.trim() !== '') { 
			setSuccessMsg('Success Upload: ' + x.msg);
		    } else {
			setSuccessMsg('Success Upload');
		    }		    
		    setTimeout( ()=>setSuccessMsg(''), 5000);
		    return;
		} else {
		    LOGGER.error(TAG+'submit: server error');
		    if(x.msg.trim() !== '') {
			setErrorMsg('Server Error: ' + x.msg);
		    } else {
			setErrorMsg('Server Error');
		    }		    
		    return;
		}
	    }).catch( (error)=> {
		props.setSpinnerOn(false);		
		LOGGER.error(TAG+'submit: error=' + error);
		setErrorMsg('Server Error.');		
	    });
	} else {
	    props.setSpinnerOn(false);		
	}
    }

    const onInputChange = ():void => {

	console.log('entering onInputChange');

	setErrorMsg('');	
	setSuccessMsg('');

	if(maxDevices==null) {
	    console.log('maxDevices==null');
	    setErrorMsg("'Max number of devices allowed' not set");
	    return;
	}	
	
	const xinput = document.getElementById("file-input");
	if(!xinput) {
	    console.error('xinput==null');
	    return;
	}
	
	const e = xinput as HTMLInputElement;
	console.log('e.value=' + e.value);
	if(e.files == null) {
	    console.error('files==null');
	    return;
	}
	const files = e.files;
	if(files.length===0) {
	    console.log('files.length===0');
	    return;
	}
	console.log('e.files[0].name=' + e.files[0].name);
	console.log('e.files[0].size=' + e.files[0].size);
	
	const reader = new FileReader();

	reader.addEventListener('loadstart', (x)=>{
	    props.setSpinnerOn(true);	    
	}, {once:true});
	
	reader.addEventListener('loadend', (x)=>{

	    props.setSpinnerOn(false);	    	    
	    
	    if(reader.error == null) {

		send_to_server(reader.result as string);
		
	    } else {
		console.log('reader.error=',reader.error);		    
	    }
	},
	    {once:true});
	
	reader.readAsText(files[0],'utf8');	    
    }

    const onClick = ():void => {

	console.log('entering onClick');

	setErrorMsg('');	
	setSuccessMsg('');

	if(maxDevices==null) {
	    console.log('maxDevices==null');
	    setErrorMsg("'Max number of devices allowed' not set");
	    return;
	}

	if(authMethod == null) {
	    setErrorMsg("Activation method has not been set");
	    return;
	}
    }

    return (
	<>

	 <Box style={{display:'flex',
	     flexDirection:'column',
	     alignItems:"center"
	 }}>

	   <Typography  variant="h5" style={{marginBottom:'0.5em'}} >
	     Upload from a CSV file
	   </Typography>

	   <Typography style={{maxWidth:'30em', marginBottom:'0.5em'}} >
	    Format: <i>Email,FirstName,LastName</i>
	   </Typography>
	 
	   <Typography style={{maxWidth:'30em',  marginBottom:'0.5em'}}>	    
             The fields <i>FirstName,LastName</i> are optional, and they can
             be blank.  If both are blank, commas can be omitted.
	   </Typography>
	   
	   <Typography style={{maxWidth:'30em',  marginBottom:'0.5em'}}>	    
	     There must not be any header line present in the file.
	   </Typography>	   

	   <Box style={{height:"1em"}}/>

	   <FreeMobileLicenseSettings
	       maxDevices={maxDevices}
	       setMaxDevices={setMaxDevices}	       	       
	       oneLicensePerEmail={oneLicensePerEmail}
	       setOneLicensePerEmail={setOneLicensePerEmail}	       
	       setAuthMethod={setAuthMethod}
	       setExpiryDuration={setExpiryDuration}
	       setActCodeExpiryDurationHrs={setActCodeExpiryDurationHrs}
	   />
	   
	   <Box style={{height:"1em"}}/>	   
	   
	   <input id="file-input" onChange={()=>{onInputChange()}}
		  disabled={maxDevices==null || authMethod===null }
		  accept=".csv"
		  type="file" style={{display:"none"}} />
	   <label htmlFor="file-input">
	     <Button  variant="contained" component="span" onClick={onClick}>
	       BROWSE FOR FILE
             </Button>
           </label>

	 { errorMsg.trim().length>0 &&
	     (<Alert  sx={{boxShadow:4, mt:2, position:'fixed', top:'50%'}} severity="error"
		      onClose={()=>setErrorMsg('')}>{errorMsg}</Alert>)
	 }
	 
	 { successMsg.trim().length>0 &&
	     (<Alert  sx={{boxShadow:4, mt:2, position:'fixed', top:'50%'}} severity="success"
		      onClose={()=>setSuccessMsg('')}>{successMsg}</Alert>)
	 }
	   
	 </Box>

	 
	</>
    )
}
