// File: free_mobile_license_settings.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
//import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { AddFreeMobileLicenses } from '../../../extlinks/portal_comm_types';
//import { SelectLicenseExpiration } from './select_license_expiration';
import { SetFreeMobileLicenseAuthMethod } from './set_free_mobile_license_auth_method';
import { FreeMobileExpirationListMenu } from './free_mobile_expiration_menu';
import { FreeMobileActCodeExpiryMenu } from './free_mobile_act_code_expiry_menu';

type AuthMethod = AddFreeMobileLicenses.AuthMethod;

export const DEFAULT_MAX_DEVICES_PER_FREE_LICENSE=1;

function
SetOneLicensePerEmail(props: { oneLicensePerEmail : boolean,
                               setOneLicensePerEmail : (value:boolean)=>void }) : React.ReactElement {
					   
    const onChange = (evt:React.ChangeEvent<HTMLInputElement>):void=>{
         props.setOneLicensePerEmail(evt.target.checked);	    
    }	
	
    return (
	
     <Box sx={{ml:0,pl:0,pr:1}}>

       <FormControlLabel sx={{ml:0,mr:1,pr:0}}
			 control={<Checkbox style={{margin:"0", paddingLeft:'0'}}
					    checked={props.oneLicensePerEmail}
					    onChange={onChange}/>}
			 labelPlacement='start'
   			       label={<Typography variant='h6' style={{margin:'0', paddingLeft:'0'}}>
			       If email already has a free mobile license, don&apos;t create another one.</Typography>}
       />
	
   </Box>)
	
}

function SetMaxDevices(props: { maxDevices:number,
    setMaxDevices : (ndevices:number)=>void }) : React.ReactElement {

	const onChange = (evt:React.ChangeEvent<HTMLInputElement>) : void => {
	    //if( evt.target === undefined ||  evt.target.value ===  undefined  || evt.target.value ===  null
	    if(evt.target.value.trim()==='') {
		props.setMaxDevices(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);
	    } else {
		evt.target.value = evt.target.value.split('.')[0];
		let n = Number(evt.target.value)
		if(Number.isNaN(n)) {
		    evt.target.value = String(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);
		    props.setMaxDevices(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);
		    return;
		} else {
		    if(n<=0) {
			evt.target.value = String(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);
			props.setMaxDevices(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);			
			return;
		    } else {
			props.setMaxDevices(n);
		    }
		}
	    }	    
	}
	
	return (
        <Box style={{display:'flex',flexDirection:'row',justifyContent:'space-between',
	    alignItems:'center',}}>

          <Typography variant='h6' style={{}}>
	     Select max number of devices allowed per license
          </Typography>
	  
	  <OutlinedInput
	      inputProps={{ type: 'tel', min:1}} 
	      value={props.maxDevices == null ? '': props.maxDevices}
	      style={{width:"6em"}}
	      size="small"
	      onChange={onChange}
	  />

	</Box>	   	    
	)
    }

export function
FreeMobileLicenseSettings(props : {
    oneLicensePerEmail:boolean,
    maxDevices:number,
    setOneLicensePerEmail:(value:boolean)=>void,
    setExpiryDuration:(expiry_duration:number)=>void,
    setActCodeExpiryDurationHrs:(act_code_expiry_duration:number)=>void,    
    setAuthMethod:(auth_method:AuthMethod|null)=>void,
    setMaxDevices : (ndevices:number)=>void}) :React.ReactElement {
	
    return (
	
    <Box>
	
	<Box sx={{ml:2,mt:1}} style={{ display:'flex', flexDirection:'row',justifyContent:'center' }}>
	
	  <Box sx={{p:2,mr:1,ml:0}}
	      style={{width:'50%', borderRadius:'10px',border:'solid 1px grey'}}>

  	     <FreeMobileExpirationListMenu setExpiryDuration={props.setExpiryDuration}/>

             <Box style={{height:'1em', width:'100%'}} />
	
  	     <SetMaxDevices maxDevices={props.maxDevices}
	 		    setMaxDevices={props.setMaxDevices}/>

	     <Box sx={{mt:2}}/>

	     <FreeMobileActCodeExpiryMenu setActCodeExpiryDuration={props.setActCodeExpiryDurationHrs}/>
	     
             <Box style={{height:'1em', width:'100%'}} />	     

             <SetOneLicensePerEmail oneLicensePerEmail={props.oneLicensePerEmail}
	                            setOneLicensePerEmail={props.setOneLicensePerEmail} />	
  	  </Box>
	
	  <Box sx={{p:2,ml:1}}
	       style={{width:'50%', borderRadius:'10px',border:'solid 1px grey'}}>
	
	    <SetFreeMobileLicenseAuthMethod setAuthMethod={props.setAuthMethod}/>
	  
	  </Box>

  	</Box>
	  
	<Box style={{width:'100%', height:'1em'}}/>

        <Box>

	</Box>	   
	  
   </Box>	
    )
	
    }
