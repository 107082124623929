// File: password_field.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Typography from '@mui/material/Typography';

interface State {
    showPassword: boolean;
}

interface PasswordFieldProps {
    id:string,
    label:string,
    adornment:string,
    onChange : (e : React.ChangeEvent<HTMLInputElement>)=>void,
    fullWidth:boolean,
    password:string,
    setPassword:(pw:string)=>void,
}

export function PasswordField( props : PasswordFieldProps) : React.ReactElement {
    
    const [values, setValues] = React.useState<State>({
	showPassword: false,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
	props.setPassword(event.target.value);
	props.onChange(event);
    };

    const handleClickShowPassword = () :void => {
	setValues({
	    ...values,
	    showPassword: !values.showPassword,
	});
    };

    const handleMouseDownPassword =
	(event: React.MouseEvent<HTMLButtonElement>):void => {
	    event.preventDefault();
	};

    return (

        <FormControl fullWidth={props.fullWidth}
	 variant="outlined">
	  	  
          <InputLabel htmlFor="outlined-adornment-password">{props.adornment}</InputLabel>
          <OutlinedInput
              id={props.id}
              type={values.showPassword ? 'text' : 'password'}
              value={props.password}
              onChange={handleChange}
	      name='password'
              endAdornment={
		  <InputAdornment position="end">
                    <IconButton
			aria-label="toggle password visibility"
			onClick={()=>handleClickShowPassword()}
			onMouseDown={handleMouseDownPassword}
			edge="end"
                    >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
		  </InputAdornment>
              }
              label={props.label}
          />
        </FormControl>
	);
}


interface PasswordFieldPropsWithTitle {
    id:string,
    title:string    
    label:string,
    adornment:string,
    onChange : (e : React.ChangeEvent<HTMLInputElement>)=>void,
    fullWidth:boolean,
    password:string,
    setPassword:(pw:string)=>void
}

export function PasswordFieldWithTitle( props : PasswordFieldPropsWithTitle) : React.ReactElement {

    return (
	
        <Box 
	    sx={{paddingLeft:'0',marginLeft:'0',
	    display: 'flex', flexDirection:'column',
 	    alignItems : 'flex-start',
	    justifyContent:'flex-start'}}>

	      <Typography style={{fontWeight:'bold'}} sx={{mt:1,mb:1}}>
                {props.title}
	      </Typography>

	      <PasswordField {...props} />
	
	</Box>);
}
