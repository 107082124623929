// File: create_free_mobile_licenses.ts
//

import * as React from 'react';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';
import {
    LOGGER} from '../../utils_common/logger';
import { AdminRole } from '../../extlinks/portal_comm_types';
import { AddFreeMobileLicensesFromForm } from '../utils_admin/free_mobile_licenses/add_free_mobile_licenses_from_form';
import { AddFreeMobileLicensesFromCSVFile } from '../utils_admin/free_mobile_licenses/add_free_mobile_licenses_from_file';
import { DisplayFreeMobileLicenses } from '../utils_admin/free_mobile_licenses/display_free_mobile_licenses';

const TAG='free_mobile_licenses.tsx: ';
const DEBUG=false;

const theme = createTheme();

export function FreeMobileLicenses() : React.ReactElement {

    const cnt = React.useRef<number>(0);

    if(cnt.current === 0) {
	LOGGER.log_event('FREE MOBILE LICENSES','free_mobile_licenses.tsx');
    }

    cnt.current += 1;    

    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;
    const [ spinnerOn, setSpinnerOn ] = React.useState<boolean>(false);
    const [ canEdit, setCanEdit ] = React.useState<boolean>(false);
    
    React.useEffect(()=> {
	if(mainStateContext.current_user_admin_role === AdminRole.MANAGER ||
	    mainStateContext.current_user_admin_role === AdminRole.EDITOR) {
	    setCanEdit(true);
	} 
    },[mainStateContext.current_user_admin_role])

    return (
        
	<ThemeProvider theme={theme}>	
	    <CssBaseline />
            
       <MainLayout title='Admin Console - Free Mobile Licenses'>
	 
         <Box
             sx={{
		 display: 'flex',
		 ml: 2,
		 mr: 2,
		 flexDirection: 'column',
		 alignItems: 'center',
             }}
         >

	   { canEdit &&
	   (<>
	     <Box sx={{ borderRadius: '10px',
	       width:'100%',
	       display: 'flex', flexDirection:'column',
	       boxShadow : 3,ml:2,mr:2, mb:3, p:2, pt:4,pb:4, alignItems:'center' }}>
	  
             <AddFreeMobileLicensesFromForm  setSpinnerOn={setSpinnerOn}/>
	   
	   </Box>

	   <Box style={{height:"1em"}}/>
	   
	   <Box sx={{ borderRadius: '10px',
	       width:'100%',	       
	       display: 'flex', flexDirection:'column',	       
               boxShadow : 3,ml:2,mr:2, p:2, pt:4,pb:4,mb:3, alignItems:'center' }} >
	     
	   <AddFreeMobileLicensesFromCSVFile setSpinnerOn={setSpinnerOn}/>

	   </Box>
	
	   <Box style={{height:"1em"}}/>
	   </>)
	   }

	   <Box sx={{ borderRadius: '10px',
	       width:'100%',	       
	       display: 'flex', flexDirection:'column',	       
               boxShadow : 3,ml:2,mr:2,p:0,pt:4,pb:4, alignItems:'center' }} >
	  
	     <DisplayFreeMobileLicenses  setSpinnerOn={setSpinnerOn}/>
	   </Box>
	 
	 </Box>

	 <Backdrop invisible={true} open={mainPagesIsLoading||spinnerOn}
		   sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	   <CircularProgress/>
	 </Backdrop>
	 
       </MainLayout>

	</ThemeProvider>
  );
    
}


