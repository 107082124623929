// File: home.tsx
//

import * as React from 'react';
import { MainLayout} from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop  from '@mui/material/Backdrop';
import Box  from '@mui/material/Box';
import  CircularProgress  from '@mui/material/CircularProgress';
//import { get_session_token} from '../../utils_common/logger';
import { LOGGER} from '../../utils_common/logger';
import { AdminRole } from '../../extlinks/portal_comm_types';
import { useNavigate} from 'react-router-dom';

const TAG='admin_home.tsx: ';
const DEBUG=false;
 
const theme = createTheme();

export function AdminHome() : React.ReactElement {

    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    const cnt = React.useRef<number>(0);

    if(cnt.current===0) {
	LOGGER.log_event('ENTER_ADMIN_HOME_PAGE','admin_home.tsx');
    }
    
    cnt.current += 1;

    LOGGER.info(DEBUG, TAG+'mainStateContext.current_user_admin_role=' +
      mainStateContext.current_user_admin_role);

    //console.info('location.host='+location.host)

    const navigate = useNavigate();
    
    React.useEffect(()=> {
	if( ! mainStateContext.current_user_admin_role ||
	    mainStateContext.current_user_admin_role === AdminRole.NONE) {
	    navigate('/home');
	}
    },[])
    
    
    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - Home'>
         <Box textAlign='center' sx={{paddingRight:'1em',paddingLeft:'1em',fontSize:'1.5em'}}>Select item from left side bar or from drop-down menu.</Box>

	  <Backdrop invisible={true} open={mainPagesIsLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>
	      
	    </MainLayout>

	</ThemeProvider>
  );
}
