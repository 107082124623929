// File: max_table_items_per_page.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { DropDownListMenu } from '../drop_down_list_menu';

export function MaxItemsPerPage(props : {setNumPerPage:(num:number)=>void}):  React.ReactElement {

    const items = [
	'5',
	'10',
	'25',
	'50',
	'100',
	'200',
	'500',                    
    ];

    const defaultIndex = 3;
    
    const [ selectedIndex, setSelectedIndex ] = React.useState<number>(defaultIndex);
    
    const setItemIndex = (index:number):void=> {
	//console.log(TAG+'setItemIndex: index=' + index);
	if(index >= items.length || index < 0) return;
	props.setNumPerPage(Number(items[index]));
	setSelectedIndex(index);
    }

    React.useEffect( ()=> {
        setItemIndex(defaultIndex);
    }, [])
    
    return (
	<Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
	  max items per page
          <DropDownListMenu setSelectedIndex={setItemIndex}
			    selectedIndex={selectedIndex}
			    minWidth={'6em'}			    
			    items={items}
	  />
	</Box>);
}

