// File: all_licenses_table.tsx
//

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks'
import { AdminRole, ListAdmins, MsgStatus } from '../../extlinks/portal_comm_types';
import { getAdminServerComm } from './admin_server_comm';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from './utils_config';
import { get_session_token, LOGGER } from '../../utils_common/logger';

const TAG='list_admins_table.tsx: ';

type AdminInfo = ListAdmins.AdminInfo;

function
EditAdminRolePopup(prps : {admin_uid:string, parentClose:()=>void, set_error_msg:(msg:string)=>void, refresh:()=>void } ) : React.ReactElement {

    const popupState = usePopupState({
	variant: 'popover',
	popupId: 'edit-admin-role'
    });
	
    const session_token = get_session_token();
    const server = getAdminServerComm();
    
    const change_admin_role_impl = async (admin_role:AdminRole) : Promise<MsgStatus> =>{
	const response = await server.handle_change_admin_role({
	    session_token,	
	    admin_uid : prps.admin_uid,
	    admin_role : admin_role
	});
	return response.status;
    }

    const change_admin_role = (admin_role:AdminRole):void=> {

	prps.parentClose();
	popupState.close();
	prps.set_error_msg('');
	
	change_admin_role_impl(admin_role).then((status)=>{
	    if(status === MsgStatus.ERROR) {
		LOGGER.error(TAG+'change_admin_role_impl: ERROR');
		prps.set_error_msg('server error');
	    }
	    prps.refresh();		
	}).catch(
	    (error)=>{
		LOGGER.error(TAG+'change_admin_role_impl: error='+error);
		prps.set_error_msg('server error');		
	    }
	)
    }

    const cancel = ():void => {	popupState.close(); }

    React.useEffect( ()=> {
	prps.set_error_msg('');
    },[]);
    
    return (
	<div>

	  <Typography
		     {...bindTrigger(popupState)}
	  >
          Change AdminRole
	  </Typography>

	  <Menu
		  {...bindMenu(popupState)}
	      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
	      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
	  >

	    <MenuItem onClick={()=>change_admin_role(AdminRole.VIEWER)}>VIEWER</MenuItem>	    
	    <MenuItem onClick={()=>change_admin_role(AdminRole.EDITOR)}>EDITOR</MenuItem>
	    <MenuItem onClick={()=>change_admin_role(AdminRole.MANAGER)}>MANAGER</MenuItem>
	    <MenuItem></MenuItem>
	    <MenuItem onClick={cancel}>CANCEL</MenuItem>	    

	  </Menu>
	</div>
    )
}


function
EditAdminUserPopup(prps : { set_error_msg:(msg:string)=>void, refresh:()=>void,row:AdminInfo}) : React.ReactElement {

    const popupState = usePopupState({
	variant: 'popover',
	popupId: 'edit-admin-user'
    })
	
    const session_token = get_session_token();
    const server = getAdminServerComm();
	
    const delete_admin_role_impl = async (row : AdminInfo) : Promise<MsgStatus> =>{
	const response = await server.handle_change_admin_role({
	    session_token,
	    admin_uid : row.admin_uid,
	    admin_role : AdminRole.NONE
	});
	return response.status;
    }
    
    const delete_admin = ():void =>{
	popupState.close();
	prps.set_error_msg('');
	delete_admin_role_impl(prps.row).then((status)=>{
	    if(status === MsgStatus.ERROR) {
		LOGGER.error(TAG+'delete_admin_role_impl: ERROR');
		prps.set_error_msg('server error');
	    } else {
		prps.refresh();
	    }
	}).catch(
	    (error)=> {
		LOGGER.error(TAG+'delete_admin_role_impl: error='+error);
		prps.set_error_msg('server error');
	    }
	)
    }

    React.useEffect( ()=> {
	prps.set_error_msg('');
    },[]);

    return (<>
	<div>

	  <IconButton
		     {...bindTrigger(popupState)}
	  >
	    <EditOutlinedIcon/>
	  </IconButton>

	  <Menu
		  {...bindMenu(popupState)}
	      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
	      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
	  >

            <MenuItem><EditAdminRolePopup
			  parentClose={()=>popupState.close()}
			  admin_uid={prps.row.admin_uid}
			  set_error_msg={prps.set_error_msg}
			  refresh={prps.refresh}/>
	    </MenuItem>
	    <MenuItem onClick={delete_admin}>Remove as Admin</MenuItem>

	
	  </Menu>
	</div>
	</>
    )
}

function ListAdminsTableRow(props : {  refresh:()=>void,  set_error_msg:(msg:string)=>void,
    sendInvite : (row:AdminInfo)=>void,
    row : AdminInfo}) : React.ReactElement {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ popupParent, setPopupParent]  = React.useState<HTMLElement|null>(null);

    /*
    const [isPreAssignedManager, setIsPreAssignedManager] = React.useState<boolean>(false);

    React.useEffect( ()=> {
	setIsPreAssignedManager(false);
	const session_token = get_session_token();
	const server = getAdminServerComm();
	server.handle_email_is_for_preassigned_manager({
	    session_token,
	    email:props.row.invited_email
	}).then( (response)=> {
	    if(response.status === MsgStatus.OK) {
		setIsPreAssignedManager(response.is_preassigned_manager);
	    } else {
		props.set_error_msg('server error');
	    }
	}).catch( (error)=> {
	    props.set_error_msg(error);
	    setIsPreAssignedManager(false);
	});
    },[]);*/

    return (
            <TableRow  key={'LC-'+(props.row.portal_email==null? '':props.row.portal_email)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
	      
              <TableCell size="small" align="left">{props.row.portal_email==null? '': props.row.portal_email}</TableCell>
	      
              <TableCell size="small" align="left">{props.row.invited_email}</TableCell>	      
	      
              <TableCell size="small" align="left">
                  {
		  props.row.is_preassigned_admin ? props.row.admin_role+'*' :props.row.admin_role
		  }
	      </TableCell>

              <TableCell size="small" align="left">
		<IconButton onClick={()=>{props.sendInvite(props.row)}}>
		  <EmailOutlinedIcon/>
		</IconButton>		  
	      </TableCell>


              <TableCell size="small" align="left">
		{props.row.invite_date ==null ? '': props.row.invite_date}
	      </TableCell>		      
	      
              <TableCell size="small" align="left" ref={(c:any)=>setPopupParent(c)}>
		{ ! props.row.is_preassigned_admin &&
		(<EditAdminUserPopup
		    set_error_msg={props.set_error_msg}
		    refresh={props.refresh}
		    row={props.row}/>)
		}
		{  props.row.is_preassigned_admin &&
		(<Typography>NA</Typography>)
		}		
              </TableCell>
		
            </TableRow>
	)
}

let ROW_CNT=0;

// max_range is inclusive;
// page_num starts at 0 for first page
// key is a dummy parameter used to force re-render this component by its parent
export function ListAdminsTable(props : { adminInfos : AdminInfo[],
    sendInvite : (row:AdminInfo)=>void,
    set_error_msg:(msg:string)=>void,
    row_edit_action : (row:AdminInfo)=>void,
    refresh:()=>void
}) : React.ReactElement {

    console.log(TAG+'props.adminInfos.length='+  props.adminInfos.length);



    return (
	
	<Box sx={{display:'flex', flexDirection:'column',
	    alignItems:'center'}}>
	  
   <TableContainer component={Paper} style={{ borderRadius:'5px'  }}
		   sx={{ boxShadow:3}} >

      <Table sx={{borderTop: '1px solid #E0E0E0' }} >
	  
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>
          <TableRow>

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Portal Email
	    </TableCell>

            <TableCell
		size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Invite Email
	    </TableCell>		    
	
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Admin Role
	    </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Send Invite
	    </TableCell>


            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Date Invited
	    </TableCell>


            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Edit
	    </TableCell>	    
	
          </TableRow>
        </TableHead>
	
        <TableBody>
	 <> 
        { props.adminInfos.map((row:AdminInfo) => {

	      return (<ListAdminsTableRow row={row} key={String(ROW_CNT++)}
					  sendInvite={props.sendInvite}
					  refresh={props.refresh}
					  set_error_msg={props.set_error_msg}
		      />)
          })
	}
	 </>
        </TableBody>
	
      </Table>
	
    </TableContainer>
      
	</Box>	
    );

}
