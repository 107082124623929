// File: enter_email.tsx
//

import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MsgAlertDialog } from './alert_dialog';

const DEBUG=true;
const TAG="request_email.ts: ";

function check_email(email:string) : boolean {
    const ws = email.split('@');
    if(ws.length !== 2) return false;
    if(ws[1].split('.').length <= 1) return false;
    return true;
}

// submitEmail is called when the user presses the SUBMIT  button;
// it has to return true iff the email is valid
export function RequestEmail(props : {
    testing:boolean,
    caption : string,
    submit:(email:string)=>void }) : React.ReactElement
{
    
    const [ msg, setMsg ] = React.useState<string>('');    
    const [ emailEntered, setEmailEntered ] = React.useState<string|null>(null);

    const submit = () : void => {
	if(emailEntered == null || emailEntered.trim()==='') {
	    setMsg('Email missing.');
	} else if( !check_email(emailEntered)) {
	    setMsg('Email invalid format.');
	} else {
	    props.submit(emailEntered);
	}
    }    
    
    return (
   <> 
       <Box  sx={{ marginTop: 8, display: 'flex',
	    flexDirection: 'column', alignItems: 'center', justifyContent:'center'
	}}>
	  
	  <Box  sx={{ display: 'flex',
	      flexDirection: 'column', alignItems: 'center',
	  }}>
				
	    <Typography sx={{ mt:5,mb:2}} textAlign="center" variant="h6">
   					{props.caption}
	    </Typography>
	    
	    <TextField
		fullWidth
		id="email"
		label="Email"
		name="email"
		autoComplete="email"
  		inputProps={{ style: { textTransform: 'lowercase'} }}		    
		onChange={(e)=> {
		    setEmailEntered(e.target.value.trim().toLowerCase())}}
	    />
				
	  </Box>	    
			      
	  <Box>
				
	    <Button 
		type="submit"
		      fullWidth
		variant="contained"
		      onClick={submit}
		      sx={{ mt: 3, mb: 2 }}
	    >
					SUBMIT
	    </Button>
	    
	  </Box>
	
	</Box>
	
	  <MsgAlertDialog msg={msg} setMsg={setMsg} />

   </>
);

}
