// File: desktop_licenses_table.tsx
//

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GetDesktopActivationInfo} from '../../extlinks/portal_comm_types';
import { TABLE_HEADER_BG_COLOR,TABLE_HEADER_FONT_COLOR } from './utils_config';

type DesktopActivationInfo = GetDesktopActivationInfo.DesktopActivationInfo;

/*
function convert_date(s:string):string {
    if(s.endsWith('+00:00')) s= s.slice(0,-6);
    else if(s.endsWith('+0000')) s= s.slice(0,-5);
    s = s.replace(/ /,'T');
    if(s.indexOf('T') > 0 && ! s.endsWith('Z')) s = s+'Z';
    return s;
}
*/

function get_email_divs(emails : string[]) : React.ReactElement {
    
    let cnt = 0;
    
    return  (<>
	{
	    emails.map( (email) => {
		return (<div key={String(cnt++)}>{email}</div>)
	    })
	}
          </>
    )
}

export function
DesktopLicensesTable(props : { key_infos :   DesktopActivationInfo[] }): React.ReactElement  {

    const getwrap = (row : DesktopActivationInfo) : string => {
	if( (row.as_user_id.split(' ').length -1) > 3) return 'wrap';
	return 'nowrap';
    }
    
    return (
   <TableContainer component={Paper} style={{  maxWidth:'97vw', borderRadius:'5px'  }}
		   sx={{boxShadow:3, pb:2}} >
      <Table sx={{ maxWidth:'97vw', borderTop: '1px solid #E0E0E0' }} >
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>
	  
          <TableRow style={{fontSize:'0.8rem'}}>
            <TableCell
	      size="small"
	      	style={{color:TABLE_HEADER_FONT_COLOR}} colSpan={5} 
		align="center">
             Desktop App Licenses
	    </TableCell>	    	    	    
          </TableRow>
	  
          <TableRow>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Emails</TableCell>	    	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Issued</TableCell>
	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">User ID</TableCell>
	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">License Key</TableCell>

	    {/*
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">SKU</TableCell>
	    */}
	    	    
	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Is Subscription</TableCell>
	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Desktop Activations</TableCell>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Remaining Desktop Activations</TableCell>	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Expiration</TableCell>	    
          </TableRow>
        </TableHead>
	
        <TableBody>
	  
          {props.key_infos.map((row: DesktopActivationInfo) => {
	      
	      const issue_date = row.issue_date == null ? 'Unknown' :
		  (new Date(row.issue_date)).toLocaleDateString();		  
		  //(new Date(convert_date(row.issue_date))).toLocaleDateString();
	      const expiry_date = row.expiry_date == null ? 'None' :
		  (new Date(row.expiry_date)).toLocaleDateString();
		  //(new Date(convert_date(row.expiry_date))).toLocaleDateString();
	      const nact = row.num_desktop_activations;
	      const nact_left = nact ==null ||
		  row.max_desktop_activations == null ? null :
		  Math.max(0,row.max_desktop_activations - nact);
	      const nact_str = nact==null ? 'NA' : String(nact);
	      const nact_left_str = nact_left==null ? 'NA' : String(nact_left);
	      const is_subscription = row.is_subscription ? 'yes' : 'no';

	      return (
            <TableRow
              key={'DLC-'+row.license_key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell size="small" align="left">{get_email_divs(row.emails_of_key)}</TableCell>	      	      	      
              <TableCell size="small" align="left">{issue_date}</TableCell>

              <TableCell style={{whiteSpace:getwrap(row)}} size="small" align="left">{row.as_user_id}</TableCell>
	      
              <TableCell style={{whiteSpace:'nowrap'}} size="small" align="left">{row.license_key}</TableCell>
	      

	      {/*
              <TableCell size="small" align="left">{row.as_sku}</TableCell>
	      */}
	      
              <TableCell size="small" align="left">{is_subscription}</TableCell>	      
              <TableCell size="small" align="left">{nact_str}</TableCell>	      
              <TableCell size="small" align="left">{nact_left_str}</TableCell>
              <TableCell size="small" align="left">{expiry_date}</TableCell>	      
            </TableRow>)
          })}
	  
        </TableBody>
	
        </Table>
    </TableContainer>);

}
