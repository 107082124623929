// File: free_mobile_expiration_menu.tsx
//

import * as React from 'react';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { AddFreeMobileLicenses } from '../../../extlinks/portal_comm_types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const TAG='free_mobile_expiration_menu.tsx: ';

const INDEFINITE_LICENSE_DURATION=AddFreeMobileLicenses.INDEFINITE_LICENSE_DURATION;

export const DEFAULT_EXPIRY_DURATION=INDEFINITE_LICENSE_DURATION;

const options : [string,number][] = [
   [ '14 days', 14],
   [ '30 days', 30],
   [ '60 days', 60],
   [ '120 days', 120],
   [ '180 days', 180],
   [ '365 days' , 365],              
   [ 'none', INDEFINITE_LICENSE_DURATION]
];

function get_default_index() : number {
    for(let i=0;i<options.length;i++) {
	if(options[i][1] ===  DEFAULT_EXPIRY_DURATION) return i;
    }

    return options.length-1;
}

export function FreeMobileExpirationListMenu(props : {setExpiryDuration:(expiry_duration:number)=>void }) : React.ReactElement {
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number>(get_default_index());
    
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>):void => {
	setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
	event: React.MouseEvent<HTMLElement>,
	index: number,
    ):void => {
	props.setExpiryDuration(options[index][1])
	console.log(TAG+'expiryDuration=' + options[index][1]);
	setSelectedIndex(index);
	setAnchorEl(null);
    };

    const handleClose = ():void => {
	setAnchorEl(null);
    };

    const theme = createTheme();
     
    return (
    <ThemeProvider theme={theme}>	
	<Box>

	  <Box style={{ display:'flex', flexDirection:'row', justifyContent:'space-between',
	      alignItems:'center'}}>
	    
  	     <Typography style={{marginRight:'1em'}} variant='h6'>Select License Expiration Period</Typography>
	     
          <Box>
	  
	  <List
              component="nav"
              aria-label="Device settings"
              sx={{ bgcolor: 'background.paper' }}
	  >
            <ListItemButton
		id="lock-button"
		style={{fontWeight:'bold', borderRadius:'4pt',
		    whiteSpace : 'nowrap', minWidth:'6em',
		    paddingTop:'1pt',paddingBottom:'1pt', paddingRight:'0.5em',paddingLeft:'0.5em',
		    backgroundColor:theme.palette.primary.main,
		    color:'white',
		    border:"1pt solid black"}}		
		aria-haspopup="listbox"
		aria-controls="lock-menu"
		aria-label="when device is locked"
		aria-expanded={open ? 'true' : undefined}
		onClick={handleClickListItem}
            >
              <ListItemText sx={{textAlign:'center'}}
		  primary={options[selectedIndex][0]}
              />

            </ListItemButton>
	  </List>
	  
	  <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
		  'aria-labelledby': 'lock-button',
		  role: 'listbox',
              }}
	  >
        {options.map((option, index) => (
            <MenuItem
		key={option[0]}

		selected={index === selectedIndex}
		onClick={(event) => handleMenuItemClick(event, index)}
            >
            {option[0]}
            </MenuItem>
        ))}
	  </Menu>
       </Box>
       </Box>       
	 
	</Box>
    </ThemeProvider>
    );
}
