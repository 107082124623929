// File: desktop_app_license_key.tsx
//

import * as React from 'react';
import { MainLayout} from '../utils/main_layout';
import  Box  from '@mui/material/Box';
import CircularProgress  from '@mui/material/CircularProgress';
import Typography  from '@mui/material/Typography';
import Backdrop  from '@mui/material/Backdrop';
import  Button  from '@mui/material/Button';
//import { useMainPagesContext } from '../utils/main_pages_provider';
import { DesktopLicensesTable } from '../utils/desktop_licenses_table';
import { useNavigate} from 'react-router-dom';
import { get_session_token, LOGGER } from '../../utils_common/logger';
import { GetDesktopActivationInfo, MsgStatus } from '../../extlinks/portal_comm_types';
import { getServerComm } from '../utils/server_comm';

const DEBUG=false;
const TAG='desktop_app_license_key.tsx: ';

type DesktopActivationInfo = GetDesktopActivationInfo.DesktopActivationInfo;

export function DesktopAppLicenseKey() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(DEBUG) console.log(TAG+'***count='+count.current);
	if(count.current > 50) navigate('/home');
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_DESKTOP_APP_LICENSE_KEY_PAGE',
	    'desktop_app_license_key.tsx');
    }

    /*
    const main_pages_state = useMainPagesContext();
    const user_licenses_info =  main_pages_state.user_licenses_info;
    const keyInfos = user_licenses_info?.license_infos;
    const isLoading = main_pages_state.is_loading;    
    */

    const [ desktopActivations, setDesktopActivations ] = React.useState<DesktopActivationInfo[]|null>(null)

    const [ isLoading,setIsLoading] = React.useState<boolean>(false);
    
    const fetch = async () : Promise<void> => {
	const server_comm = getServerComm();
	const session_token= get_session_token();
	setIsLoading(true);
	const x = await server_comm.handle_get_desktop_activation_info({session_token});
	if(x.status === MsgStatus.OK) {
	    setDesktopActivations(x.desktop_activation_infos);
	} else {
	    LOGGER.error(TAG+'fetch: error');
	}
	setIsLoading(false);	
    };
    
    const refresh_fun = ():void => {
	//main_pages_state.refresh_user_licenses_info();
	fetch().then( ()=>{}).catch(()=>{});
    };

    React.useEffect( ()=> {
	fetch().then(()=>{}).catch(()=>{});
    },[]);
    
    return (
	
	<MainLayout title='Desktop Licenses'>

	  <Backdrop invisible={true} open={isLoading} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>

      <>
	  { (desktopActivations == null ||  desktopActivations.length===0) && ! isLoading  &&
	    (
		<Typography align='center'>You have no desktop licenses.</Typography>
	    )
	  }
      </>
      
      <>
	{ desktopActivations && desktopActivations.length>0  && !isLoading &&

    	  ( <>
	   <Box style={{height:'1em'}}/>
   	   <Box>      
	     <DesktopLicensesTable key_infos={desktopActivations ? desktopActivations: []}/>
           </Box>
	   <Typography sx={{mt:2}}>
	     *email that claimed license first
	   </Typography>	     
	      </>
          )
	}
      </>

      <>
	{ !isLoading &&
	  (<>
	    <Box textAlign='center'>
              <Button
		  size="small"
		  type="submit"
		  variant='outlined'	  
		  sx={{ mt: 3, mb: 2 }}
		  onClick={()=>refresh_fun()}
              >
	    REFRESH INFO
              </Button>
            </Box>
          </>)
	}
      </>
       
	</MainLayout>	
  );
}
