// File: add_free_mobile_licenses_from_form.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { get_session_token, LOGGER } from '../../../utils_common/logger';
import { getAdminServerComm } from '../admin_server_comm';
import { MsgStatus } from '../../../extlinks/portal_comm_types';
import { AddFreeMobileLicenses } from '../../../extlinks/portal_comm_types';
import { DEFAULT_MAX_DEVICES_PER_FREE_LICENSE, FreeMobileLicenseSettings } from './free_mobile_license_settings';
import { DEFAULT_EXPIRY_DURATION,INDEFINITE_LICENSE_DURATION } from './select_license_expiration';
import { get_on_local_host } from '../../../config/firebase_config';
import { DEFAULT_ACT_CODE_EXPIRY_HRS } from './free_mobile_act_code_expiry_menu';

const TAG='add_free_mobile_licenses_from_form.tsx: ';
const DEBUG=true;

type AuthMethod = AddFreeMobileLicenses.AuthMethod;
type UserInfo = AddFreeMobileLicenses.UserInfo;

class Item {
    static keynum : number = 0;
    private values : string[] = [ "","",""];
    private key=String(Item.keynum++);
    constructor() {
    }
    get_values() : string[] {
	return this.values;
    }
    
    email_is_missing() : boolean {
	return this.values[0].trim()==='';
    }
    
    is_empty():boolean {
	for(let value of this.values) {
	    if(value.trim()!=='') return false;
	}
	return true;
    }
    //get_elem(key:string) : React.ReactElement {
    get_elem(clearMsg:()=>void) : React.ReactElement {
	
        const f0 = (event: React.ChangeEvent):void => {
	    // @ts-ignore	    
	    this.values[0] = event.target.value.trim();
	    //console.log('get_elem:values[0]=' + this.values[0]);
	}
        const f1 = (event: object):void => {
	    // @ts-ignore
	    this.values[1] = event.target.value.trim();
	}
        const f2 = (event: object):void => {
	    // @ts-ignore	    
	    this.values[2] = event.target.value.trim();
	}

	return (
	    <Box key={this.key} style={{display:'flex',
		marginBottom:"0.5em",
		width:"100%",
		flexDirection:'row',
		alignItems:"center",
		justifyContent:"space-around"}}>		    
	      <TextField size="small"
			 label="Email *"
			 style={{ width:"35%", marginRight:"1em"}}
	                 onInput={clearMsg}
	                 onClick={clearMsg}
			 onChange={f0}/>
	      <TextField  size="small" style={{ width:"25%", marginRight:"1em"}}
			  label="First Name (Optional)"
	                  onInput={clearMsg}
	                  onClick={clearMsg}			  
			 onChange={f1}/>
	      <TextField  size="small" style={{width:"25%"}}
			  label="Last Name (Optional)"
	                  onInput={clearMsg}
	                  onClick={clearMsg}			  			  
			 onChange={f2}/>
	    
  	    </Box>
	)	
    }
    to_user_info() : UserInfo | null {
	if(this.values[0].trim() === '') return null;
	return {
	    email : this.values[0],
	    first_name : this.values[1],
	    last_name : this.values[2],
	}
    }
}

function convert_items_to_user_infos(items:Item[]) : UserInfo[] {
	
    const user_infos = items.map( (x)=>x.to_user_info() );
    let out : UserInfo[] = [];
    // using 'filter' does not pass type test
    for(let x of user_infos) {
	if(x) out.push(x);
    }
    return out;
}

export function
AddFreeMobileLicensesFromForm(props : { setSpinnerOn:((on:boolean)=>void)}) :React.ReactElement {

    const [ items, setItems ] = React.useState<Item[]>([new Item()]);
    const [ errorMsg, setErrorMsg ] = React.useState<string>('');
    const [ successMsg, setSuccessMsg ] = React.useState<string>('');    
    const [ expiryDuration, setExpiryDuration] = React.useState<number>(DEFAULT_EXPIRY_DURATION);
    const [ actCodeExpiryDurationHrs, setActCodeExpiryDurationHrs] =
	React.useState<number>(DEFAULT_ACT_CODE_EXPIRY_HRS);
    const [ authMethod, setAuthMethod ] = React.useState<AuthMethod|null>(null);
    const [ oneLicensePerEmail, setOneLicensePerEmail] = React.useState<boolean>(true);
    const [ maxDevices, setMaxDevices ] = React.useState<number>(DEFAULT_MAX_DEVICES_PER_FREE_LICENSE);
    
    const clearMsgs = ():void => {
	setErrorMsg('');
	setSuccessMsg('');
    }
    
    const add_row = ():void => {

	console.log('add_row: maxDevices='+maxDevices);

	if(DEBUG) {
	    console.log('entering add_row');
	    console.log(TAG+'expiryDuration='+expiryDuration);
	}
 	
	clearMsgs();
	if(items.length>0 && items[items.length-1].email_is_missing()) return;
	const new_items = items.concat([]);
	new_items.push(new Item());
	setItems(new_items);
    }
    
    const submit = ():void => {
	
	if(DEBUG) console.log('entering save');

	clearMsgs();

	if(maxDevices==null) {
	    console.log('maxDevices==null');
	    setErrorMsg("'Max number of devices allowed' not set");
	    return;
	}
	
	if(authMethod == null) {
	    setErrorMsg('The activation method was not checked.');	    
	    console.error(TAG+'submit: authMethod==null');
	    return;
	}

	{
	    let cnt = 0;
	    for(let x of items) {
		if(DEBUG) console.log(x.get_values());
		if( ! x.is_empty()) cnt++;
	    }
	    if(DEBUG) console.log(TAG+'items.length='+items.length);
	    if(cnt===0) {
		setErrorMsg('No emails.');
		return;
	    }
	}

	if(expiryDuration==null) {
	    console.error(TAG+'submit:license_duration_days==null');
	    return;
	}

	props.setSpinnerOn(true);

	const license_duration_days = expiryDuration >= INDEFINITE_LICENSE_DURATION ? null : expiryDuration;

	const overwrite_existing = ! oneLicensePerEmail;
	    
	const user_infos = convert_items_to_user_infos(items);
	
	/*
	if(user_infos.length===0) {
	    setErrorMsg('No emails were input.');
	    return;
	}*/
	if(DEBUG) console.log(TAG+'user_infos.length='+user_infos.length);
	
	if(user_infos.length>0) {

	    const act_code_expiry_hrs = actCodeExpiryDurationHrs;
	    const web_host_is_local = get_on_local_host();
	    const session_token = get_session_token();
	    const server = getAdminServerComm();

	    console.log(TAG+'act_code_expiry_hrs='+act_code_expiry_hrs);
	    
	    server.handle_add_free_mobile_licenses({
		session_token,
		user_infos,
		auth_method: authMethod,
		overwrite_existing,
		license_duration_days,
		max_devices : maxDevices,
		act_code_expiry_hrs,		
		web_host_is_local
	    }).then( (x)=> {

		if(DEBUG) console.log(TAG+'return from: handle_add_free_mobile_licenses: x=',x);
		
		props.setSpinnerOn(false);
		
		if(x.status===MsgStatus.OK) {
		    if(DEBUG) console.log(TAG+'handle_add_free_mobile_licenses: success');
		    setItems([new Item()]);
		    if(x.common_activation_code != null) {
			if(x.msg.trim() !== '') { 
			setSuccessMsg('Success: ' + x.msg + '. Activation Code: ' +
			    x.common_activation_code);
			} else {
			    setSuccessMsg('Success: Activation Code: ' +
				x.common_activation_code);
			}
		    } else {
			if(x.msg.trim() !== '') { 
			setSuccessMsg('Success: ' + x.msg);
			} else {
			    setSuccessMsg('Success');
			}			
		    }
		    props.setSpinnerOn(false);						    
		    //setTimeout( ()=>setSuccessMsg(''),5000 );
		    return;
		} else {
		    LOGGER.error(TAG+'handle_add_free_mobile_licenses: server error');
		    console.error(TAG+'handle_add_free_mobile_licenses: server error');
		    if(x.msg.trim() !== '') {
			setErrorMsg('Server Error: ' + x.msg);
		    } else {
			setErrorMsg('Server Error');
		    }

		    return;
		}
	    }).catch( (error)=> {
		LOGGER.error(TAG+'handle_add_free_mobile_licenses: PT2: error=' + error);
		console.error(TAG+'handle_add_free_mobile_licenses: PT2: error=' + error);
		props.setSpinnerOn(false);						
		setErrorMsg('Server Error');		
	    });

	} else {
	    props.setSpinnerOn(false);					    
	}

    }

/*
    React.useEffect( ()=>{
	    
	console.log('maxDevices=' + maxDevices);
	
    },[maxDevices])
*/    
    
    return (
	<Box>
	
	 <Box style={{display:'flex',
	     maxWidth:"100%",
	     flexDirection:'column',
	     alignItems:"center",
	     justifyContent:"flex-start",
	     marginRight:"0.5em",
	     marginLeft:"0.5em",
	 }}>
	   
	   <Typography sx={{mr:2,mb:2}} variant="h5" >Create Free Mobile Licenses</Typography>
	   
	   <FreeMobileLicenseSettings
	       maxDevices={maxDevices}
	       setMaxDevices={setMaxDevices}	       
	       oneLicensePerEmail={oneLicensePerEmail}
	       setOneLicensePerEmail={setOneLicensePerEmail}
	       setExpiryDuration={setExpiryDuration}
	       setAuthMethod={setAuthMethod}
	       setActCodeExpiryDurationHrs={setActCodeExpiryDurationHrs}
	   />

	   <Box style={{height:"1em"}}/>	   
	
	   <Box style={{display:'flex', flexDirection:'row',
	       alignItems:"center" , justifyContent:"center"}}>
	   <Button onClick={add_row} size="small" type="submit" variant="contained">
               +
           </Button>
	     <Typography variant='h6' sx={{ml:2}}>Add</Typography>	   
	   </Box>
	   
	   <Box style={{width:'100%', height:"1em"}}/>
	       
	   <>
	       {
		   items.map( item => (
		       item.get_elem(clearMsgs)
		   ))
	       }
	   </>

	   <Box style={{height:"1em"}}/>
	   
           <Typography>* Required</Typography>
	   
	   <Box style={{height:"1em"}}/>	       

	     
	   <Button onClick={submit} size="small" type="submit" variant="contained">SUBMIT</Button>

	 {  errorMsg.trim().length > 0 && 
	     (<Alert 
                  style={{position:'fixed',top:'50%',zIndex:'100000'}}
		  sx={{boxShadow:4, mt:3}}
		  severity="error"
		  onClose={()=>setErrorMsg('')}>{errorMsg}</Alert>)
	 }
	   
	 { successMsg.trim().length>0 &&
	     (<Alert  sx={{boxShadow:4, mt:2}} 
                      style={{position:'fixed',top:'50%', zIndex:'100000'}}
		      severity="success"		      
		      onClose={()=>setSuccessMsg('')}>{successMsg}</Alert>)
	 }	 
	   
         </Box>
	    
	    
	</Box>
    )
}
