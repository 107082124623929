// File: devices_of_license_table.tsx
//

import * as React from 'react';
//import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from '../utils_config';
import { GetEditLicenseInfo } from '../../../extlinks/portal_comm_types';

//const TAG='devices_of_license_table.tsx: ';
//const DEBUG=false;

type EditLicenseInfo = GetEditLicenseInfo.EditLicenseInfo;

export function DevicesOfLicenseTable(props:{license_info:EditLicenseInfo}) : React.ReactElement {

    let cnt = 0;
    return (

 <Box  sx={{borderTop: 'solid 1px black', width:'100%', display:'flex',flexDirection:'column',
	    alignItems:'center'}}>
	
   <h2>Devices</h2>
   <TableContainer  style={{ borderRadius:'5px'  }}
		   sx={{ boxShadow:3}} >

      <Table sx={{borderTop: '1px solid #E0E0E0' }} >
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>
		      
          <TableRow key='DevicesOfLicenseTable-row0'>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Device Type
	    </TableCell>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Activation Count
	    </TableCell>	

          </TableRow>
        </TableHead>
	
        <TableBody>
  	{
	      props.license_info.devices_of_license.map( (d:GetEditLicenseInfo.DeviceOfLicense)=> {
		  return (
		  <TableRow key={'DevicesOfLicenseTable-row'+cnt++}>
		    <TableCell>{d.device_type}</TableCell>
		    <TableCell>{String(d.activation_count)}</TableCell>		    
		  </TableRow>)
	      })
	  }
	  
        </TableBody>
	
        </Table>
	
    </TableContainer>

   </Box>    

    );
}
