// File: ok_cancel_dialog.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

interface OKCancelDialogProps {
    title:string;
    open: boolean;
    onClose: (value: string) => void;
}

export function OKCancelDialog(props: OKCancelDialogProps)  : React.ReactElement {
    
    //const { onClose, selectedValue, open } = props;
    const { onClose, open, title } = props;

    /*
  const handleClose = ():void => {
    onClose(selectedValue);
  };*/

  const handleListItemClick = (value: string):void => {
    onClose(value);
  };

  return (
    <Dialog onClose={()=>{}} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <Button onClick={() => handleListItemClick('OK')}>OK</Button>
      <Button onClick={() => handleListItemClick('CANCEL')}>CANCEL</Button>
    </Dialog>
  );
}

interface OKDialogProps {
    title:string;
    open: boolean;
    onClose: () => void;
}

export function OKDialog(props: OKDialogProps)  : React.ReactElement {
    
    const { onClose, open, title } = props;

  return (
      <Dialog onClose={()=>{}} open={open}>
	<DialogTitle>{title}</DialogTitle>
	<Button onClick={onClose}>OK</Button>
      </Dialog>
  );
}
