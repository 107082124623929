// File: ok_cancel_dialog.tsx
//

import * as React from 'react';
//import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
//import Dialog from '@mui/material/Dialog';
//import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Popover from '@mui/material/Popover';

interface Props {
    label:string;
    msg:string | React.ReactElement;
    open: boolean;
}

export function HelpPopup(props: Props)  : React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>):void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ():void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (

      <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
	<Box sx={{mr:0,pr:0}}>
	  {props.label}
  	</Box>
      <IconButton  sx={{ml:1,pl:0}} onClick={handleClick}>
        <HelpOutlineIcon fontSize='small'/>	
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >

	<Typography align='left' sx={{ p: 2 }}>{props.msg}</Typography>
	
      </Popover>
    </Box>
  );
}


interface Props2 {
    msg:string | React.ReactElement;
    open: boolean;
}


export function HelpPopup2(props: Props2)  : React.ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>):void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = ():void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (

     <Box sx={{paddingLeft:'0',marginLeft:'0',
	 display:'flex', flexDirection:'row',
	 alignItems:'center'}}>
       
      <IconButton  sx={{ml:0,pl:0}} onClick={handleClick}>
        <HelpOutlineIcon fontSize='small'/>	
      </IconButton>
      
      <Popover id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
	       transformOrigin={{horizontal:'left',
		  vertical:'top' }}
	       marginThreshold={30}
      >
	
        <Typography align='left'
		    style={{maxWidth:'20em'}}
		    sx={{ p: 2 }}>{props.msg}</Typography>
	
      </Popover>
      
    </Box>
  );
}

/*
               anchorOrigin={{
		   vertical: 'bottom',
		   horizontal: 'left',
               }}   
*/
