// File: drop_down_list_menu.tsx
//

import * as React from 'react';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { createTheme } from '@mui/material/styles';

//const TAG='drop_down_list_menu.tsx: ';

export function DropDownListMenu(props : {
    setSelectedIndex:(indx:number)=>void,
    selectedIndex:number,    
    minWidth : string,
    onChange? : ()=>void,
    items:string[]}) : React.ReactElement {
    
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	  
    const open = Boolean(anchorEl);
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>):void => {
	setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
	_event: React.MouseEvent<HTMLElement>,
	index: number,
    ):void => {
	props.setSelectedIndex(index);
	setAnchorEl(null);
	if(props.onChange) props.onChange();
    };

    const handleClose = ():void => {
	setAnchorEl(null);
    };
	  
    const theme = createTheme();

    return (
	
	  <Box style={{ display:'flex', flexDirection:'column', alignItems:'center'}}>
	  
	  <List
              component="nav"
              sx={{ bgcolor: 'background.paper' }}
	  >
            <ListItemButton
		id="lock-button"
		style={{fontWeight:'bold', borderRadius:'4pt',
		    whiteSpace : 'nowrap', minWidth:props.minWidth,
		    paddingTop:'1pt',paddingBottom:'1pt', paddingRight:'0.5em',paddingLeft:'0.5em',
		    backgroundColor:theme.palette.primary.main,		    
		    color:'white',
		    border:"1pt solid black"}}		
		aria-haspopup="listbox"
		aria-controls="lock-menu"
		aria-label="when device is locked"
		aria-expanded={open ? 'true' : undefined}
		onClick={handleClickListItem}
            >
              <ListItemText sx={{textAlign:'center'}}
		  primary={props.items[props.selectedIndex]}
              />

            </ListItemButton>
	  </List>
	  
	  <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
		  'aria-labelledby': 'lock-button',
		  role: 'listbox',
              }}
	  >
        {props.items.map((item, index) => (
            <MenuItem
		key={item}
		selected={index === props.selectedIndex}
		onClick={(event) => handleMenuItemClick(event, index)}
            >
            {item}
            </MenuItem>
        ))}
	  </Menu>

       </Box>       
    );
}
