// File: ecomm_session_tokens.ts
//

import { ECOMM_TESTING } from '../../config/config';

const TAG='ecomm_session_tokens.ts: ';
const DEBUG=true;

const KEY='session_token';

export function store_ecomm_session_token(args:{session_token:string}) : void {
    window.sessionStorage.setItem(KEY, args.session_token);
}

export function get_ecomm_session_token() : string|null {
    const token = window.sessionStorage.getItem(KEY);
    if(token===undefined) {
        if(ECOMM_TESTING) {
            console.log(TAG+'token===undefined');
        }
        return null;
    }
    return token;
}

export function delete_ecomm_session_token() : void {
    window.sessionStorage.removeItem(KEY);
}
