// File: view_mobile_devices.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { MainLayout} from '../utils/main_layout';
import { check_password_strength } from '../../utils_common/password_strength';
import { PasswordField } from '../../utils_common/password_field';
import { getAuth, updatePassword } from 'firebase/auth';
import { LOGGER } from '../../utils_common/logger';
import {useNavigate} from 'react-router-dom';

const TAG='change_password.tsx: ';
const DEBUG=false;

export function ChangePassword() : React.ReactElement {

    const count = React.useRef<number>(0);
    {
	const navigate = useNavigate();	
	count.current = count.current+1;
	if(DEBUG) console.log('***count='+count.current);
	if(count.current > 50) navigate('/home');
    }

    if(count.current===1) {
	LOGGER.log_event('ENTER_CHANGE_PASSWORD','change_password.tsx');
    }

    const [newpw, setNewPW] = React.useState<string>('');

    const [ msg, setMsg ] = React.useState<string>('')

    const parse_error = (error : any) : void => {
	if( ! error.code) {
	    setMsg('Error changing password. Try logging out and logging in again.');
	    
	} else if (error.code === 'auth/weak-password') {
            setMsg('Password is too weak.');
	    
	} else if (error.code === 'auth/invalid-password') {
	    
            setMsg('Password was invalid.');
	    
	} else {
	    setMsg('Error changing password. Try logging out and logging in again.');
	}
    }
    
    const onSubmit = ():void => {

	console.log({newpw});

	setMsg('');
	
	if(newpw==='') {
	    setMsg('Need to input new password.');	    
	    return;	    
	}

	const errmsg = check_password_strength(newpw);

	if(errmsg === '') {

	    const auth = getAuth();
	    if(auth && auth.currentUser) {
		updatePassword(auth.currentUser, newpw).then(
		    ()=> {
			LOGGER.log_event('PASSWORD_CHANGED','');
			setMsg('Password changed');
			setNewPW('');
		    }
		).catch(
		    (error) => {
			LOGGER.error(TAG+'PT1:error=' + String(error));
			parse_error(error);
		    }
	    
		);
	    } else {
		LOGGER.error('Error changing password. Try logging out and logging in again.');
		setMsg('Error changing password. Try logging out and logging in again.');
	    }
	    
	} else {
	    setMsg(errmsg);
	    return;
	}
    }

    const navigate = useNavigate();

    const onCancel = ():void => {
	setMsg('');
	navigate(-1);
    }
    
    return (
	
	<MainLayout title='Change Password'>

	  <Box style={{marginTop : '2em',width:'100%', display:'flex', flexDirection:'column',
	      justifyContent:'center', alignItems:'center'}}>

	  <Box 	style={{display:'flex', flexDirection:'column',
	      justifyContent:'flex-start', alignItems:'flex-start'}}>

	    <Box style={{height:'0.5em'}}/>	    	    
	      
	    <PasswordField  id='newpw'
		       fullWidth={true}	      
		       adornment="New Password"
           	       label="New Password"
		       password={newpw}
		       setPassword={setNewPW}
		       onChange={(e)=> {
			   if(msg !== '') setMsg('');
		       }
		       }/>
	    
	    
	  </Box>

	  <Box style={{height:'2em'}}/>
	  
	  <Button variant={"outlined"} onClick={()=>onSubmit()}>
	    Submit
	      </Button>

	   <Button
		type="submit"
		variant='outlined'	  	    
		sx={{ mt: 2, mb: 2}}
		onClick={()=>onCancel()}
	    >
     	CANCEL
	    </Button> 	  
	      
	      <Box style={{height:'2em'}}/>	    
	      
	      <Typography  sx={{maxWidth:'60ch',
		  minHeight:'2em', fontWeight : "bold" }}
			   variant="body1" color="textSecondary">
	      {msg}
	      </Typography>
	      
	  </Box>	  

	</MainLayout>
    );
}
