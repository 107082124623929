// File: main_menu_items.tsx
//



import * as React from 'react';
import Icon from '@mdi/react';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useLocation } from 'react-router-dom';
import { AdminRole, UserPurchaseInfo } from '../../extlinks/portal_comm_types';
import { get_homeonly_items, get_main_menu_items, get_mobupgrade_menu_items, SideBarItem } from './sidebar_items';
import { PubMainPagesContextState } from './pub_main_pages_provider';
import { SideBarType } from './side_bar';
//import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';

//const TAG='main_menu_items.tsx: ';
//const DEBUG=false;

//export const FONT_COLOR='#3CB371';
export const FONT_COLOR='#008B8B';

// if the link goes to current page path, this
// link deactivates mouse pointer event and highlights the
// enclosed button; this prevents freezing of page in this
// circumstance
export function SideBarLink(props : {key:string, to:string,
    children:React.ReactNode|React.ReactElement[] }) : React.ReactElement {

	const location = useLocation();

	let style :object =
		{ textDecoration:'none', pointerEvents:undefined,
		    color:FONT_COLOR};

	let path = props.to;
	let selected = false;
	if(location.pathname === props.to) {
	    style = {textDecoration:'none', pointerEvents :'none'};
	    path = '';
	    selected = true;
	}

	return (
	    <NavLink to={path} style={style}> 	    
	    <ListItemButton selected={selected}
			    style={{color:FONT_COLOR}}>
	    {props.children}
            </ListItemButton>
            </NavLink>
	);    
}

/*
function MainItems(props : { items : SideBarItem[] }): React.ReactElement {
    return  (
    <Box>
    {  props.items.map( (x) => {
	   const icon_size = x.icon_size ? x.icon_size : 1;
	   const bg_color = x.bg_color ? x.bg_color : 'transparent';
	    return (
		<Box  key={'Box@'+x.title.replace(' ','-')} sx={{backgroundColor:bg_color}}>
		  <SideBarLink key={x.title.replace(' ','-')} to={x.to}>
		    <ListItemIcon>
		      <Icon path={x.icon_path}
			    style={{transform: `rotate(${x.rotate_deg}deg)`}}
			    size={icon_size}/>
		    </ListItemIcon>
		    <ListItemText primary={x.title} />      
		  </SideBarLink>
		</Box>)      
	    
	})
    }
    </Box>	
    );
}*/

function MenuCloseIcon(props: {closeIcon:()=>void})   : React.ReactElement {
    return (

            <Box  component='div' style={{height:'1px'}}
                sx={{mt:3,mb:1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }} >

           <IconButton onClick={props.closeIcon}>
               <CloseIcon/>
            </IconButton>
	    
          </Box>	
    )
    
}

function MainItems(props : { items : SideBarItem[] }): React.ReactElement {
    return  (
    <>
    {  props.items.map( (x) => {
	   const icon_size = x.icon_size ? x.icon_size : 1;
	   const bg_color = x.bg_color ? x.bg_color : 'transparent';
	    return (
		<MenuItem key={'Box@'+x.title.replace(' ','-')} sx={{backgroundColor:bg_color}}>
		  <SideBarLink key={x.title.replace(' ','-')} to={x.to}>
		    <ListItemIcon>
		      <Icon path={x.icon_path}
			    style={{transform: `rotate(${x.rotate_deg}deg)`}}
			    size={icon_size}/>
		    </ListItemIcon>
		    <ListItemText primary={x.title} />      
		  </SideBarLink>
		</MenuItem>)      
	    
	})
    }
    </>
    );
}

function
FullMainMenuItems(props : {user_admin_role: AdminRole,
    user_info: UserPurchaseInfo|null}) : React.ReactElement {
    return (<MainItems items={get_main_menu_items(props.user_admin_role,props.user_info)}/>);
}

function
HomeOnlyMenuMainItems(props : {user_info: UserPurchaseInfo|null}) : React.ReactElement {
    return (<MainItems items={get_homeonly_items(props.user_info)} />);
    
}

function
MobUpgradeMenuItems(props : {user_info: UserPurchaseInfo|null}) : React.ReactElement {
    return (<MainItems items={get_mobupgrade_menu_items(props.user_info)}/>);
}


export function MainDropDownMenu(props : {open:boolean, sx:object,
    onClose:()=>void,
    anchorEl : HTMLElement | null,
    main_pages_state:PubMainPagesContextState, side_bar_type? : SideBarType}): React.ReactElement {

   return (
       <Box component='div'>

	<Menu open={props.open}
	      anchorEl={props.anchorEl}
	      sx={props.sx} onClose={props.onClose}>
	  
           <MenuCloseIcon closeIcon={props.onClose} />	  
	
	    { (props.side_bar_type === undefined || props.side_bar_type===SideBarType.FULL) &&
	     (<FullMainMenuItems
		  user_admin_role={props.main_pages_state.user_admin_role}
		  user_info={props.main_pages_state.purchase_info}/>)
	    }
	
	    {  (props.side_bar_type !== undefined && props.side_bar_type===SideBarType.MOBUPGRADE_CHILD_PAGE) &&
		(<MobUpgradeMenuItems 
		user_info={props.main_pages_state.purchase_info}/>) 
	    }
	
	    {  (props.side_bar_type !== undefined && props.side_bar_type===SideBarType.HOME_ONLY) &&
		(<HomeOnlyMenuMainItems
			       user_info={props.main_pages_state.purchase_info}/>)
	    }
	
      </Menu>
       </Box>       
    );
}


