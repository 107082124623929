// File: email_verified.tsx
//

import React from 'react';
import { useParams, Link} from 'react-router-dom';
import Box from '@mui/material/Box';
import {IPageProps} from '../interfaces/page';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Copyright} from '../../utils_common/copyright';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { getServerComm } from '../utils/server_comm';
import { UseMobUpgradeRoutes } from '../utils/use_mobupgrade_routes';

const DEBUG=true;
const TAG="email_verified.ts: ";

const theme = createTheme();

export const MOBUPGRADE_URL_TAG='mobupgrade';

function Progress(props:{isLoading:boolean} ) : React.ReactElement {
    return (
      <Backdrop
	  invisible={true} open={props.isLoading}
	  sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>);
}

function AcctCreatedSignIn(props : {url_tag?:string}) : React.ReactElement {

    const [ signinRoute, setSigninRoute ] = React.useState<string|null>(null);

    React.useEffect( ()=>{
	if(props.url_tag) console.log(TAG+'AcctCreatedSignIn: props.url_tag='+props.url_tag);
	if(props.url_tag !== undefined && props.url_tag===MOBUPGRADE_URL_TAG) {
	    setSigninRoute('/signin/mobupgrade');
	} else {
	    setSigninRoute('/signin');
	}
	console.log(TAG+'AcctCreatedSignIn: signinRoute='+signinRoute);
    },[props.url_tag])
    
    return (
            <>
	      <Typography component="h1" variant="h5"  align='center'>
            Email Successfully Verified 
             </Typography>
	     <Box style={{height:'1em'}}/>
	      <Typography component="h1" variant="h5"  align='center'>
           Sign In to Finish Creating Account
             </Typography>
	     { signinRoute != null &&
	     (<Link to={signinRoute}>
	       <Typography sx={{mt:2}}
		   component="h1" variant="h5"  align='center'>
               Sign In
	       </Typography>
	     </Link>)
	     }
	    </>    )
}

function AcctNotCreated(props: {url_tag?:string}    ) : React.ReactElement {

    const [ signinRoute, setSigninRoute ] = React.useState<string|null>(null);

    React.useEffect( ()=>{
	if(props.url_tag) console.log(TAG+'AcctNoteCreatedSignIn: props.url_tag='+props.url_tag);
	if(props.url_tag !== undefined && props.url_tag===MOBUPGRADE_URL_TAG) {
	    setSigninRoute('/signin/mobupgrade');
	} else {
	    setSigninRoute('/signin');
	}
	console.log(TAG+'AcctNotCreatedSignIn: signinRoute='+signinRoute);
    },[props.url_tag])
    
    return (
            <>
	      <Typography component="h1" variant="h5"  align='center'>
            Problems Verifying Email
             </Typography>
	      <Typography component="h1" variant="h6"  align='center'>
            Link may have expired.
             </Typography>

	     {/*
	       <Link to='/select-email'>
		 <Typography sx={{mt:2}}
			     component="h1" variant="h5"  align='center'>
               Try Creating an Account Again
		 </Typography>
	     </Link>
	     */}

	     <Box style={{height:"1.5em"}}/>
	      <Typography component="h1" variant="h5"  align='center'>
		Or you may already have an account
             </Typography>
	     
	{ signinRoute != null &&
	       (<Link to={signinRoute}>
		 <Typography sx={{mt:2}}
			     component="h1" variant="h5"  align='center'>
                Sign In
		 </Typography>
	     </Link>)
	}
	     
	    </>    )
}

export function EmailVerified(_props : IPageProps) : React.ReactElement {

    const [ isLoading, setIsLoading ] = React.useState<boolean>(true);
    const [ success, setSuccess ] = React.useState<boolean>(false);
    const [ alreadyUser, setAlreadyUser ] = React.useState<boolean>(false);

    const { token, tag:url_tag } = useParams();

    //const navigate = useNavigate();
    
    React.useEffect( ()=> {

	console.log(TAG+'url_tag='+url_tag);
	
	setIsLoading(false);	
	if(token) {
	    if(DEBUG) console.log(TAG+'***token='+token);
	    setIsLoading(true);		    
	    const server = getServerComm();	    
	    server.handle_verify_email_acct( { link_token:token } ).then(
		(result)=> {
		    
		    setIsLoading(false);
		    
		    if(result.error_status==null) {
			setSuccess(true);

			if(url_tag!==undefined && url_tag === MOBUPGRADE_URL_TAG) {
			    UseMobUpgradeRoutes.set_on();
			}
			
			return;
		    }

		    if(result.error_status === 'ACCOUNT_ALREADY_CREATED') {
			setAlreadyUser(true);
			return;
		    }
		    
		    console.error(TAG+'handle_verify_email_acct(): result.error_status=' +
			result.error_status);
		    setSuccess(false);
		    return;
		}
	    ).catch((error)=> {
		setIsLoading(false);
		setSuccess(false);		
		console.error(TAG+'error='+String(error))
	    }
	    );
	}
    },[token])
    
    return (
	<ThemeProvider theme={theme}>
	<Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box  sx={{
            marginTop: 8,
            ndisplay: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>

	<Typography align='center'>
         <TDBAppLogoLarge/>
	</Typography>

         <Box sx={{mt:5}}>

	   <>
	  {
	      ( ! alreadyUser && ! isLoading && success) && (<AcctCreatedSignIn url_tag={url_tag}/>)
	  }

	  {
	      ( (alreadyUser ||  !success) && ! isLoading) && (<AcctNotCreated url_tag={url_tag}/>)
	  }	     

	     </>

         </Box>
	 
        </Box>	

        <Copyright sx={{ mt: 8, mb: 4 }} />

	<Progress isLoading={isLoading}/>
	
      </Container>
    </ThemeProvider>
  );	

}

