// File: query_questions.tsx
//


import * as React from 'react';
import { SimplePromptDialog } from './prompt_dialog';

const TAG='query_questions.ts: '

const QAs : [string, string[]][]  = [
    ['What is the first book in the Bible ?' , ['genesis'] ],
    ['What is the second book in the Bible ?' , ['exodus'] ],    
    ['What is the last book in the Bible ? (you many enter the first 3 letters)' ,  ['revelations', 'revelation', 'rev'] ],
    ['What is the first book in the New Testament ?' , ['matthew'] ],
    ['What is the last book in the New Testament ?' ,  ['revelations', 'revelation', 'rev'] ],
    ['How many characters are in the word Joshua ?' , ['6'] ],
    ['How many characters are in the word Esau ?' , ['4'] ],
    ['How many characters are in the word Jacob ?' , ['5'] ],                    
]
    
function getRandomInt(max:number) :number {
  return Math.floor(Math.random() * max);
}

function ans_ok(input:string, answers:string[]) : boolean {
    //console.log(TAG+'entering ans_ok');
    for(let ans of answers) {
	//console.log(TAG+'ans_ok: input=' + input + ' ans=' + ans);
	if(input.trim().toLowerCase() === ans) return true;
    }
    return false;
}

//const MAXTRIES=2;

export function
ChallengeQuestions(props : {open:boolean,
    setOpen:(open:boolean)=>void,
    setAnswerCorrect : (correct:boolean)=>void,
    setMaxTriesReached:(reached:boolean)=>void}) : React.ReactElement {

	//const [tries, setTries] = React.useState<number>(0);
	//const tries = React.useRef<number>(0);
	
	const [input, setInput] = React.useState<string>('');
	const [qa,setQa] = React.useState<[string,string[]]|null>(null);	

	React.useEffect( ()=>{
	    if(qa == null) {
		const indx = getRandomInt(QAs.length);
		setQa(QAs[indx]);
	    }
	},[]);

	React.useEffect(()=>{
	    /*
	    if(localStorage.getItem('nchq')===undefined) {
		localStorage.setItem('nchq','0')
	    }
	    tries.current = 1 + Number(localStorage.getItem('nchq'));
	    localStorage.setItem('nchq',String(tries.current))
	    props.setMaxTriesReached(tries.current>=MAXTRIES);
	    
	    console.log('tries='+tries.current);
	    */	    
	},[])
	
    const onClose = ():void=> {
	/*
	if(tries.current>=MAXTRIES) {
	    props.setAnswerCorrect(false);	    
	    return;
	}*/
	//console.log(TAG+'entering onClose');
	if(qa !=null && ans_ok(input, qa[1])) {
	    props.setAnswerCorrect(true);
	} else {
	    props.setAnswerCorrect(false);
	}

	props.setOpen(false);
    }
    
    return(<>
     <SimplePromptDialog open={props.open}
			  setOpen={props.setOpen}
			  onClose={onClose}
			  setInput={setInput}
			  input_label='Answer'
			  title={'Challenge Question'}
			  text={qa ==null? '' : qa[0]}/>


    </>);
}

