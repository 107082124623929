// File: prompt_dialog.tsx
//


import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const TAG='alert_dialog.tsx: ';
const DEBUG = false;


export interface PromptDialogProps {
    open:boolean;
    setOpen:(open:boolean)=>void,
    onClose:()=>void,
    setInput : (input:string)=>void,
    input_label:string,
    
    title : string,  // can have newlines
    text : string,   // can have newlines
    text2? : string,
    
    choice1 : string,
    choice2 : string,

    handle_choice1 : ()=>void,
    handle_choice2 : ()=>void,

    center_body?: boolean,
    bold_body?: boolean,
    body_font_size?:string
    
}


function DialogText(props :  PromptDialogProps) : React.ReactElement {
    
    const[ bodyAlign, setBodyAlign] = React.useState<string>('left');
    const[ bodyFontWt, setBodyFontWt] = React.useState<string>('normal');
    const[ bodyFontSize, setBodyFontSize] = React.useState<string>('100%');        
    
    React.useEffect( ()=> {
	
	const body_align : string = props.center_body === undefined
	    ? 'left' : (props.center_body ? 'center' : 'left');

	setBodyAlign(body_align);

	const body_font_wt  : string = props.bold_body === undefined
	    ? 'normal' : (props.bold_body ? 'bold' : 'normal');
	setBodyFontWt(body_font_wt);
	
	const body_font_size = props.body_font_size === undefined
	    ? '100%' : props.body_font_size;

	setBodyFontSize(body_font_size);

	if(0) {
	    console.log(TAG+'bodyFontWt='+bodyFontWt);
	    console.log(TAG+'bodyAlign='+bodyAlign);
	    console.log(TAG+'bodyFontSize='+bodyFontSize);
	}

	if(0) {
	    console.log(TAG+'props.text='+JSON.stringify(props.text));
	    console.log(TAG+'typeof props.text='+(typeof props.text));
	}
	
    },[ props]);

    let cnt=0;
    
    return (
	<>	  
	{
	    props.text.split('\n').map( txt => 
		(<DialogContentText
		     key={String(cnt++)}
		     sx={{ fontWeight: bodyFontWt, fontSize:bodyFontSize }}>
  	          {txt}
		</DialogContentText>)  		
	    )
	}
	{
	    props.text2 && props.text2.trim()!=='' &&
		 
		 (props.text2.split('\n').map( txt => 
		     (<DialogContentText
			  key={String(cnt++)}
			  sx={{ fontWeight: bodyFontWt, fontSize:bodyFontSize }}>
  	          {txt}
		     </DialogContentText>)
		 )
		 )
	}	  
	</>
    );
}

function DialogTitles(props :  PromptDialogProps) : React.ReactElement {

    if(props.title === '') return (<></>);

    if(0) {
	console.log(TAG+'props.title='+JSON.stringify(props.title));
	console.log(TAG+'typeof props.title='+(typeof props.title));
    }

    let cnt = 0;
    return (<>
	{
	    ["","",...props.title.split('\n') ].map( txt => 
		( <DialogTitle
		      key={String(cnt++)}    		      
		      variant='h6'
		      style={{paddingTop:'0'}}
		      sx={{mb:0, mt:0}}
		      align='center'> 
			{txt}
                </DialogTitle>
		)
	    )
	}
	</>
    );
    
}

export function
PromptDialog(props : PromptDialogProps ) : React.ReactElement {

    const close_dialog = () : void => {
	props.setOpen(false);
    }
    
    const[ bodyAlign, setBodyAlign] = React.useState<string>('left');
    const[ bodyFontWt, setBodyFontWt] = React.useState<string>('normal');
    const[ bodyFontSize, setBodyFontSize] = React.useState<string>('100%');        
    
    React.useEffect( ()=> {
	
	const body_align : string = props.center_body === undefined
	    ? 'left' : (props.center_body ? 'center' : 'left');

	setBodyAlign(body_align);

	const body_font_wt  : string = props.bold_body === undefined
	    ? 'normal' : (props.bold_body ? 'bold' : 'normal');
	setBodyFontWt(body_font_wt);
	
	const body_font_size = props.body_font_size === undefined
	    ? '100%' : props.body_font_size;

	setBodyFontSize(body_font_size);

	if(DEBUG) {
	    console.log('bodyFontWt='+bodyFontWt);
	    console.log('bodyAlign='+bodyAlign);
	    console.log('bodyFontSize='+bodyFontSize);
	}
	
    },[ props]);
    
    return (<>
    <div>
      <Dialog
        open={props.open}
        onClose={close_dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
	<DialogTitles {...props}/>
	    
	{ props.text !== '' &&
	    (<Box sx={{ textAlign:'bodyAlign', mt:0}} >
	      <DialogContent  sx={{pt:0 }} >
		<DialogText {...props}/>
	      </DialogContent>
	    </Box>)
	}

        <TextField
	    sx={{ml:3,mr:3}}
            id="input"
            label={props.input_label}
            name={props.input_label}
            autoComplete={props.input_label}
	    inputProps={{ style: { textTransform: 'lowercase'} }}		    
	    onChange={(e)=> props.setInput(e.target.value.trim().toLowerCase())}
	/>	
      
        <DialogActions
	    sx={{ display:'flex', flexDirection:'row',  mt:0, mb:1,
	    justifyContent:'space-around' }}>
          <Button  variant='outlined'
		   sx={{mt:0}}
	           onClick={()=>props.handle_choice1()} autoFocus>	
            {props.choice1}
	  </Button>
	  { props.choice2 !== '' &&
              <Button variant='outlined'
		      onClick={()=>props.handle_choice2()} autoFocus>
	    {props.choice2}
              </Button>
	  }
        </DialogActions>
      </Dialog>
    </div>      
    </>);
}

export interface SimplePromptDialogProps {
    open:boolean;
    setOpen:(open:boolean)=>void,
    
    onClose:()=>void,
    setInput : (input:string)=>void,
    input_label:string,
    
    title : string,  // can have newlines
    text : string,   // can have newlines
    text2? : string,
}

export function
SimplePromptDialog(props : SimplePromptDialogProps) : React.ReactElement {

    //console.log(TAG+'props.open='+props.open);

    const onClose = ():void => {
	props.onClose();
	props.setOpen(false);
    }
    
    const dialog_props : PromptDialogProps = {
	open : props.open,
	setOpen : props.setOpen,
	onClose : ()=> {},
	
	setInput : props.setInput,
	input_label: props.input_label,
	
	title : props.title,
	text : props.text,
	
	choice1 : 'OK',
	choice2 : '',

	handle_choice1 : onClose,
	handle_choice2 : ()=>{},
    } 
    
    return (
	<PromptDialog {...dialog_props}/>
    )
    
}
