// File: select_license_sorter.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { ListAllLicensesMsg } from '../../../extlinks/portal_comm_types';
import { DropDownListMenu } from '../drop_down_list_menu';

const TAG='select_license_sorter.tsx';
//const DEBUG=true;

type UserLicense = ListAllLicensesMsg.UserLicense;

export interface LicenseSorter {
    name:string,
    sort(licenses : UserLicense[]) : UserLicense[];
}

/*
class NoneSorter implements LicenseSorter {
    readonly name='none';
    sort(licenses : UserLicense[]) : UserLicense[] { return licenses; }
}*/

class SortEmail implements LicenseSorter {
    readonly name='sort-email';    
    sort(licenses : UserLicense[]) : UserLicense[] {
	return licenses.sort((a,b)=> {
	    // eslint-disable-next-line @typescript-eslint/tslint/config	    
	    if(a.sorting_email>b.sorting_email) return 1;
	    // eslint-disable-next-line @typescript-eslint/tslint/config	    
	    if(a.sorting_email<b.sorting_email) return -1;	    
	    return 0;
	});
    }
}

class SortLicenseKey implements LicenseSorter {
    readonly name='sort-license-key';    
    sort(licenses : UserLicense[]) : UserLicense[] {
	return licenses.sort((a,b)=> {
	    // eslint-disable-next-line @typescript-eslint/tslint/config	    
	    if(a.license_key>b.license_key) return 1;
	    // eslint-disable-next-line @typescript-eslint/tslint/config	    
	    if(a.license_key<b.license_key) return -1;	    
	    return 0;
	});
    }
}

class LatestIssueDateFirst implements LicenseSorter {
    readonly name='latest-first';    
    sort(licenses : UserLicense[]) : UserLicense[] {
	return licenses.sort((a,b)=> {
	    if(a.issue_datetime_msecs>b.issue_datetime_msecs) return -1;
	    if(a.issue_datetime_msecs<b.issue_datetime_msecs) return 1;	    
	    return 0;
	});
    }
}

export const EMAIL_SORTER=new SortEmail();

class LatestIssueDateLast implements LicenseSorter {
    readonly name='latest-last';        
    sort(licenses : UserLicense[]) : UserLicense[] {
	return licenses.sort((a,b)=> {
	    if(a.issue_datetime_msecs>b.issue_datetime_msecs) return 1;
	    if(a.issue_datetime_msecs<b.issue_datetime_msecs) return -1;
	    return 0;
	});
    }
}

const SORTERS= [ new SortEmail(), new SortLicenseKey(),  new LatestIssueDateFirst(),  new LatestIssueDateLast() ]


export function get_license_sorter(name:string) : LicenseSorter|null {
    const x = SORTERS.filter(f=>f.name===name);
    if(x.length===0) return null;
    return x[0];
}


export function SelectLicenseSorter(props:{
    setSorter:(x:LicenseSorter)=>void,
    sorter : LicenseSorter,
    onChange?:()=>void}) : React.ReactElement {

    const filters : [string, LicenseSorter][] = [
	[ 'sort by email',  new SortEmail() ],
	[ 'sort by license',  new SortLicenseKey() ],	
	[ 'sort by newest issued first',  new LatestIssueDateFirst()],	
	[ 'sort by oldest issued first',  new LatestIssueDateLast()],
    ]
    
    const items :string[] = filters.map( (x)=>x[0] );

    const [ selectedIndex, setSelectedIndex ] = React.useState<number>(0);
	
    React.useEffect(()=>{
	for(let i=0;i<filters.length;i++) {
	    if(filters[i][1].name === props.sorter.name) {
		setSelectedIndex(i);
		break;
	    }
	}
    },[props.sorter.name]);
	
    const setItemIndex = (index:number):void=> {
	console.log(TAG+'setItemIndex: index=' + index);
	if(index >= items.length || index < 0) return;
	console.log(TAG+'filters[index][1]'+filters[index][1].name);
	props.setSorter(filters[index][1]);
    }
    
    return (
	<Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <DropDownListMenu setSelectedIndex={setItemIndex}
			    selectedIndex={selectedIndex}
			    minWidth={"16em"}
			    onChange={props.onChange}
			    items={items}
	  />
	</Box>);
}


