// File: alert_dialog.tsx
//

import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const TAG='alert_dialog.tsx: ';
const DEBUG = false;

export interface AlertDialogProps {
    open:()=> boolean,
    handle_close:()=> void,
    handle_choice1:()=> void,
    handle_choice2:()=> void,

    title : string,  // can have newlines
    text : string,   // can have newlines
    text2? : string,
    choice1 : string,
    choice2 : string,

    center_body?: boolean,
    bold_body?: boolean,
    body_font_size?:string,

    children? : React.ReactElement |React.ReactElement[],
}

function DialogText(props : AlertDialogProps) : React.ReactElement {
    
    const[ bodyAlign, setBodyAlign] = React.useState<string>('left');
    const[ bodyFontWt, setBodyFontWt] = React.useState<string>('normal');
    const[ bodyFontSize, setBodyFontSize] = React.useState<string>('100%');        
    
    React.useEffect( ()=> {
	
	const body_align : string = props.center_body === undefined
	    ? 'left' : (props.center_body ? 'center' : 'left');

	setBodyAlign(body_align);

	const body_font_wt  : string = props.bold_body === undefined
	    ? 'normal' : (props.bold_body ? 'bold' : 'normal');
	setBodyFontWt(body_font_wt);
	
	const body_font_size = props.body_font_size === undefined
	    ? '100%' : props.body_font_size;

	setBodyFontSize(body_font_size);

	if(0) {
	    console.log(TAG+'bodyFontWt='+bodyFontWt);
	    console.log(TAG+'bodyAlign='+bodyAlign);
	    console.log(TAG+'bodyFontSize='+bodyFontSize);
	}

	if(0) {
	    console.log(TAG+'props.text='+JSON.stringify(props.text));
	    console.log(TAG+'typeof props.text='+(typeof props.text));
	}
	
    },[ props]);

    let cnt=0;
    
    return (
	<>	  
	{
	    props.text.split('\n').map( txt => 
		(<DialogContentText
		     key={String(cnt++)}
		     sx={{ fontWeight: bodyFontWt, fontSize:bodyFontSize }}>
  	          {txt}
		</DialogContentText>)  		
	    )
	}
	{
	    props.text2 && props.text2.trim()!=='' &&
		 
		 (props.text2.split('\n').map( txt => 
		     (<DialogContentText
			  key={String(cnt++)}
			  sx={{ fontWeight: bodyFontWt, fontSize:bodyFontSize }}>
  	          {txt}
		     </DialogContentText>)
		 )
		 )
	}	  
	</>
    );
}

function DialogTitles(props : AlertDialogProps) : React.ReactElement {

    if(props.title === '') return (<></>);

    if(0) {
	console.log(TAG+'props.title='+JSON.stringify(props.title));
	console.log(TAG+'typeof props.title='+(typeof props.title));
    }

    let cnt = 0;
    return (<>
	{
	    ["","",...props.title.split('\n') ].map( txt => 
		( <DialogTitle
		      key={String(cnt++)}    		      
		      variant='h6'
		      style={{paddingTop:'0'}}
		      sx={{mb:0, mt:0}}
		      align='center'> 
			{txt}
                </DialogTitle>
		)
	    )
	}
	</>
    );
    
}

export function AlertDialog(props : AlertDialogProps) : React.ReactElement {

    const[ bodyAlign, setBodyAlign] = React.useState<string>('left');
    const[ bodyFontWt, setBodyFontWt] = React.useState<string>('normal');
    const[ bodyFontSize, setBodyFontSize] = React.useState<string>('100%');        
    
    React.useEffect( ()=> {
	
	const body_align : string = props.center_body === undefined
	    ? 'left' : (props.center_body ? 'center' : 'left');

	setBodyAlign(body_align);

	const body_font_wt  : string = props.bold_body === undefined
	    ? 'normal' : (props.bold_body ? 'bold' : 'normal');
	setBodyFontWt(body_font_wt);
	
	const body_font_size = props.body_font_size === undefined
	    ? '100%' : props.body_font_size;

	setBodyFontSize(body_font_size);

	if(DEBUG) {
	    console.log('bodyFontWt='+bodyFontWt);
	    console.log('bodyAlign='+bodyAlign);
	    console.log('bodyFontSize='+bodyFontSize);
	}
	
    },[ props]);
    
  return (
    <div>
      <Dialog
        open={props.open()}
        onClose={props.handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
	<DialogTitles {...props}/>
	    
	{ props.text !== '' &&
	    (<Box sx={{ textAlign:bodyAlign, mt:0}} >
	      <DialogContent  sx={{pt:0 }} >
		<DialogText {...props}/>
	      </DialogContent>
	    </Box>)
	}
      
        <DialogActions
	    sx={{ display:'flex', flexDirection:'row',  mt:0, mb:1,
	    justifyContent:'space-around' }}>
          <Button  variant='outlined'
		   sx={{mt:0}}
		   onClick={()=>props.handle_choice1()}
	           autoFocus>
            {props.choice1}
	  </Button>
	  { props.choice2 !== '' &&
              <Button variant='outlined'
		      onClick={()=>props.handle_choice2()} autoFocus>
	    {props.choice2}
              </Button>
	  }
        </DialogActions>
	{props.children}
      </Dialog>
    </div>
  );
}

export function AlertDialog2(props : AlertDialogProps) : React.ReactElement {

    const params = props;

    const[ bodyAlign, setBodyAlign] = React.useState<string>('left');
    const[ bodyFontWt, setBodyFontWt] = React.useState<string>('normal');
    const[ bodyFontSize, setBodyFontSize] = React.useState<string>('100%');        
    
    React.useEffect( ()=> {
	
	const body_align : string = params.center_body === undefined
	    ? 'left' : (params.center_body ? 'center' : 'left');

	setBodyAlign(body_align);

	const body_font_wt  : string = params.bold_body === undefined
	    ? 'normal' : (params.bold_body ? 'bold' : 'normal');
	setBodyFontWt(body_font_wt);
	
	const body_font_size = params.body_font_size === undefined
	    ? '100%' : params.body_font_size;

	setBodyFontSize(body_font_size);

	if(DEBUG) {
	    console.log('bodyFontWt='+bodyFontWt);
	    console.log('bodyAlign='+bodyAlign);
	    console.log('bodyFontSize='+bodyFontSize);
	}
	
    },[ params]);
    
  return (
    <div>
      <Dialog
        open={params.open()}
        onClose={params.handle_close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
	<DialogTitles {...params}/>
	    
	{ params.text !== '' &&
	    (<Box sx={{ textAlign:bodyAlign, mt:3}} >
	      <DialogContent  sx={{pt:0 }} >
		<DialogText {...params}/>
	      </DialogContent>
	    </Box>)
	}
      
        <DialogActions
	    sx={{ display:'flex', flexDirection:'row',  mt:0, mb:1,
	    justifyContent:'space-around' }}>
          <Button  variant='outlined'
		   sx={{mt:0}}
		   onClick={()=>params.handle_choice1()}
	           autoFocus>
            {params.choice1}
	  </Button>
	  { params.choice2 !== '' &&
              <Button variant='outlined'
		      onClick={()=>params.handle_choice2()} autoFocus>
	    {params.choice2}
              </Button>
	  }
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function MsgAlertDialog(props : { msg:string, setMsg : (msg:string)=>void}) : React.ReactElement {

    const dialog_is_open = () :boolean=> {
       return props.msg !== '';
    }

    const close_dialog = () : void => {
	props.setMsg('');
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : close_dialog,
	handle_choice1 :close_dialog,
	handle_choice2 :close_dialog,

	title : props.msg,
	text : '',
	choice1 : 'OK',
	choice2 : ''
    }    
    
    return (<>
      <AlertDialog {...dialog_props} />
    </>);
}


export function DownloadProgressDialog(props : { msg:string, setCancel : (cancel:boolean)=>void }) : React.ReactElement {

    const dialog_is_open = () :boolean=> {
       return props.msg !== '';
    }

    const on_cancel = () : void => {
	//console.log('DownloadProgressDialog: called on_cancel');
	props.setCancel(true);
    }

    const dialog_props = {
	open : dialog_is_open,
	handle_close : on_cancel,
	handle_choice1 : on_cancel,
	handle_choice2 : ()=>{},

	title : props.msg,
	text : '',
	choice1 : 'CANCEL',
	choice2 : ''
    }    
    
    return (<>
      <AlertDialog {...dialog_props} >
	<>
      { props.msg !== '' &&
	  <Box sx={{p:1}}>
         <center>  <progress/></center>
         </Box>	
      }
	  </>
      </AlertDialog>
	
    </>);
}
