// File: upgradeable_licenses_list.tsx
//

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { GetUserLicensesInfo} from '../../extlinks/portal_comm_types';
import { TABLE_HEADER_FONT_COLOR, TABLE_HEADER_BG_COLOR } from './utils_config';

type LicenseInfo = GetUserLicensesInfo.LicenseInfo;

export function get_default_license_key(upgradeable_license_infos:LicenseInfo[]) : string | null {
    const lic_infos = upgradeable_license_infos;
    if(lic_infos.length===0) return null;
    if(lic_infos.length===1) return lic_infos[0].license_key;
    for(let x of lic_infos) {
	if(x.expiry_date==null) {
	    return x.license_key;
	}
    }
    const sorted = lic_infos.sort( (x:LicenseInfo, y:LicenseInfo) => {
	if(x.expiry_date ==null) return 1;
	if(y.expiry_date ==null) return -1;	
	const tx = (new Date(x.expiry_date)).getTime();
	const ty = (new Date(y.expiry_date)).getTime();
	if(tx < ty) return  -1;
	if(tx > ty) return  1;
	return 0;
    });

    return sorted[sorted.length-1].license_key;
}

export function
MobileUpgradeableLicensesList(props : { upgradeable_license_infos :  LicenseInfo[],
    onChange : (ev:any, key:string)=>void } ): React.ReactElement  {

	const [default_license_key, set_default_license_key] = React.useState<string|null>(null);

	React.useEffect( ()=> {
	    const default_key=get_default_license_key(props.upgradeable_license_infos);
	    set_default_license_key(default_key);
            if(default_key) props.onChange(null, default_key);
	}, [ props.upgradeable_license_infos]);
	
    return (
	<Box>
   <TableContainer component={Paper} style={{ borderRadius:'5px'  }}
		   sx={{ boxShadow:0, border:'1pt solid gray'}} >
 
      <FormControl sx={{}}>	      		
	<RadioGroup onChange={ (ev,value)=> {
	     set_default_license_key(value);
            props.onChange(ev,value)
       }} >

      <Table sx={{borderTop: '1px solid #E0E0E0' }} >
	
        <TableHead  style={{backgroundColor:TABLE_HEADER_BG_COLOR}}>
	  
          <TableRow style={{fontSize:'0.8rem'}}>

          </TableRow>
	  
          <TableRow>

            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">License Key</TableCell>
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Issued</TableCell>	    	    	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Expiration</TableCell>	    
            <TableCell
	      size="small"	      
	      	style={{color:TABLE_HEADER_FONT_COLOR}}
		align="left">Is Subscription</TableCell>	    
          </TableRow>	  
        </TableHead>
	
        <TableBody>
	  
          {props.upgradeable_license_infos.map((row) => {
	      const issue_date = row.issue_date == null ? 'Unknown' :
		  (new Date(row.issue_date)).toLocaleDateString();
	      const expiry_date = row.expiry_date == null ? 'None' :
		  (new Date(row.expiry_date)).toLocaleDateString();
	      return (
            <TableRow
              key={'LC-'+row.license_key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell size="small" align="left">
		<FormControlLabel
		    sx={{mt:0,mb:0}}
		    control={<Radio />}
		checked={default_license_key===row.license_key}
		    value={row.license_key}
		    label={row.license_key}/>
	      </TableCell>	      	      
              <TableCell size="small" align="left">{issue_date}</TableCell>	      	      
              <TableCell size="small" align="left">{expiry_date}</TableCell>
              <TableCell size="small" align="left">{row.is_subscription? 'yes':'no'}</TableCell>	      	      
            </TableRow>)
          })}
	  
        </TableBody>
	
        </Table>

	</RadioGroup>
      </FormControl>
      
    </TableContainer>
	</Box>	
    );
}
