// File: password_strength.ts
//

import { passwordStrength } from 'check-password-strength'

const CONTAINS=['lowercase', 'uppercase', 'symbol', 'number'];

function get_msg_of_missing(contains: string[]) :string{
    for(let c of CONTAINS) {
        if( ! contains.includes('symbol')) {
            return 'Password must contain at least one special character.';
        }
        if( ! contains.includes(c)) {
            return 'Password must contain at least one ' + c + ' character.';
        }
    }
    return '';
}

// returns empty string if password is strong enough
export function check_password_strength(pw : string) : string {
    pw = pw.trim();
    if(pw.indexOf(' ')>=0) {
        return 'Password cannot contain a blank character.';
    }
    if(pw.length < 8) return 'Password must contain at least 8 characters.';    
    const response = passwordStrength(pw);
    const msg = get_msg_of_missing(response.contains)
    if(msg !== '') return msg;
    switch(response.id) {
        case 0:
            return 'Password is too weak.';
        case 1:
            return 'Password is too weak.';            
        case 2:
            return '';
        case 3:
            return '';            
    }
    return '';
}
