// File: select_license_sorter.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { DropDownListMenu } from '../drop_down_list_menu';
import { GetMobUpgradeableLicenses,
         } from '../../../extlinks/portal_comm_types';

const TAG='select_license_sorter.tsx';
//const DEBUG=true;

type LicenseInfo = GetMobUpgradeableLicenses.ParentLicenseInfo;

export interface LicenseSorter {
    name:string,
    sort(licenses : LicenseInfo[]) : LicenseInfo[];
}

/*
class NoneSorter implements LicenseSorter {
    readonly name='none';
    sort(licenses : LicenseInfo[]) : LicenseInfo[] { return licenses; }
}
*/

class SortEmail implements LicenseSorter {
    readonly name='sort-email';    
    sort(licenses : LicenseInfo[]) : LicenseInfo[] {
	return licenses.sort((a,b)=> {
	    // eslint-disable-next-line @typescript-eslint/tslint/config	    
	    if(a.sorting_email>b.sorting_email) return 1;
	    // eslint-disable-next-line @typescript-eslint/tslint/config	    
	    if(a.sorting_email<b.sorting_email) return -1;	    
	    return 0;
	});
    }
}

class SortLicenseKey implements LicenseSorter {
    readonly name='sort-license-key';    
    sort(licenses : LicenseInfo[]) : LicenseInfo[] {
	return licenses.sort((a,b)=> {
	    // eslint-disable-next-line @typescript-eslint/tslint/config
	    if(a.desktop_license_key>b.desktop_license_key) return 1;
	    // eslint-disable-next-line @typescript-eslint/tslint/config
	    if(a.desktop_license_key<b.desktop_license_key) return -1;	    
	    return 0;
	});
    }
}

class LatestIssueDateFirst implements LicenseSorter {
    readonly name='latest-first';    
    sort(licenses : LicenseInfo[]) : LicenseInfo[] {
	return licenses.sort((a,b)=> {
	    if(a.issue_date_msecs > b.issue_date_msecs) return -1;
	    if(a.issue_date_msecs < b.issue_date_msecs) return 1;
	    return 0;
	});
    }
}

export const EMAIL_SORTER=new SortEmail();

class LatestIssueDateLast implements LicenseSorter {
    readonly name='latest-last';        
    sort(licenses : LicenseInfo[]) : LicenseInfo[] {
	return licenses.sort((a,b)=> {
	    if(a.issue_date_msecs > b.issue_date_msecs) return 1;
	    if(a.issue_date_msecs < b.issue_date_msecs) return -1;	    
	    return 0;
	});
    }
}

const SORTERS : [string, LicenseSorter][] = [
	[ 'sort by email',  new SortEmail() ],
	[ 'sort by desktop license',  new SortLicenseKey()],
	[ 'sort by newest issued first',  new LatestIssueDateFirst()],	
	[ 'sort by oldest issued first',  new LatestIssueDateLast()],
    ]

export function get_license_sorter(name : string) : LicenseSorter|null {
    for(let x of  SORTERS) {
	const sorter = x[1];
	if(sorter.name===name) {
	    return sorter;
	}
    }
    return null;
}

export function SelectLicenseSorter(props:{ setSorter:(x:LicenseSorter)=>void,
    sorter:LicenseSorter,
    onChange?:()=>void}) : React.ReactElement {

    const sorters = SORTERS;
    
    const items :string[] = sorters.map( (x)=>x[0] );

    const [ selectedIndex, setSelectedIndex ] = React.useState<number>(0);
					      
    const setItemIndex = (index:number):void=> {
	console.log(TAG+'setItemIndex: index=' + index);
	if(index >= items.length || index < 0) return;
	console.log(TAG+'filters[index][1]'+sorters[index][1].name);
	props.setSorter(sorters[index][1]);
	setSelectedIndex(index);
    }


	React.useEffect(()=> {
	    for(let i=0;i<sorters.length;i++) {
		if(sorters[i][1].name === props.sorter.name) {
		    setSelectedIndex(i);
		}
	    }
	},[props.sorter.name]);
	
    return (
	<Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
          <DropDownListMenu setSelectedIndex={setItemIndex}
			    selectedIndex={selectedIndex}
			    minWidth={"16em"}
			    onChange={props.onChange}
			    items={items}
	  />
	</Box>);
}


