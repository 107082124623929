// File: RequireAuth.tsx
//

import React from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAuthStatus } from '../auth_provider/use_auth';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';

const TAG='PubRequireAuth.tsx: ';

const DEBUG=false;

const theme = createTheme();

export const PubRequireAuth =
    () : React.ReactElement => {
    
    const {user} = useAuthStatus();
    const location = useLocation();

    React.useEffect( ()=> {
	if(user) {
	    if(DEBUG) {
		console.log(TAG+'user != null')
		console.log(TAG+'user.emailVerified='+user.emailVerified);
	    }
	} else {
	    if(DEBUG) {	    
		console.log(TAG+'user == null')
	    }
	}
    }, [user]);
    
    return (
	<>
	{ user == null &&
  	    ( <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
		  <Navigate to='/signin' state={{ from : location }} replace />
	      </Container>
	      </ThemeProvider>)
	}
	
	{ user != null &&  ! user.emailVerified &&
 	    (
	      <ThemeProvider theme={theme}>
	      <Container component="main" maxWidth="xs">
		<CssBaseline />	
	 	  <Navigate to='/email-not-verified' state={{ from : location }} replace />
	        </Container>
	     </ThemeProvider>)
	}
	
	{ user != null && user.emailVerified &&
		(<Outlet />)	  
	}
	</>
    );
    
}


