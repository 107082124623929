// File: edit_devices_of_license.tsx
//

import * as React from 'react';
import Box from '@mui/material/Box';
import { ListAllLicensesMsg, GetEditLicenseInfo } from '../../../extlinks/portal_comm_types';
import { ResetDeviceActivationCountComp } from './reset_device_activation_count';
import { DevicesOfLicenseTable } from './devices_of_license_table';

const TAG='edit_devices_of_license.tsx: ';
const DEBUG=false;

type EditLicenseInfo = GetEditLicenseInfo.EditLicenseInfo;
type UserLicense = ListAllLicensesMsg.UserLicense;

export function EditDevicesOfLicense(props:{refresh:()=>void, license:UserLicense, license_info:EditLicenseInfo}) : React.ReactElement {

    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    
    const [ licenseInfo, setLicenseInfo ] = React.useState<EditLicenseInfo>(props.license_info);
	
    const refresh = ():void=> {
	props.refresh();
	setLicenseInfo(props.license_info);
	//console.log(TAG+'refresh');
    }
    
    return (

   <Box  sx={{ display:'flex',flexDirection:'column',
	    alignItems:'center'}}>

     <Box sx={{mt:2, width:'100%'}}>
      <DevicesOfLicenseTable license_info={licenseInfo}/>
      </Box>
   
      <Box sx={{mt:3}}>		      
        <ResetDeviceActivationCountComp
	  refresh={refresh}
	  license={props.license}/>
       </Box>		      
	          
 </Box>    

    );
}
