// File: edit_license.tsx
//

import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { MainLayout } from '../utils_admin/main_layout';
import { useAdminPagesContext } from '../utils_admin/admin_pages_provider';
import { ListAllLicensesMsg, AdminRole,
    GetEditLicenseInfo } from '../../extlinks/portal_comm_types';
import { EditLicenseState } from '../utils_admin/display_licenses/licenses_loc_states';
import { useLocationState } from '../../utils_common/use_location_state';
import { getAdminServerComm } from '../utils_admin/admin_server_comm';
import { EditDevicesOfLicense } from '../utils_admin/display_licenses/edit_devices_of_license';
import { EditLicenseProps } from '../utils_admin/display_licenses/edit_license_props';
import { get_session_token, LOGGER } from '../../utils_common/logger';
//import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GoBackToLicensesButton } from '../utils_admin/display_licenses/go_back_to_licenses_button';

const TAG='edit_license.tsx: ';
//const DEBUG=false;
 
const theme = createTheme();
 
async function get_license_info(license: ListAllLicensesMsg.UserLicense) : Promise<GetEditLicenseInfo.OutType> {
    
    const server = getAdminServerComm();
    const session_token = get_session_token();
    
    const result = await server.handle_get_edit_license_info({
	session_token,
	license_key: license.license_key
    });
	    
    return result;
}

export function EditLicense() : React.ReactElement {

    const cnt = React.useRef<number>(0);
    
    if(cnt.current === 0) {
	LOGGER.log_event('EDIT LICENSE','edit_license.tsx');
    }

    cnt.current += 1;

    const loc_state = useLocationState<EditLicenseState>();

    const mainStateContext = useAdminPagesContext();
    const mainPagesIsLoading = mainStateContext.is_loading;

    const [ isLoading, setIsLoading] = React.useState<boolean>(false);
    
    const [userLicense, setUserLicense ] = 
	React.useState<ListAllLicensesMsg.UserLicense|null>(null);

    const [userInfo, setUserInfo ] = 
	React.useState<GetEditLicenseInfo.EditLicenseInfo|null>(null);

    const navigate = useNavigate();

    const refresh = ():void =>{
	
	if(loc_state && loc_state.license) {
	    setIsLoading(true);
	    setUserLicense(loc_state.license);
	    setUserInfo(null);	    
	    get_license_info(loc_state.license).then(
		(result)=> {
		    if(result.edit_license_info) {
			setUserInfo(result.edit_license_info);
		    } else {
			console.error(TAG+'get_license_info: server error:msgs=',result.msgs)
		    }
		    setIsLoading(false);
	    }).catch((error)=> {
		console.error(TAG+'get_license_info: server error: error='+error)		
		setIsLoading(false);		
	    });
	}	
    }
    
    React.useEffect(()=> {
	refresh();
    },[loc_state])

    React.useEffect(()=> {
	if(mainStateContext.current_user_admin_role !== AdminRole.MANAGER &&
	    mainStateContext.current_user_admin_role !== AdminRole.EDITOR) {
	    navigate('/admin/home');
	}
    },[])
    
    return (
	<ThemeProvider theme={theme}>	
	    <CssBaseline />	      
	    <MainLayout title='Admin Console - Edit License'>

	      <>
	      {	loc_state && loc_state.back_state !== undefined &&
		  <GoBackToLicensesButton backState={loc_state.back_state}/>
	      }</>
	      
	      <>
	      {userLicense && userInfo &&
		  (<Box sx={{display:'flex', flexDirection:'column'}}>
		
		      <EditLicenseProps
  		          unmod_license_info={userInfo}
		          license={userLicense}/>
		      
		      <Box sx={{mt:2,mb:1}}>
      	                <EditDevicesOfLicense
			    refresh={refresh}
			    license={userLicense}
			    license_info={userInfo}/>
		      </Box>

		  </Box>)
	      }
	      </>

	  <Backdrop invisible={true} open={mainPagesIsLoading||isLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	    <CircularProgress/>
	  </Backdrop>
	      
	    </MainLayout>

	</ThemeProvider>
  );
    
}
