// File: reset_device_activation_count.tsx
//

import * as React from 'react';
import {ResetDeviceActivationCount,
    MsgStatus, ListAllLicensesMsg
} from '../../../extlinks/portal_comm_types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getAdminServerComm } from '../admin_server_comm';
import { OKCancelDialog } from '../ok_cancel_dialog';
import {
    get_session_token,
    //    LOGGER
} from '../../../utils_common/logger';

const TAG='reset_device_activation_count.tsx: ';
const DEBUG=false;

type DeviceType = ResetDeviceActivationCount.DeviceType;
type UserLicense = ListAllLicensesMsg.UserLicense;

async function
zero_out_act_count_impl(device_type: DeviceType, license_key:string) : Promise<MsgStatus> {
    const server = getAdminServerComm();
    const session_token = get_session_token();
    const response = await server.handle_reset_device_activation_count({
	session_token,
	device_type,
	license_key,
	new_activation_count:0
    });
    return response.status;
}

export function
ResetDeviceActivationCountComp(props:{refresh: ()=>void, license:UserLicense}) : React.ReactElement {

    const [ dialogTitle, setDialogTitle ] = React.useState<string>('');
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [ deviceType, setDeviceType ] = React.useState<DeviceType|null>(null);
    const [ licenseKey, setLicenseKey ] = React.useState<string|null>(null);
    const [ isLoading, setIsLoading ] = React.useState<boolean>(false);
    
    const zero_out_act_count = (device_type: DeviceType, license_key:string):void => {

	setIsLoading(true);
	zero_out_act_count_impl(device_type, license_key).then( (response)=> {
	    if(response === MsgStatus.OK) {
		props.refresh();
	    } else {
		console.error(TAG+'server error');
	    }
	    setIsLoading(false);
	}).catch( (error)=> {
	    console.error(TAG+'server error=' + error);
	    setIsLoading(false);
	});
    }

    const device_type_to_title = {
	'mobile' : 'Zero out activation count of mobile devices?',
	'desktop' :  'Zero out activation count of desktop devices?',
    }
    
    const zero_out_act_count_on_click =  (device_type: DeviceType, license_key:string):void => {
	setDialogTitle(device_type_to_title[device_type]);	
	setOpenDialog(true);
	setDeviceType(device_type);
	setLicenseKey(license_key);
    }

    const onCloseDialog = (response:string):void => {
	setDialogTitle('');
	setOpenDialog(false);
	if(response==='OK') {
	    if(deviceType != null &&  licenseKey!=null) {
		zero_out_act_count(deviceType, licenseKey);
	    }
	}
    }    
	    
    return (<>
      <Box>
       { props.license.license_type.includes_mobile &&
	(<Box sx={{mb:3}}>
	  <Button sx={{mr:1}} size='small'
		  variant='contained'
		  onClick={ ()=>zero_out_act_count_on_click('mobile', props.license.license_key) }
	  >GO</Button>
	  Set all mobile device activation counts to zero
	</Box>)
       }

       { props.license.license_type.includes_desktop &&	
	(<Box>	
	  <Button  sx={{mr:1}} size='small'
		   variant='contained'
		  onClick={ ()=>zero_out_act_count_on_click('desktop', props.license.license_key) }
	  >GO</Button>
Set all desktop activation counts to zero
	</Box>)
	}

	<Backdrop invisible={true} open={isLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	   <CircularProgress/>
	</Backdrop>

        <OKCancelDialog title={dialogTitle} open={openDialog} onClose={onCloseDialog}/>	
	  
      </Box>	
    </>)
}
