import React from 'react';
import { createRoot} from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './config/firebase_config';

import {Application} from './application';
import { AuthProvider } from './auth_provider/auth_provider';
//import { MainPagesProvider} from './pages/utils/main_pages_provider';
//import reportWebVitals from './reportWebVitals';
//x


const rootElement = document.getElementById('root');

if( ! rootElement) {
    console.error('rootElement undefined');

} else {

    const root = createRoot(rootElement);

    // in the past
    // <React.StrictMode> makes link to self page hang.
    // now fixed ?
    root.render(
	<React.StrictMode>
	  <BrowserRouter>
	    <AuthProvider>
		 <Application />
	    </AuthProvider>	    
	  </BrowserRouter>
	  </React.StrictMode>	
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// JJN !!! reportWebVitals();
