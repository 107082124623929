// File: edit_license_display.tsx
//

import * as React from 'react';
import { ListAllLicensesMsg, GetEditLicenseInfo,MsgStatus,
    ModifyLicenseInfo,
    AdminRole
} from '../../../extlinks/portal_comm_types';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AllLicensesTable } from './all_licenses_table';
import { getAdminServerComm } from '../admin_server_comm';
import { OKCancelDialog } from '../ok_cancel_dialog';
import {
    get_session_token,
    //    LOGGER
} from '../../../utils_common/logger';
import { deepCopy } from "deep-copy-ts";

const TAG='edit_license_props.tsx: ';
const DEBUG=false;
 
type EditLicenseInfo = GetEditLicenseInfo.EditLicenseInfo;
type UserLicense = ListAllLicensesMsg.UserLicense;

/*
function getPropertyName<T extends object>(o: T, expression: (x: { [Property in keyof T]: string }) => string) : string {
    const res = {} as { [Property in keyof T]: string };
    Object.keys(o).map(k => res[k as keyof T] = k);
    return expression(res);
}
*/

interface EditItemProps {
    prop_name: string;
    license_info : EditLicenseInfo;
    editable:boolean;
}

const PROPNAME_TO_LABEL : {[key:string]:string}= {
    'includes_mobile' : 'license allows mobile',
    'includes_desktop' : 'license allows desktop',
    'includes_upgrade_to_mobile' : 'license eligible for upgrade to mobile',
    'has_upgraded_to_mobile' : 'user has purchased mobile upgrade',            
}

function get_label(propname:string) : string {
    const label = PROPNAME_TO_LABEL[propname];
    if(label !== undefined) return label;
    return propname.replace(/_/g,' ');
}

class LicenseInfoWrapper {
    
    constructor(private readonly license_info:EditLicenseInfo) {
    }

    get_prop_number(prop_name:string) : number|null {
        if(prop_name === 'max_mobile_devices_per_license') {
	    return this.license_info.max_mobile_devices_per_license;
	}
        if(prop_name === 'max_desktop_activations') {
	    return this.license_info.max_desktop_activations;
	}
	console.error(TAG+'LicenseInfoWrapper.get_prop_number: invalid prop_name=' + prop_name);	
	return null;
    }

    set_prop_number(prop_name:string, value:number) :void {
        if(prop_name === 'max_mobile_devices_per_license') {
	    this.license_info.max_mobile_devices_per_license = value;
	} else if(prop_name === 'max_desktop_activations') {
	    this.license_info.max_desktop_activations =value;
	} else {
	    console.error(TAG+'LicenseInfoWrapper.set_prop_number: invalid prop_name=' + prop_name);	    
	}
    }

    get_prop_boolean(prop_name:string) : boolean|null {
	if(prop_name === 'includes_mobile') {
	    return this.license_info.license_type.includes_mobile;
	}
	if(prop_name === 'includes_desktop') {
	    return this.license_info.license_type.includes_desktop
	}
	if(prop_name === 'includes_upgrade_to_mobile') {
	    return this.license_info.license_type.includes_upgrade_to_mobile;
	}
        if(prop_name === 'has_upgraded_to_mobile') {
	    return this.license_info.license_type.has_upgraded_to_mobile;
	}
	console.error(TAG+'LicenseInfoWrapper.get_prop_boolean: invalid prop_name=' + prop_name);
	return null;
    }
    
    set_prop_boolean(prop_name:string, value:boolean) : void {
	if(prop_name === 'includes_mobile') {
	    this.license_info.license_type.includes_mobile = value;
	}
	if(prop_name === 'includes_desktop') {
	    this.license_info.license_type.includes_desktop = value;
	}
	if(prop_name === 'includes_upgrade_to_mobile') {
	    this.license_info.license_type.includes_upgrade_to_mobile = value;
	}
        if(prop_name === 'has_upgraded_to_mobile') {
	    this.license_info.license_type.has_upgraded_to_mobile = value;
	}
	console.error(TAG+'LicenseInfoWrapper.set_prop_boolean: invalid prop_name=' + prop_name);	
    }    
}

function EditItemBool(props : EditItemProps) :  React.ReactElement {

    const w = new LicenseInfoWrapper(props.license_info);
    
    const [checked, setChecked] = React.useState<boolean|null>(w.get_prop_boolean(props.prop_name));

    const handleChange = (event : React.ChangeEvent<HTMLInputElement>):void => {
	setChecked(event.target.checked);
	w.set_prop_boolean(props.prop_name, event.target.checked);
    }

    React.useEffect( ()=> {
	setChecked(w.get_prop_boolean(props.prop_name));
    },[props.license_info]);
    
    return (<>
	{ checked != null && props.editable &&
            (<FormGroup>
               <FormControlLabel control={
	         <Checkbox 
	           checked={checked}
	           onChange={handleChange}
	         />}
      	        label={get_label(props.prop_name)}
	       />
            </FormGroup>)
	}
	{ (checked != null && !props.editable) &&
            (<Box sx={{display:'flex', flexDirection:'row'}}>
	       <FormControlLabel control={
	         <Checkbox
		     disabled={true}
	             checked={checked}
	         />}
      	        label={get_label(props.prop_name) + '(not editable)'}
	       />
	    </Box>
	    )
	}
	{ (checked == null && props.editable) &&
            (<Box sx={{display:'flex', flexDirection:'row'}}>
	      <QuestionMark sx={{transform:'scale(0.6)'}}/>
	      <Box sx={{ml:1}}>{props.prop_name.replace(/_/g,' ')}</Box>
	    </Box>
	    )
	}            
	</>
    )    
}

function EditItemNumber(props : EditItemProps) :  React.ReactElement {

    const w = new LicenseInfoWrapper(props.license_info);
    
    const [value, setValue] = React.useState<number|null>(w.get_prop_number(props.prop_name));


    React.useEffect( ()=> {
	
	setValue( w.get_prop_number(props.prop_name));

	console.log(TAG+'prop_name=' +  props.prop_name + ' value=' + value);

	console.log(TAG+'prop_name=' +  props.prop_name + ' license_info='+JSON.stringify(props.license_info,null,2));
	    
    }, [props.license_info]);

    const onChange = (e : React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>):void => {
	if(isNaN(Number(e.target.value))) {
	    console.error(TAG+'EditItemNumber: not a number:  '+e.target.value);
	} else {
	    const n = Number(e.target.value);
	    e.target.value = String(n);
	    setValue(n);
	    w.set_prop_number(props.prop_name, n);
	}
    }
    
    return (<>
      
      { value != null && props.editable &&
	    (<Box sx={{mb:2}}>
		<TextField
		    variant='filled'
		    size='small'
		    type='number'
		    helperText={props.prop_name.replace(/_/g,' ')}
		    label={''}
		    InputProps={{
			inputProps: { min: 0 }
		    }}
		    value={value}
		    onChange={onChange}
	      />
           </Box>)
      }
      
      { (value == null ||  ! props.editable) && 
	    (<Box sx={{mb:2}}>
		<TextField
		    size='small'
		    fullWidth={false}
		    helperText={props.prop_name.replace(/_/g,' ')}
		    InputProps={{readOnly: true}}
		    label={'not editable'}
		    value={value == null ? 'unknown' : value}
		/>
	    </Box>)
      }
      
    </>
    )        
}


async function
save_license_info_impl(license: UserLicense,  orig_license_info: EditLicenseInfo, new_license_info: EditLicenseInfo) : Promise<ModifyLicenseInfo.OutType> {
	
    const server = getAdminServerComm();
    const session_token = get_session_token();
    return await server.handle_modify_license_info({
	session_token,
	license_key: license.license_key,
	orig_license_info,
	new_license_info,
    });
}


export function EditLicenseProps(props:{license: UserLicense,  unmod_license_info: EditLicenseInfo}) : React.ReactElement {
    
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [ modLicenseInfo, setModLicenseInfo] =
    	 React.useState<EditLicenseInfo>(deepCopy(props.unmod_license_info));

    const [ isLoading, setIsLoading ] = React.useState<boolean>(false);

    const refresh = ():void => {
    }
    
    const save_license_info = ():void => {

	setIsLoading(true);
	save_license_info_impl(props.license,
                     	       props.unmod_license_info,
	                       modLicenseInfo).then( (response)=> {
	    if(response.status === MsgStatus.OK) {
		refresh();
		console.log(TAG+'server response=', response.msgs);		
	    } else {
		console.error(TAG+'server error=', response.msgs);
	    }
	    setIsLoading(false);
	}).catch( (error)=> {
	    console.error(TAG+'server error=' + error);
	    setIsLoading(false);
	});
    }

    const save_license_info_on_click = ():void => {
	setOpenDialog(true);
    }

    const onCloseDialog = (response:string):void => {
	setOpenDialog(false);
	if(response==='OK') {
	    save_license_info();
	}
    }
    
    const prop_names:{name:string, typ:string, editable:boolean}[] = [
	{ name : 'max_mobile_devices_per_license', typ: 'number',
	    editable:modLicenseInfo.license_type.includes_mobile },
	{ name : 'max_desktop_activations', typ : 'number',
	    editable:modLicenseInfo.license_type.includes_desktop },
	{ name : 'includes_mobile' , typ: 'boolean', editable:true},
	{ name : 'includes_desktop', typ: 'boolean', editable:true},
	{ name : 'includes_upgrade_to_mobile', typ : 'boolean', editable:true},
	{ name : 'has_upgraded_to_mobile', typ: 'boolean', editable:true},
    ]

    return (<>
	<Box sx={{display:'flex', flexDirection:'column'}}>
	  
	  <Box sx={{pb:4}}>
	  <AllLicensesTable page_num={null}
			    setPageNum={()=>{}}
			    current_user_admin_role={AdminRole.VIEWER}
			    split_pages={false}
			    allUserLicenses={[props.license]}
			    filteredUserLicenses={[props.license]}
    	  />
	  </Box>
      {
	  prop_names.map( (x)=> {
	      if(x.typ === 'number') {
                 return (<EditItemNumber prop_name={x.name}
					 editable={x.editable}
					 license_info={modLicenseInfo}/>);
     	      }
	      if(x.typ === 'boolean') {
                 return (<EditItemBool prop_name={x.name}
				       editable={x.editable}				       
				       license_info={modLicenseInfo}/>);
     	      }	      
	      return (<></>)
	  })
      }

          <Box sx={{display:'flex', flexDirection:'row', justifyContent:'center'}}>	  
   	     <Button
		 onClick={save_license_info_on_click}
		 variant='contained'>Save</Button>
	  </Box>
	    
	<Backdrop invisible={true} open={isLoading}
		    sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	   <CircularProgress/>
	</Backdrop>
	
        <OKCancelDialog title={'Save properties?'} open={openDialog} onClose={onCloseDialog}/>
	    
	</Box>	
	</>)
}
