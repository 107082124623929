// File: create_acct_for_assigned_password.tsx
//

import { getAuth } from 'firebase/auth';
import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import { Link, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import  CircularProgress  from '@mui/material/CircularProgress';
import Backdrop  from '@mui/material/Backdrop';
import { Copyright} from '../../utils_common/copyright';
import {IPageProps} from '../interfaces/page';
import { MsgDialogSimple} from '../../utils_common/msg_dialog';
import { TDBAppLogoLarge } from '../../utils_common/tdb_large_logo';
import { PasswordField} from '../../utils_common/password_field';
import { check_password_strength } from '../../utils_common/password_strength';
import { signUpWithEmailAndPassword } from '../utils/create_email_password_acct';
import { REG_LOGGER } from '../../utils_common/logger';
import TextField from '@mui/material/TextField';

const TAG='create_acct_for_assigned_password.tsx: ';
const DEBUG=true;

const theme = createTheme();

export function CreateAcctAssignedPassword(_props: IPageProps): React.ReactElement {

    const cnt =  React.useRef<number>(0);

    React.useEffect( ()=> {    
	if(cnt.current===0) {
	    REG_LOGGER.log_event('ENTER_REGISTER_ACCOUNT','create_acct_for_assigned_password.tsx')
	}
	console.log(TAG+'cnt='+cnt.current);
	cnt.current+=1;
    },[]);

    const [ email, setEmail ] = React.useState<string>('');
    
    const [ msg, setMsg ] = React.useState<string>('');

    const [ linkSent, setLinkSent] = React.useState<boolean>(false);
    const [ waiting, setWaiting ] = React.useState<boolean>(false);
    const [ password, setPassword ] = React.useState<string>('');
    const [ submitted, setSubmitted] = React.useState<boolean>(false);
    const [ directToLoginPage, setDirectToLoginPage] = React.useState<boolean>(false);        
    const auth = getAuth();
    const navigate = useNavigate();

    const submit_failed = (error:string) : void => {
	REG_LOGGER.error(TAG+'submit_failed: error='+ error);
	setMsg(error);
    }

    const [ authChanged, setAuthChanged ] = React.useState<boolean>(false);

    const start_wait = ():void => {
	let waitInterval = setInterval( ()=> {
	    if(authChanged && waitInterval!=null) {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises		
		if(auth.currentUser) auth.currentUser.reload();		
		clearInterval(waitInterval);
	    }
	    if(auth.currentUser) {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		auth.currentUser.reload();
	    }
	}, 2000);
    };
    
    auth.onAuthStateChanged( user=> {
	if(user && user.emailVerified) {
	    setAuthChanged(true);
	}
    });
    
    React.useEffect( () => {
	if(authChanged) {
	    console.log('authchanged')
	    navigate('/signin');	    	    
	}
    }, [authChanged]);
    
    const get_user_status = async ():Promise<number>=> {
	const user = auth.currentUser;
	if(user==null) return 0;
	await user.reload();
	if(user.emailVerified) return 2;
	return 1;
    }

    const signin_and_send_link = ():void => {
	
	if(DEBUG) console.info('entering signin_and_send_link');

	setMsg('');
	setWaiting(true);
	signUpWithEmailAndPassword(
	    {
		email:email,
		password:password,
		url_tag:'0',
	    },
	    ()=>{
		setWaiting(false);
		setSubmitted(true);
		setPassword('');
		setLinkSent(true);		
		setMsg('Check your email for verification link.');
	    },
	    (error)=>{
		setWaiting(false);
		submit_failed(error);
	});
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
	event.preventDefault();
	//console.log(password);
	//console.log(remember);

	if(linkSent) {
	    setMsg('Email link already sent.');
	    return;
	}

	if(password ==='') {
	    setMsg('Need to input password.')
	    return;
	}
	
	const pw_msg = check_password_strength(password);
	if(pw_msg !== '') {
	    setMsg(pw_msg);
	    return;
	} else {
	    
	    if(DEBUG) console.info('handle_submit: PT1');

	    setDirectToLoginPage(false);	    	    
	    
	    if(email !=='') {
		
		get_user_status().then( (user_status:number):void => {

		    if(user_status === 0)  {
			// not registered as user
			if(submitted) {
			    setMsg('already submitted');
			    setPassword('');			    
			} else {
			    signin_and_send_link();
			    start_wait();
			}
		    } else if(user_status === 1) {
			// registered as user but not email verified
			if(linkSent) {
			    setMsg('Email link already sent.');
			    setPassword('');			    
			} else {
			    setWaiting(false);
			    setSubmitted(true);
			    //send_email_link();
			    start_wait();			    
			}
		    } else {
			// registered as user and email verified
			setMsg('Already have account.');
			setPassword('');			
			setDirectToLoginPage(true);
		    }
		    setPassword('');
		}).catch( (err:string):void=> {
		    REG_LOGGER.error(TAG+'get_user_status: email='+
			email+': returned error=' + err);
		});
	    }
	}
    }
    
    const close_dialog = () : void => {
	setMsg('');
	if(directToLoginPage) {
	    console.log('PT10');
	    setDirectToLoginPage(false);	    
	    navigate('/signin');
	}
    }
    
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

      <Backdrop invisible={true} open={waiting} sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>
      	
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

	<Typography align='center'>
         <TDBAppLogoLarge/>
	</Typography>
      
         <Box style={{height:'0.5em'}}/>
      
          <Typography component="h1" variant="h5" sx={{mb:1}}>
            Create Account
          </Typography>
 
          <Box component="form"
	       noValidate onSubmit={(ev:React.FormEvent<HTMLFormElement>)=>handleSubmit(ev)}
	       sx={{ mt: 2 }}>
            <Grid container spacing={2}>
	      
              <Grid item xs={12} sx={{ml:0}}>	      	  	  	      
		<Typography>
Input the email and password from your invitation
		</Typography>
	      </Grid>
	      
		<Grid item xs={12}>
	    
            <TextField
		margin="normal"
		fullWidth
		id="email"
		label="Email Address"
		name="email"
		autoComplete="email"
		autoFocus
		inputProps={{ style: { textTransform: 'lowercase'} }}
		onChange={(e)=> {
		    setMsg('');
		    setEmail(e.target.value.trim().toLowerCase())}}	    
            />
		
	      <PasswordField  id='newpw'
			 fullWidth={true}
			 adornment="Password"
           		 label="Password"
			 password={password}
			 setPassword={setPassword}
			 onChange={()=> {
			     if(msg !== '') setMsg('');
			 }}/>
              </Grid>
	      
	    </Grid>

            <Button
		type="submit"
		fullWidth
		variant="contained"
		sx={{ mt: 2, mb: 2 }}
	    >
                  SUBMIT
	    </Button>

	    
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link to="/signin">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
	
        </Box>

	<MsgDialogSimple msg={msg}
			 button_label1={'OK'}
			 service_close={close_dialog}/>
	
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
