// File: use_auth.tsx
//

import React from 'react';

import {AuthContextState, AuthContext} from './auth_provider';

export const useAuthStatus = () : AuthContextState => {
    return React.useContext<AuthContextState>(AuthContext);
}
