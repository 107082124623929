// file: sidebar_items.tsx
//

import * as React from 'react';
import Icon from '@mdi/react';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
//import Divider from '@mui/material/Divider';
import { mdiTableKey,
    mdiCellphone,
    mdiShieldAccountOutline,        
    mdiCellphoneArrowDownVariant, mdiCellphoneKey, mdiHomeOutline
}  from '@mdi/js/mdi'
import { NavLink, useLocation } from 'react-router-dom';

import { AdminRole, UserPurchaseInfo } from '../../extlinks/portal_comm_types';
//import { LogoutDialog } from '../../utils_common/logout_dialog';

const TAG='sidebar_items.tsx: ';
const DEBUG=false;

//export const FONT_COLOR='#3CB371';
export const FONT_COLOR='#008B8B';

// if the link goes to current page path, this
// link deactivates mouse pointer event and highlights the
// enclosed button; this prevents freezing of page in this
// circumstance
export function SideBarLink(props : {key:string, to:string,
    children:React.ReactNode|React.ReactElement[] }) : React.ReactElement {

	const location = useLocation();

	let style :object =
		{ textDecoration:'none', pointerEvents:undefined,
		    color:FONT_COLOR};

	let path = props.to;
	let selected = false;
	if(location.pathname === props.to) {
	    style = {textDecoration:'none', pointerEvents :'none'};
	    path = '';
	    selected = true;
	}

	return (
	    <NavLink to={path} style={style}> 	    
	    <ListItemButton selected={selected}
			    style={{color:FONT_COLOR}}>
	    {props.children}
            </ListItemButton>
            </NavLink>
	);    
}

export type SideBarItem = {
    icon_path : string,
    title:string,
    to:string,
    rotate_deg:number,
    bg_color?:string,
    icon_size?:number
}

const TO_HOME :SideBarItem = {
    title : 'Home',
    icon_path : mdiHomeOutline,
    to : '/home',
    rotate_deg:0,
    icon_size:1
}


const TO_ADMIN_CONSOLE :SideBarItem = {
    title : 'Admin Console*',
    icon_path : mdiShieldAccountOutline,
    to : '/admin/home',
    rotate_deg:0,
    //bg_color:'lightgray',
    icon_size:1.2
}

const MOBILE_UPGRADE_INFO :SideBarItem = {
    title : 'Mobile App Upgrade Information',
    icon_path : mdiCellphoneArrowDownVariant,
    to : '/mobile-upgrade/mobile-upgrade',
    rotate_deg:180
}

const BACK_TO_MOBILE_UPGRADE_PAGE :SideBarItem = {
    title : 'Back to Mobile App Upgrade Information',
    icon_path : mdiCellphoneArrowDownVariant,
    to : '/mobile-upgrade/mobile-upgrade',
    rotate_deg:180
}

const ACTIVATE_MOBILE_APP :SideBarItem  = {
    title : 'Activate Mobile App',
    icon_path: mdiCellphoneKey,
    to :'/activate-mobile',
    rotate_deg:0    
}

const DISPLAY_MOBILE_DEVICES :SideBarItem  = {
    title : 'Your Mobile Devices',
    icon_path:mdiCellphone,
    to : '/display-mobile',
    rotate_deg:0        
}
	
const DISPLAY_DESKTOP_LICENSE_KEYS :SideBarItem = {
    title:'Desktop App License Key',
    icon_path: mdiTableKey,
    to: '/desktop-app-key',
    rotate_deg:0    
}

export function get_main_menu_items(user_admin_role : AdminRole, user_info  :  UserPurchaseInfo|null) : SideBarItem[] {
    
    if(user_info==null) return [];

    if(DEBUG) console.log(TAG+'entering get_main_items');
    
    let out :  SideBarItem[] = [];

    out.push(TO_HOME);
    
    if(user_admin_role !== AdminRole.NONE) {
	out.push(TO_ADMIN_CONSOLE);
    }
    
    //if(user_info.eligible_for_mobile_upgrade)  out.push(MOBILE_UPGRADE_INFO);
    out.push(MOBILE_UPGRADE_INFO);
                
    out.push(ACTIVATE_MOBILE_APP);

    //if(user_info.has_mobile_app) out.push(DISPLAY_MOBILE_DEVICES);
    out.push(DISPLAY_MOBILE_DEVICES);

    //if(user_info.has_desktop_license) out.push(DISPLAY_DESKTOP_LICENSE_KEYS);
    out.push(DISPLAY_DESKTOP_LICENSE_KEYS);

    //console.log('out=',JSON.stringify(out,null,2));
    
    return out;
}

export function get_mobupgrade_menu_items(user_info  :  UserPurchaseInfo|null) : SideBarItem[] {
	
    if(user_info==null) return [];

    if(DEBUG) console.log(TAG+'entering get_main_items');
    
    let out :  SideBarItem[] = [];

    out.push(TO_HOME);
    
    out.push( BACK_TO_MOBILE_UPGRADE_PAGE);
    
    return out;
}

export function get_homeonly_items(user_info  :  UserPurchaseInfo|null) : SideBarItem[] {
	
    if(user_info==null) return [];

    if(DEBUG) console.log(TAG+'entering get_main_items');
    
    let out :  SideBarItem[] = [];
    out.push(TO_HOME);
    
    return out;
}


export function
SideBarMainItems(props : {user_admin_role: AdminRole,
    user_info: UserPurchaseInfo|null}) : React.ReactElement {

    return (
    <Box>
    {  get_main_menu_items(props.user_admin_role,props.user_info).map( (x) => {
	   const icon_size = x.icon_size ? x.icon_size : 1;
	   const bg_color = x.bg_color ? x.bg_color : 'transparent';
	    return (
		<Box  key={'Box@'+x.title.replace(' ','-')} sx={{backgroundColor:bg_color}}>
		  <SideBarLink key={x.title.replace(' ','-')} to={x.to}>
		    <ListItemIcon>
		      <Icon path={x.icon_path}
			    style={{transform: `rotate(${x.rotate_deg}deg)`}}
			    size={icon_size}/>
		    </ListItemIcon>
		    <ListItemText primary={x.title} />      
		  </SideBarLink>
		</Box>)      
	    
	})
    }
    </Box>
);
    
}

export function
HomeOnlySideBarMainItems(props : {user_info: UserPurchaseInfo|null}) : React.ReactElement {
    return (
    <Box>
      {props.user_info != null &&
    (<SideBarLink key={'Home'} to='/home'>    
      <ListItemIcon>
        <HomeIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </SideBarLink>)
      }
    </Box>
    );
    
}

export function
SideBarMobUpgradeItems(props : {user_info: UserPurchaseInfo|null}) : React.ReactElement {

    return (
    <Box>
    {  get_mobupgrade_menu_items(props.user_info).map( (x) => {
	   const icon_size = x.icon_size ? x.icon_size : 1;
	   const bg_color = x.bg_color ? x.bg_color : 'transparent';
	    return (
		<Box  key={'Box@'+x.title.replace(' ','-')} sx={{backgroundColor:bg_color}}>
		  <SideBarLink key={x.title.replace(' ','-')} to={x.to}>
		    <ListItemIcon>
		      <Icon path={x.icon_path}
			    style={{transform: `rotate(${x.rotate_deg}deg)`}}
			    size={icon_size}/>
		    </ListItemIcon>
		    <ListItemText primary={x.title} />      
		  </SideBarLink>
		</Box>)      
	    
	})
    }
    </Box>

);
    
}

