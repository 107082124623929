// File: progress.tsx
//

import React from 'react';
import Backdrop  from '@mui/material/Backdrop';
import  CircularProgress  from '@mui/material/CircularProgress';

export function Progress(props: {isLoading:boolean} ) : React.ReactElement {
    return (
      <Backdrop
	  invisible={true} open={props.isLoading}
	  sx={{zIndex : (thme)=>thme.zIndex.drawer + 1}} >
	 <CircularProgress/>
      </Backdrop>);
}
