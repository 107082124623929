// File: server_comm.ts
//
//import {getAuth } from 'firebase/auth';        
import { GetEmailIsAlreadyReg,
//         GetSelectEmailStatus,
         GetNewEmailIsEligible,
         ReportUserLogin,
//         ChangeEmail,
         SendEmailVerifyLink,
         GetEmailChangeStatus,
         VerifyEmailAcct,
         DeleteNonVerifiedEmailAcct,
         GetDesktopActivationInfo,         
         GetUserLicensesInfo,         
         CreateSendPendingActivation,
         GetUpgradePopupSession,
         PopupCreateMobileUpgradeLicense,
         ReqMobUpgradeFSProdURL,
         CheckLicenseEligibleForMobileUpgrade,
         MsgStatus,
         ResultType,
         ResponseType,
         RequestType,
         RelayBrowserLogging,
         RelayBrowserRegLogging,
         CheckPasswordBeforeAcct,
         PWLessRequestEmailLoginLink,
         PWLessGetSigninTokenFromUrlToken,         
         CreateNewUserAcct,
         GetEmailIsVerified,
//         CreateAcctFromUrlTokenGetEmail,
         CreateAcctFromUrlToken,
         SendMobUpgradeInvites,
         GetMobUpgradeableLicenses,
         SendResetPasswordLink,
         ResetPasswordFromUrlToken,
         GetResetPasswordFromUrlTokenNotExpired,                  
} from '../../extlinks/portal_comm_types';

import { getFunctions,  httpsCallableFromURL } from "firebase/functions";
import { get_firebase_functions_portalcomm } from '../../config/firebase_config';

const TIMEOUT=30000;


const TAG='server_comm.ts: ';
const DEBUG=true;
 
const PORTAL_FUN_URL=get_firebase_functions_portalcomm();

async function query_server<TIn,TOut extends ResultType>(input : TIn,
                                                         cmd:string,
                                                         options? :{cancelExec? : ()=>boolean }) : Promise<TOut|null> {

    const functions = getFunctions();

    const portalFun = httpsCallableFromURL<RequestType<TIn>,ResponseType<TOut>>(
        functions,
        PORTAL_FUN_URL,
        { timeout:TIMEOUT}        
//        {limitedUseAppCheckTokens:true }   // is in beta
    );

    const msg_input:RequestType<TIn> = { cmd:cmd,  input:input }
    
    return new Promise<TOut|null>( (resolve,reject) => {

        if(options && options.cancelExec) {
            setInterval( ()=> {
                if(options && options.cancelExec && options.cancelExec()) {
                    resolve(null);
                }
            }, 5000);
        }
        
        portalFun(msg_input).then( (x) => {
            resolve(x.data.result);
        }).catch( (error) => {
            console.error(TAG+'query_server: error=' + String(error));
            console.error(TAG+'query_server: problems with appcheck for localhost?');                        
            //console.trace();
            //throw new Error(error);
            reject(error);
        });
    });
}

export type UserLicensesInfo = GetUserLicensesInfo.UserLicensesInfo;

export class ServerComm {
    private ProdServerComm_:undefined;


    async handle_create_new_user_acct(input:CreateNewUserAcct.InType) : Promise<CreateNewUserAcct.OutType> {
        
        const out = await query_server<CreateNewUserAcct.InType,
                                       CreateNewUserAcct.OutType>(input,
                                                                  CreateNewUserAcct.CMD);

        if(out == null) {
            return CreateNewUserAcct.ERROR_OUT;
        }

        return out;                              
    }

/*    
    async handle_create_acct_from_url_token_get_email(input:CreateAcctFromUrlTokenGetEmail.InType) : Promise<CreateAcctFromUrlTokenGetEmail.OutType> {

        const out = await query_server<CreateAcctFromUrlTokenGetEmail.InType,
                                       CreateAcctFromUrlTokenGetEmail.OutType>(input,
                                                                  CreateAcctFromUrlTokenGetEmail.CMD);

        if(out == null) {
            return CreateAcctFromUrlTokenGetEmail.ERROR_OUT;
        }

        return out;                              
    }
  */
    
    async handle_create_acct_from_url_token(input:CreateAcctFromUrlToken.InType) : Promise<CreateAcctFromUrlToken.OutType> {

        const out = await query_server<CreateAcctFromUrlToken.InType,
                                       CreateAcctFromUrlToken.OutType>(input,
                                                                  CreateAcctFromUrlToken.CMD);

        if(out == null) {
            return CreateAcctFromUrlToken.ERROR_OUT;
        }

        return out;                              
    }

    
    async handle_get_email_is_verified(input:GetEmailIsVerified.InType) : Promise<GetEmailIsVerified.OutType> {
        
        const out = await query_server<GetEmailIsVerified.InType,
                                       GetEmailIsVerified.OutType>(input,
                                                                   GetEmailIsVerified.CMD);

        if(out == null) {
            return GetEmailIsVerified.ERROR_OUT;
        }

        return out;                              
    }
    
    async handle_check_password_before_acct(input:CheckPasswordBeforeAcct.InType) : Promise<CheckPasswordBeforeAcct.OutType> {
        const out = await query_server<CheckPasswordBeforeAcct.InType,
                                       CheckPasswordBeforeAcct.OutType>(input,
                                                                        CheckPasswordBeforeAcct.CMD);

        if(out == null) {
            return CheckPasswordBeforeAcct.ERROR_OUT;
        }

        return out;                              
    }
    
    async handle_send_email_verify_link(input:SendEmailVerifyLink.InType) : Promise<SendEmailVerifyLink.OutType> {
        const out = await query_server<SendEmailVerifyLink.InType,
                                       SendEmailVerifyLink.OutType>(input,
                                                            SendEmailVerifyLink.CMD);

        if(out == null) {
            return SendEmailVerifyLink.ERROR_OUT;
        }

        return out;                              
    }
    
    async handle_verify_email_acct(input:VerifyEmailAcct.InType) : Promise<VerifyEmailAcct.OutType> {
        const out = await query_server<VerifyEmailAcct.InType,
                                       VerifyEmailAcct.OutType>(input,
                                                            VerifyEmailAcct.CMD);

        if(out == null) {
            return VerifyEmailAcct.ERROR_OUT;
        }

        return out;                                      
    }
    
    async handle_get_desktop_activation_info(input:GetDesktopActivationInfo.InType) : Promise<GetDesktopActivationInfo.OutType> {
        const out = await query_server<GetDesktopActivationInfo.InType,
                                       GetDesktopActivationInfo.OutType>(input,
                                                            GetDesktopActivationInfo.CMD);

        if(out == null) {
            return {status : MsgStatus.ERROR, desktop_activation_infos:null };
        }

        return out;                      
    }
    
    async handle_get_user_licenses_info(input: GetUserLicensesInfo.InType) : Promise<GetUserLicensesInfo.OutType> {
        const out = await query_server<GetUserLicensesInfo.InType,
                                       GetUserLicensesInfo.OutType>(input,
                                                            GetUserLicensesInfo.CMD);

        if(out == null) {
            return {status : MsgStatus.ERROR, user_licenses_info:null };
        }

        return out;              
    }

    async handle_desktop_license_eligible_for_mobile_upgrade(input:CheckLicenseEligibleForMobileUpgrade.InType) : Promise<CheckLicenseEligibleForMobileUpgrade.OutType> {

        if(DEBUG) console.log(TAG+'entering handle_desktop_eligible_for_mobile_upgrade');

        const out = await query_server<CheckLicenseEligibleForMobileUpgrade.InType,
                                        CheckLicenseEligibleForMobileUpgrade.OutType>(input,
                                                                  CheckLicenseEligibleForMobileUpgrade.CMD);

        if(DEBUG) console.log(TAG+'handle_desktop_license_eligible_for_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid :false,
                error_message:'server error'
            }        
        }

        return out;
    }

    
    async handle_get_encrypted_session(input : GetUpgradePopupSession.InType) : Promise<GetUpgradePopupSession.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_get_encrypted_session');

        const out = await query_server<GetUpgradePopupSession.InType,
                                        GetUpgradePopupSession.OutType>(input,
                                                                  GetUpgradePopupSession.CMD);

        if(DEBUG) console.log(TAG+'handle_get_encrypted_session: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                encrypted_session : {
                    secured_data : '',
                    secured_key : ''
                }
            }
        }


        return out;        
    }

    
    async handle_popup_create_mobile_upgrade(input:PopupCreateMobileUpgradeLicense.InType) : Promise<PopupCreateMobileUpgradeLicense.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<PopupCreateMobileUpgradeLicense.InType,
                                        PopupCreateMobileUpgradeLicense.OutType>(input,
                                                             PopupCreateMobileUpgradeLicense.CMD);

        if(DEBUG) console.log(TAG+'handle_popup_create_mobile_upgrade: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                is_valid:false,
                error_message:'server error',
                cancel_order_status:PopupCreateMobileUpgradeLicense.CancelOrderStatus.ORDER_ATTEMPTED_TO_CANCEL_BUT_FAILED
            }
        }

        return out;        
    }

    async handle_get_mob_upgrade_prod_url(input:ReqMobUpgradeFSProdURL.InType) : Promise<ReqMobUpgradeFSProdURL.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_popup_create_mobile_upgrade');
        const out = await query_server<ReqMobUpgradeFSProdURL.InType,
                                        ReqMobUpgradeFSProdURL.OutType>(input,
                                                             ReqMobUpgradeFSProdURL.CMD);

        if(DEBUG) console.log(TAG+'handle_get_mob_upgrade_prod_url: out=',out);

        if(out == null) {
            return {
                status : MsgStatus.ERROR,
                fs_prod_url: ''
            }
        }

        return out;                
    }

/*    
    // doesnt check if email already exists for a firebase auth user
    // (use handle_get_email_is_already_registered() separately to check)
    async  handle_get_select_email_status( input : GetSelectEmailStatus.InType) : Promise<GetSelectEmailStatus.OutType> {
        if(DEBUG) console.log(TAG+'entering handle_get_email_status');


        const out = await query_server<GetSelectEmailStatus.InType,
                                 GetSelectEmailStatus.OutType>(input,
                                                         GetSelectEmailStatus.CMD);

        if(DEBUG) console.log(TAG+'handle_get_email_status: out=',out);

        if(out == null) {
            return {status : MsgStatus.ERROR, email_status: EmailStatus.UNKNOWN };
        }

        return out;
    }
*/
    
    async  handle_get_email_is_already_registered( input : GetEmailIsAlreadyReg.InType) : Promise<GetEmailIsAlreadyReg.OutType> {

        if(DEBUG) console.log(TAG+'entering handle_get_email_is_already_registered');

        const out = await query_server<GetEmailIsAlreadyReg.InType,
                                 GetEmailIsAlreadyReg.OutType>(input,
                                                               GetEmailIsAlreadyReg.CMD);

        if(DEBUG) console.log(TAG+'handle_get_email_is_already_registered: out=',out);

        if(out == null) {
            return {status : MsgStatus.ERROR, email_is_registered: false};
        }

        return out;        
    }

    // doesnt check if email already exists for a firebase auth user
    // (use handle_get_email_is_already_registered() separately to check)    
    async  handle_get_new_email_is_eligible(input: GetNewEmailIsEligible.InType): Promise<GetNewEmailIsEligible.OutType> {
        const out = await query_server<GetNewEmailIsEligible.InType,
                                       GetNewEmailIsEligible.OutType>(input,
                                                            GetNewEmailIsEligible.CMD);

        if(out == null) {
            return GetNewEmailIsEligible.ERROR_OUT;
        }

        return out;                
    }
    
    async handle_delete_non_verified_email_acct(input : DeleteNonVerifiedEmailAcct.InType) : Promise<DeleteNonVerifiedEmailAcct.OutType|null>{
        
        const out = await query_server<DeleteNonVerifiedEmailAcct.InType,
                                       DeleteNonVerifiedEmailAcct.OutType>(input,
                                       DeleteNonVerifiedEmailAcct.CMD);

        return out;                        
    }
    
    async handle_report_user_login(input : ReportUserLogin.InType) : Promise<ReportUserLogin.OutType|null>{
        
        const out = await query_server<ReportUserLogin.InType,
                                       ReportUserLogin.OutType>(input,
                                       ReportUserLogin.CMD);

        return out;                        
    }
    
    async handle_create_send_pending_activation(input : CreateSendPendingActivation.InType) : Promise<CreateSendPendingActivation.OutType> {
        
        const out = await query_server<CreateSendPendingActivation.InType,
                            CreateSendPendingActivation.OutType>(input,
                                                            CreateSendPendingActivation.CMD);

        if(out == null) {
            return CreateSendPendingActivation.ERROR_OUT;
        }

        return out;                
    }

    async handle_relay_browser_logging(input: RelayBrowserLogging.InType) :
                                      Promise<RelayBrowserLogging.OutType> {
        
        const out = await query_server<RelayBrowserLogging.InType,
                                       RelayBrowserLogging.OutType>(input,
                                       RelayBrowserLogging.CMD);

        if(out == null) {
            return {status : MsgStatus.ERROR };
        }

        return out;                        
    }

    async handle_send_reset_password_link(input: SendResetPasswordLink.InType) :
                                          Promise<SendResetPasswordLink.OutType> {

        try {
            const out = await query_server<SendResetPasswordLink.InType,
                                           SendResetPasswordLink.OutType>(input,
                                           SendResetPasswordLink.CMD);
            
            if(out == null) {
                return SendResetPasswordLink.ERROR_OUT;
            }
            return out;                                    
        } catch(error) {
            return SendResetPasswordLink.ERROR_OUT;            
        }
    }

    async handle_reset_password_from_url_token(input: ResetPasswordFromUrlToken.InType) :
                                              Promise<ResetPasswordFromUrlToken.OutType> {

        try {
            const out = await query_server<ResetPasswordFromUrlToken.InType,
                                           ResetPasswordFromUrlToken.OutType>(input,
                                           ResetPasswordFromUrlToken.CMD);
            
            if(out == null) {
                return ResetPasswordFromUrlToken.ERROR_OUT;
            }
            return out;                                    
        } catch(error) {
            return ResetPasswordFromUrlToken.ERROR_OUT;            
        }
    }

    async handle_reset_password_from_url_token_not_expired(input: GetResetPasswordFromUrlTokenNotExpired.InType) :
                                              Promise<GetResetPasswordFromUrlTokenNotExpired.OutType> {

        try {
            const out = await query_server<GetResetPasswordFromUrlTokenNotExpired.InType,
                                           GetResetPasswordFromUrlTokenNotExpired.OutType>(input,
                                           GetResetPasswordFromUrlTokenNotExpired.CMD);
            
            if(out == null) {
                return GetResetPasswordFromUrlTokenNotExpired.ERROR_OUT;
            }
            return out;                                    
        } catch(error) {
            return GetResetPasswordFromUrlTokenNotExpired.ERROR_OUT;            
        }
    }
    
    async handle_relay_browser_reg_logging(input: RelayBrowserRegLogging.InType) :
                                          Promise<RelayBrowserRegLogging.OutType> {

        //console.log(TAG+'entering handle_relay_browser_reg_logging');        
        try {
            const out = await query_server<RelayBrowserRegLogging.InType,
                                            RelayBrowserRegLogging.OutType>(input,
                                            RelayBrowserRegLogging.CMD);
            
            if(out == null) {
                return {status : MsgStatus.ERROR };
            }
            return out;                                    
        } catch(error) {
            return {status : MsgStatus.ERROR };
        }
    }

    async  handle_get_email_change_status( input : GetEmailChangeStatus.InType) : Promise<GetEmailChangeStatus.OutType> {

        if(DEBUG) console.log(TAG+'entering handle_get_email_changed_status');

        const out = await query_server<GetEmailChangeStatus.InType,
                          GetEmailChangeStatus.OutType>(input,
                                                        GetEmailChangeStatus.CMD);

        if(DEBUG) console.log(TAG+'handle_get_email_changed_status: out=',out);

        if(out == null) {
            return {status : MsgStatus.ERROR,
                    email_change_status: GetEmailChangeStatus.EmailChangeStatus.SERVER_ERROR };
        }

        return out;        
    }

    async handle_get_mobupgradeable_emails(input : GetMobUpgradeableLicenses.InType) : Promise< GetMobUpgradeableLicenses.OutType > {
        try {
            
            const out = await query_server<GetMobUpgradeableLicenses.InType,
                                     GetMobUpgradeableLicenses.OutType >(input, GetMobUpgradeableLicenses.CMD);

            if(out == null) {
                console.error(TAG+'GetMobUpgradeableEmails: out==null');
                return GetMobUpgradeableLicenses.ERROR_OUT;
            }
            return out;

        } catch(error) {
            console.error(TAG+'GetMobUpgradeableEmails: error='+error);        
            return GetMobUpgradeableLicenses.ERROR_OUT;
        }
    }

    async handle_send_mobupgrade_invites(input : SendMobUpgradeInvites.InType) : Promise<SendMobUpgradeInvites.OutType> {
        try {
            const out = await query_server<SendMobUpgradeInvites.InType,
                               SendMobUpgradeInvites.OutType >(input, SendMobUpgradeInvites.CMD);

            if(out == null) {
                console.error(TAG+'SendMobUpgradeInvites: out==null');
                return SendMobUpgradeInvites.ERROR_OUT;
            }
            return out;

        } catch(error) {
            console.error(TAG+'SendMobUpgradeInvites: error='+error);        
            return SendMobUpgradeInvites.ERROR_OUT;
        }
    }


    async handle_pwless_request_email_verify_link(input : PWLessRequestEmailLoginLink.InType) : Promise<PWLessRequestEmailLoginLink.OutType> {
        try {
            const out = await query_server<PWLessRequestEmailLoginLink.InType,
                               PWLessRequestEmailLoginLink.OutType >(input, PWLessRequestEmailLoginLink.CMD);

            if(out == null) {
                console.error(TAG+'PWLessRequestEmailVerifyLink: out==null');
                return PWLessRequestEmailLoginLink.ERROR_OUT;
            }
            return out;

        } catch(error) {
            console.error(TAG+'PWLessRequestEmailVerifyLink: error='+error);        
            return PWLessRequestEmailLoginLink.ERROR_OUT;
        }
    }

    async handle_pwless_get_signin_token_from_url_token(input : PWLessGetSigninTokenFromUrlToken.InType) : Promise<PWLessGetSigninTokenFromUrlToken.OutType> {
        try {
            const out = await query_server<PWLessGetSigninTokenFromUrlToken.InType,
                               PWLessGetSigninTokenFromUrlToken.OutType >(input, PWLessGetSigninTokenFromUrlToken.CMD);

            if(out == null) {
                console.error(TAG+'PWLessGetSigninTokenFromUrlToken: out==null');
                return PWLessGetSigninTokenFromUrlToken.ERROR_OUT;
            }
            return out;

        } catch(error) {
            console.error(TAG+'PWLessGetSigninTokenFromUrlToken: error='+error);        
            return PWLessGetSigninTokenFromUrlToken.ERROR_OUT;
        }
    }        
}
 
export function getServerComm() : ServerComm {
    return new ServerComm();     
}
